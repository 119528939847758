import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Reviews from "../Components/Reviews";

const ReviewList = (props) => {
  return (
    <>
      <Helmet>
        <title>Reviews | American Legacy Tours</title>
      </Helmet>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile')",
          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h3>Google Reviews</h3>
            </Col>
          </Row>
          <Row
            style={{
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Reviews numToDisplay={16} />
          </Row>
          <Row>
            <Col style={{ textAlign: "center", marginTop: 20 }}>
              <a
                href="https://www.google.com/search?q=American+Legacy+Tours#lrd=0x8841b3fd868b6ea1:0xfae885fabe92c950,1,,,"
                style={{
                  fontSize: "110%",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  marginTop: 20,
                  border: "5px solid white",
                }}
              >
                See more
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ReviewList;
