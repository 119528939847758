import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col } from "react-bootstrap";

const Content = (props) => {
  return (
    <Col lg={6} md={6} sm={12} xs={12}>
      <h2
        dangerouslySetInnerHTML={{
          __html: props.content.title,
        }}
      ></h2>
      <div
        dangerouslySetInnerHTML={{
          __html: props.content.content,
        }}
      ></div>
      <hr />
    </Col>
  );
};
export default Content;
