import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { Image, Placeholder, Transformation } from "cloudinary-react";
const items = [
  // {
  //   src: "/img/home/slider/slider-001",
  //   altText: "Inside Cincy's Arts Walking Tour",
  //   header: "Inside Cincy's Arts Walking Tour	",
  //   caption: "Join us and ArtsWave for this incredible walking tour!",
  //   learnmore: [{ insidecincysarts: "Learn More" }],
  // },
  {
    src: "/img/home/slider/slider-01",
    altText: "Underground Tours",
    header: "Underground Tours",
    caption: "Join us below the city to explore Cincinnati's past.",
    learnmore: [
      { "ultimate-underground": "Ultimate Queen City Underground Tour" },
      { hiddencaverns: "Hidden Caverns Tour" },
    ],
  },
  {
    src: "/img/home/slider/slider-02",
    altText: "1919 THE YEAR THAT CHANGED BASEBALL",
    header: "1919 THE YEAR THAT CHANGED BASEBALL",
    caption:
      "Join us to learn about 1919: the year that truly changed baseball forever.",
    learnmore: [{ "1919-tour": "Learn more about the tour" }],
  },
  {
    src: "/img/home/slider/slider-03",
    altText: "Newport Gangster Tour",
    header: "Newport Gangster Tour",
    caption:
      "Explore the streets where the mob made their millions, gamblers lost their lives, and ladies of the night earned their reputations.",
    learnmore: [{ "/newport-gangster-tour": "Learn more about the tour" }],
  },
  {
    src: "/img/home/slider/slider-04",
    altText: "Haunted Tours",
    header: "Haunted Tours",
    caption:
      "Discover stories of grisly murders, ghastly deaths and other disturbing tales buried deep within the Tri-State's storied past.",
    learnmore: [
      {
        "mt-healthy-is-haunted": "New this season: Mt. Healthy is Haunted Tour",
      },
      { tours: "& more" },
    ],
  },
];
const height = window.screen.height;
const width = window.screen.width;
const minHeight = 0.3 * height;

const TopCarousel = (props) => {
  const slides = items.map((item, idx) => {
    return (
      <Carousel.Item>
        <Image
          className="w-100 d-none d-sm-block biggerthansmall"
          cloudName="american-legacy-tours"
          // loading={idx != 0 ? "lazy" : null}
          fetchpriority={idx == 0 ? "high" : "low"}
          publicId={item.src}
          fetch_format="auto"
          title={item.altText}
          style={{ minHeight: minHeight }}
        >
          <Placeholder type="predominant" />
          <Transformation width="1800" height="700" crop="fill" />
        </Image>
        <Image
          className="d-block d-sm-none  w-100 smallonly"
          cloudName="american-legacy-tours"
          // loading={idx != 0 ? "lazy" : null}
          fetchpriority={idx == 0 ? "high" : "low"}
          publicId={item.src}
          fetch_format="auto"
          title={item.altText}
          style={{ minHeight: minHeight }}
        >
          <Placeholder type="predominant" />
          <Transformation width="1800" height="1800" crop="fill" />
        </Image>
        <Carousel.Caption>
          <h2>{item.header}&nbsp;</h2>
          <p>{item.caption}&nbsp;</p>
          {item.learnmore.map((i) => {
            const [k, v] = Object.entries(i)[0];
            return (
              <Button
                key={k}
                href={k}
                style={{
                  margin: 20,
                  color: "black",
                  backgroundColor: "#F4B657",
                  border: "1px solid black",
                }}
              >
                {v}
              </Button>
            );
          })}
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return <Carousel>{slides}</Carousel>;
};
export default TopCarousel;
