import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { send } from "emailjs-com";

const Contact = (props) => {
  const [sent, setSent] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [toSend, setToSend] = React.useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    setSent(true);
    send("service_thlhokp", "template_a5nz49a", toSend, "pspiVumi2F-ZjP1oN")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsSent(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setIsSent(false);
        setSent(false);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | American Legacy Tours</title>
      </Helmet>
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/shop') no-repeat center center fixed",
        }}
      >
        <Container>
          <h2>Get in Touch</h2>
          <Row>
            <Col md={6} xs={12}>
              <div
                className="contactInfo"
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                <div className="media">
                  <div className="media-body">
                    <h4 className="media-heading">Address</h4>
                    <p>
                      1332 Vine St
                      <br />
                      Cincinnati, OH 45202
                    </p>
                  </div>
                </div>
                <hr />
                <div className="media">
                  <div className="media-body">
                    <h4 className="media-heading">Phone number</h4>
                    <p>
                      <a href="tel:8599518560">(859) 951.8560</a>
                    </p>
                  </div>
                </div>
                <hr />

                <div className="media">
                  <div className="media-body">
                    <h4 className="media-heading">Email</h4>
                    <p>
                      <a href="mailTo:questions@americanlegacytours.com">
                        questions@americanlegacytours.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="contactForm">
                <form onSubmit={onSubmit} class="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="from_name"
                      placeholder="Your Name"
                      value={toSend.from_name}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="reply_to"
                      placeholder="Your Email"
                      value={toSend.reply_to}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="phone"
                      className="form-control"
                      name="from_phone"
                      placeholder="Your Phone"
                      value={toSend.from_phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group" id="submitbutton">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      value={toSend.message}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  {!sent && <button type="submit">Send Message</button>}
                  {isSent && (
                    <div
                      id="thanks"
                      style={{
                        fontSize: "110%",
                        color: "#000000",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.8)",
                        padding: 20,
                        border: "5px solid white",
                      }}
                    >
                      Message Sent!
                    </div>
                  )}
                </form>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12}>
              <iframe
                title="googlemap"
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: "5px solid white" }}
                src={
                  "https://www.google.com/maps/embed/v1/place?q=" +
                  encodeURI("1332 Vine St Cincinnati OH 45202") +
                  "&key=AIzaSyAF0pVwRqx_wSImGwi80X7chUgzKdq1Q_E"
                }
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Contact;
