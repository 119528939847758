import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { getTours, getTourGroups } from "../Api/TourApi";
import Tour from "./Tour";

const Tours = (props) => {
  const [tourGroups, setTourGroups] = useState([]);
  // const [tourLocations, setTourLocations] = useState([]);
  const [selectedTag, setSelectedTag] = useState();
  const [isLoadingTours, setIsLoadingTours] = useState(true);

  const [origTours, setOrigTours] = useState([]);
  const [resultTours, setResultTours] = useState([]);

  useEffect(() => {
    const filterByGroup = () => {
      const resultTours = [...origTours].filter((tour) => {
        if (!selectedTag) {
          return tour;
        } else {
          if (tour["attributes"]["groups"]) {
            return tour["attributes"]["groups"].includes(selectedTag);
          } else {
            return null; // DO I NEED THIS?
          }
        }
      });
      setResultTours(resultTours);
    };
    filterByGroup();
  }, [selectedTag, origTours]);

  useEffect(() => {
    let cancelled = false;

    // async function fetchTourLocations() {
    //   const response = await getTourLocations("all");
    //   // console.log(response);
    //   if (!cancelled) {
    //     // setTours(response.data);
    //     setTourLocations(
    //       response.data.sort(
    //         (a, b) =>
    //           a["attributes"].readable_name - b["attributes"].readable_name
    //       )
    //     );
    //   }
    // }
    // fetchTourLocations();

    async function fetchTourGroups() {
      const response = await getTourGroups("all");
      // console.log(response);
      if (!cancelled) {
        // setTours(response.data);
        setTourGroups(
          response.data.sort(
            (a, b) =>
              a["attributes"].readable_name - b["attributes"].readable_name
          )
        );
      }
    }
    fetchTourGroups();

    async function fetchTours() {
      const response = await getTours();
      // console.log(response);
      if (!cancelled) {
        setOrigTours(
          response.data.sort(
            (a, b) => a["attributes"].sortOrder - b["attributes"].sortOrder
          )
        );
        setIsLoadingTours(false);
      }
    }
    fetchTours();

    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <>
      <Row style={{ textAlign: "center", margin: 5, padding: 0 }}>
        {/* Topic: */}
        <Col style={{ margin: 0, padding: 0 }}>
          <ButtonGroup style={{ marginBottom: "20px" }}>
            <Button
              size="sm"
              variant={!selectedTag ? "primary" : "secondary"}
              onClick={() => {
                setSelectedTag();
              }}
              style={{
                maxHeight: "75px",
                fontSize: "80%",
              }}
            >
              All Tours
            </Button>
            {tourGroups.map((group) => {
              if (group["attributes"]["frontpage"]) {
                return (
                  <Button
                    size="sm"
                    variant={
                      selectedTag === group["attributes"]["name"]
                        ? "primary"
                        : "secondary"
                    }
                    outline
                    onClick={() => {
                      setSelectedTag(group["attributes"]["name"]);
                    }}
                    style={{
                      maxHeight: "75px",
                      fontSize: "80%",
                      borderLeft: "1px solid black",
                    }}
                  >
                    {group["attributes"]["readable_name"]}
                  </Button>
                );
              }
              return null;
            })}
          </ButtonGroup>
        </Col>
      </Row>
      <Container className="themed-container" style={{ textAlign: "center" }}>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {!isLoadingTours &&
            resultTours.map((tour) => <Tour tour={tour["attributes"]} />)}
          {isLoadingTours && <div style={{ height: "2482px" }} />}
        </Row>
      </Container>
    </>
  );
};
export default Tours;
