import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Card, Button, ListGroup } from "react-bootstrap";
import AvailabilitySmall from "../Components/Tour/AvailabilitySmall";
import { Image, Placeholder, Transformation } from "cloudinary-react";
import { Helmet } from "react-helmet";

const isMobile = window.screen.width < 600;
const current = new Date();

const Tour = (props) => {
  return (
    props.tour.enabled && (
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: props.tour.title,
              description: props.tour.description,
              brand: {
                "@type": "Brand",
                name: "American Legacy Tours",
              },
              image:
                "https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
                props.tour.picturePath,
              offers: {
                "@type": "Offer",
                url:
                  "https://www.americanlegacytours.com/" + props.tour.pathTitle,
                price: props.tour.price,
                priceCurrency: "USD",
                availability: "https://schema.org/InStock",
                priceValidUntil: "12/31/" + current.getFullYear(),
              },
            })}
          </script>
        </Helmet>
        <Col
          xl={3} // THIS WAS GONE
          lg={4} // THIS WAS 3
          md={6} // THIS WAS 4
          sm={6}
          xs={6}
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <Card style={{ marginBottom: "20px" }}>
            <a href={props.tour.pathTitle}>
              <Image
                className="w-100 d-none d-sm-block  biggerthansmall"
                cloudName="american-legacy-tours"
                publicId={props.tour.picturePath}
                loading="lazy"
                fetch_format="auto"
                quality="auto"
                dpr="auto"
                crop="scale"
                width="240"
                // height="340"
                style={{ width: 240 }} //, height: 340
                title={props.tour.title}
              >
                <Placeholder type="predominant" />
                <Transformation height="240" width="340" />
              </Image>
              <Image
                className="d-block d-sm-none  w-100 smallonly"
                cloudName="american-legacy-tours"
                publicId={props.tour.picturePath}
                loading="lazy"
                fetch_format="auto"
                quality="auto"
                dpr="auto"
                crop="scale"
                width="163"
                // height="231"
                style={{ width: 163 }} //, height: 231
                title={props.tour.title}
              >
                <Placeholder type="predominant" />
                <Transformation height="163" width="231" />
              </Image>
              {/* <Image
                cloudName="american-legacy-tours"
                publicId={props.tour.picturePath}
                style={{ width: "100%" }}
                loading="lazy"
                fetch_format="auto"
                quality="auto"
                dpr="auto"
                crop="scale"
                width="auto"
              >
                <Placeholder type="predominant" />
              </Image> */}
            </a>
            <Card.Body
              style={{
                paddingTop: "0",
                paddingBottom: "0",
                margin: "0",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Card.Title style={{ display: "none" }}>
                {props.tour.title}
              </Card.Title>
              {/* <CardText>{props.tour.caption}</CardText> */}
              <Button
                outline
                color="secondary"
                block
                href={props.tour.pathTitle}
                style={{ marginBottom: "5px" }}
              >
                Learn More
              </Button>
            </Card.Body>
            {isMobile && (
              <ListGroup className="list-group-flush">
                {/* style={{ minHeight: 520 }} */}
                <AvailabilitySmall
                  thundertixId={props.tour.thundertixId}
                  slug={props.tour.pathTitle}
                />
              </ListGroup>
            )}
            {!isMobile && (
              <ListGroup
                className="list-group-flush"
                style={{ minHeight: 395 }}
              >
                <AvailabilitySmall
                  thundertixId={props.tour.thundertixId}
                  slug={props.tour.pathTitle}
                />
              </ListGroup>
            )}
            {/* </Card.Body> */}
          </Card>
        </Col>
      </>
    )
  );
};
export default Tour;
