import React from "react";
import banner from '../assets/banner.png';

const Banner = () => {
  return (
    <div style={{width: "100%", alignItems: "center"}}>
      <img src={banner} style={{maxWidth: "100%"}}/>
    </div>
  );
};

export default Banner;
