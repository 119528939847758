import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Badge, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { getTourAvailability } from "../../Api/TourApi";
import { Helmet } from "react-helmet";

const Availability = (props) => {
  const [tourAvailability, setTourAvailability] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [moreLink, setMoreLink] = useState("");
  // const [daysAvailable, setDaysAvailable] = useState({});
  const current = new Date();

  useEffect(() => {
    let cancelled = false;
    let availabilityList = [];
    if (props.thundertixId) {
      async function fetchTourAvailability() {
        const response = await getTourAvailability(props.thundertixId);
        if (!cancelled && response) {
          if (response) {
            // let theAvailableDays = {};
            Object.keys(response).map((i) => {
              // theAvailableDays[response[i].id] = i.dayofweek;
              availabilityList.push(response[i]);
              if (response[i].totalShowings > 6) {
                setHasMore(true);
                setMoreLink(props.slug + "/buy");
              } else {
                setHasMore(false);
              }
              return null;
            });
            // console.log(theAvailableDays);
            // setDaysAvailable(theAvailableDays);
            setIsLoading(false);
          }
          return setTourAvailability(availabilityList);
        }
      }
      fetchTourAvailability();
    }

    return () => {
      cancelled = true;
    };
  }, [props.thundertixId]);

  return (
    <Container>
      <Card style={{ border: "none" }}>
        <Card.Body>
          <Card.Title tag="h5">Availability</Card.Title>
          {/* <Card.Subtitle tag="h6" className="mb-2 text-muted">
            {daysAvailable &&
              Object.keys(daysAvailable).map((i) => {
                return (
                  <Badge variant="secondary" style={{ margin: "2px" }}>
                    {daysAvailable[i] + "s"}
                  </Badge>
                );
              })}
          </Card.Subtitle> */}
          <Card.Text>
            {!isLoading && tourAvailability.length <= 0 && <>Check Back Soon</>}
            {isLoading && (
              <Row style={{ textAlign: "center" }}>
                <Col xs="12">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            )}
            {!isLoading &&
              tourAvailability &&
              tourAvailability.map((i) => {
                return (
                  <>
                    {i.availability !== 0 && (
                      <Helmet>
                        <script type="application/ld+json">
                          {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Event",
                            name:
                              props.title +
                              " on " +
                              i.short +
                              " at " +
                              i.time +
                              " " +
                              i.ampm,
                            startDate: i.long,
                            // endDate: "2025-07-21T23:00-05:00",
                            eventAttendanceMode:
                              "https://schema.org/OfflineEventAttendanceMode",
                            eventStatus: "https://schema.org/EventScheduled",
                            location: {
                              "@type": "Place",
                              name: props.startLocationName,
                              address: {
                                "@type": "PostalAddress",
                                streetAddress: props.address,
                                addressLocality: props.city,
                                postalCode: props.zip,
                                addressRegion: props.state,
                                addressCountry: "US",
                              },
                            },
                            image:
                              "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
                              props.picturePath,
                            description: props.description,
                            offers: {
                              "@type": "Offer",
                              url:
                                "https://www.americanlegacytours.com/" +
                                props.slug +
                                "/checkout/" +
                                props.performanceid,
                              price: props.price,
                              priceCurrency: "USD",
                              availability: "https://schema.org/InStock",
                              priceValidUntil: "12/31/" + current.getFullYear(),
                            },
                            performer: {
                              "@type": "PerformingGroup",
                              name: "American Legacy Tours",
                            },
                            organizer: {
                              "@type": "Organization",
                              name: "Amerian Legacy Tours",
                              url: "https://www.americanlegacytours.com",
                            },
                          })}
                        </script>
                      </Helmet>
                    )}
                    <Row
                      style={{
                        padding: "5px",
                        borderBottom: "1px solid rgba(0,0,0,.125)",
                      }}
                    >
                      <Col xs={7}>
                        <span className={"d-none d-sm-block biggerthansmall"}>
                          {i.month +
                            " " +
                            i.day +
                            " " +
                            i.year +
                            " @ " +
                            i.time +
                            " " +
                            i.ampm}
                        </span>
                        <span className={"d-block d-sm-none smallonly"}>
                          {i.month +
                            " " +
                            i.day +
                            " @ " +
                            i.time +
                            " " +
                            i.ampm}
                        </span>
                      </Col>
                      <Col xs={5}>
                        <Badge
                          variant={
                            i.availability > 7
                              ? "success"
                              : i.availability > 3
                              ? "warning"
                              : i.availability > 0
                              ? "danger"
                              : "secondary"
                          }
                          style={{ width: "100%", fontSize: "100%" }}
                        >
                          <a
                            href={
                              i.availability && i.availability !== 0
                                ? "/" +
                                  props.slug +
                                  "/checkout/" +
                                  i.performaceId
                                : null
                            }
                            style={{ textDecoration: "none", color: "#FFFFFF" }}
                          >
                            <span
                              className={"d-none d-sm-block biggerthansmall"}
                            >
                              {i.availability > 7
                                ? "Tickets available"
                                : i.availability <= 0
                                ? "Sold Out"
                                : i.availability + " tickets remaining"}
                            </span>
                            <span className={"d-block d-sm-none smallonly"}>
                              {i.availability > 7
                                ? "Available"
                                : i.availability <= 0
                                ? "Sold Out"
                                : i.availability + " tickets"}
                            </span>
                          </a>
                        </Badge>
                      </Col>
                    </Row>
                  </>
                );
              })}
            {/* {hasMore && (
              <Row style={{ width: "100%", padding: "2px" }}>
                <a href={moreLink}>
                  <Badge variant={"secondary"}>See More</Badge>
                </a>
              </Row>
            )} */}
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};
export default Availability;
