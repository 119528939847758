import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { getTourPhotos } from "../Api/TourApi";
import { Image } from "cloudinary-react";
import "react-image-lightbox/style.css";
import "../masonry.css";
import Masonry from "react-masonry-css";

const LightboxComponent = (props) => {
  const [images, setImages] = useState([]);
  const [tourPhotos, setTourPhotos] = useState({});
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const breakpointColumnsObj = {
    default: 4,
    991: 3,
    768: 2,
    // 500: 1,
  };

  useEffect(() => {
    let cancelled = false;

    async function fetchTourPhotos() {
      const response = await getTourPhotos(props.slug);
      if (!cancelled && response.data[0]) {
        setTourPhotos(response.data[0].attributes ?? [{}]);
        let imageList = [];
        for (let i in response.data[0].attributes) {
          imageList.push(
            "https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/" +
              response.data[0].attributes[i].picture_path
          );
        }
        return setImages(imageList);
      }
    }
    fetchTourPhotos();

    return () => {
      cancelled = true;
    };
  }, [props.slug]);

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {Object.keys(tourPhotos).map((i) => {
          return (
            <Image
              cloudName="american-legacy-tours"
              // height="130"
              // width="130"
              crop="fill"
              quality="auto"
              publicId={tourPhotos[i].picture_path}
              style={{
                width: "100%",
                cursor: "pointer",
                marginBottom: 25,
              }}
              loading="lazy"
              fetch_format="auto"
              onClick={() => {
                setPhotoIndex(i);
                setIsOpen(true);
              }}
            />
          );
        })}
      </Masonry>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};
export default LightboxComponent;
