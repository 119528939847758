import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Banner from "react-js-banner";

const GC = (props) => {
  const current = new Date();

  let bannerCss = {
    b1C: { width: "93%" },
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Gift Card",
    description:
      "American Legacy Tour gift cards are the perfect gift for any holiday or occassion",
    offers: {
      "@type": "Offer",
      url: "https://www.americanlegacytours.com/gift-certificates",
      price: "35",
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
      priceValidUntil: "12/31/" + current.getFullYear(),
    },
  };
  return (
    <>
      <Helmet>
        <title>Purchase Gift Certificates | American Legacy Tours</title>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
        }}
      >
        <Container>
          <Row>
            <Col>
              <p>
                Gift Certificates from American Legacy Tours make your holiday
                and birthday gift shopping easy. Give your family, friends,
                employees and coworkers the gift of historically entertaining
                tours. You will be allowing them to take an experiential tour of
                Cincinnati while choosing the tour do it on. We have tours for
                everyone from History Tours and Haunted Tours to Underground
                Tours and Food and Drink Tours.
              </p>
              {/* <Banner css={bannerCss.b1C}>
                <div>
                  Discount reflected at checkout. Enter total value below and
                  the 20% will be applied prior to payment.
                  <span style={{ fontWeight: 100 }}>
                    <br />
                    (Limited Time Only)
                  </span>
                </div>
              </Banner>
              <br /> */}
              <iframe
                className="iframe-class"
                width="100%"
                height="1200px"
                src="https://americanlegacytours.thundertix.com/gift_certificates/53"
                frameBorder="0"
                scrolling="auto"
                marginheight="0"
                marginwidth="0"
                allowtransparency="true"
                title="gift-certificates"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default GC;
