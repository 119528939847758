import React from "react";
// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const FAQ = (props) => {
  const faqs = [
    {
      id: 1,
      question: "How early should I arrive?",
      answer:
        "We recommend arriving at least 20-30 minutes early. This will give you plenty of time to check in, or purchase your tickets. If you arrive too much later, there is a chance you'll miss the beginning of an award winning tour. And we can't have that, now, can we?",
    },
    {
      id: 2,
      question: "Will there still be a tour if the weather is bad?",
      answer:
        "Yes! Tours go on rain or shine, and we highly recommend wearing the appropriate attire. Bust out those rain boots, ladies and gents! In the case of something catastrophic, say a tornado or shoulder-high flood, we'll contact you to reschedule before you risk your life.",
    },
    {
      id: 3,
      question: "...but it's raining!",
      answer:
        "Well, umbrellas are pretty nifty and quite useful in the event of rain. Did you know that the first recorded use of an umbrella was in 21 AD? Seriously, we suggest investing in one, even if you don't come on our tour. Of course, you could always opt for the trendier poncho as well.",
    },
    {
      id: 4,
      question: "Can kids come on the tour?",
      answer:
        "We offer over a dozen different tours each with their own unique offering. Please check each individual tour page for a more detailed description of what that tour entails. Some tours are off limits to those 21 and under.",
    },
    {
      id: 5,
      question: "Is the tour stroller friendly?",
      answer:
        "This can vary, depending on the tour. See each tour page for additional details.",
    },
  ];

  let getSchema = () => {
    let schemaFaq = [];
    let count = 0;
    while (count < faqs.length) {
      schemaFaq.push({
        "@type": "Question",
        name: faqs[count].question,
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>" + faqs[count].answer + "</p>",
        },
      });
      count = count + 1;
    }

    const schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: schemaFaq,
    };

    return schema;
  };

  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions (FAQ) | American Legacy Tours</title>
        <script type="application/ld+json">
          {JSON.stringify(getSchema())}
        </script>
      </Helmet>
      <Container style={{ paddingTop: 50, paddingBottom: 100 }}>
        <div className="sectionTitle d-none d-sm-block biggerthansmall">
          <h2>
            <span itemprop="name">Frequently Asked Questions</span>
          </h2>
        </div>
        <h2
          className="d-block d-md-none smallonly"
          itemprop="name"
          style={{ textAlign: "center" }}
        >
          Frequently Asked Questions
        </h2>
        {faqs.map((faq) => (
          <Accordion defaultActiveKey="0" key={faq.id}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={faq.id}>
                  {faq.question}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={faq.id}>
                <Card.Body>{faq.answer}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))}
      </Container>
    </>
  );
};
export default FAQ;
