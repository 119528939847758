import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { getTour } from "../Api/TourApi";
import { Image } from "cloudinary-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Banner from "../Components/Banner";

const BuyPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [tourInfo, setTourInfo] = useState({});
  const history = useHistory();

  useEffect(() => {
    let cancelled = false;

    async function fetchTour() {
      const response = await getTour(props.match.params.slug);
      // console.log(response);
      if (!response.data[0]) {
        history.push("/");
      }
      if (!cancelled) {
        setTourInfo(response.data[0]["attributes"]); //0 index because same method for one or all
        setLoading(false);
      }
    }
    fetchTour();

    return () => {
      cancelled = true;
    };
  }, [props.match.params.slug, history]);

  return (
    <>
      {loading && (
        // <div className="App" style={{ height: "4000px" }}>
        //   Loading...
        // </div>
        <Row style={{ textAlign: "center", height: "4000px" }}>
          <Col xs="12">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {!loading && (
        <>
          <Helmet>
            <title>{tourInfo.title + " | Purchase Tickets"}</title>
          </Helmet>
          <section
            className="pageTitle"
            style={{
              padding: "30px",
              color: "#ffffff",
              // height: "200px",
              backgroundImage: tourInfo.topPath
                ? "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
                  tourInfo.topPath +
                  "')"
                : "url('https://www.americanlegacytours.com/img/pages/page-title-bg.jpg')",
              backgroundPosition: "center",
            }}
          >
            <Container>
              <Col>
                <div className="titleTable">
                  <div className="titleTableInner">
                    <div
                      className="pageTitleInfo"
                      style={{ textAlign: "center" }}
                    >
                      <h1>{tourInfo.title}</h1>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>
          </section>
          <Row style={{ margin: "10px" }}>
            <Col sm={3}>
              <div style={{ textAlign: "center" }}>
                <a href={"/" + tourInfo.pathTitle}>
                  <Image
                    cloudName="american-legacy-tours"
                    publicId={tourInfo.picturePath}
                    style={{ height: "200px" }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <br />
                <a href={"/" + tourInfo.pathTitle} className="btn">
                  See Tour Information
                </a>
              </div>
              <Banner/>
            </Col>
            <Col sm={9}>
              <iframe
                className="iframe-class"
                style={{ height: "2000px" }}
                src={
                  "https://americanlegacytours.thundertix.com/events/" +
                  tourInfo.thundertixId +
                  "/performances"
                }
                width="100%"
                height="150"
                frameBorder="0"
                marginwidth="0"
                marginheight="0"
                scrolling="auto"
                title="calendar"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default BuyPage;
