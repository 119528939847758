import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";

const Hiring = (props) => {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: "Tour Guide",
    description:
      '<p>Looking for a fun job in Cincinnati or Newport? Now hiring tour guides! Do you have a need to stand in front of strangers entertaining them with stories from our past? We\'re looking for enthusiastic and entertaining people who can project their voice in front of a large crowd. Must be available on weekends and weekdays if possible. We pay $12/hour plus tips. If interested please email <a href="mailto:craigmaness@americanlegacytours.com?subject=Tour%20Guide%20Position">craigmaness@americanlegacytours.com</a> with resume attached</p>',
    identifier: {
      "@type": "PropertyValue",
      name: "American Legacy Tours",
      value: "2022092101",
    },
    datePosted: "2022-09-21",
    validThrough: "2022-12-31T00:00",
    employmentType: "CONTRACTOR",
    hiringOrganization: {
      "@type": "Organization",
      name: "American Legacy Tours",
      sameAs: "http://www.americanlegacytours.com",
      logo: "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/logo-color-big.png",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "1332 Vine St",
        addressLocality: "Cincinnati",
        addressRegion: "OH",
        postalCode: "45202",
        addressCountry: "US",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        value: 12.0,
        unitText: "HOUR",
      },
    },
  };
  return (
    <>
      <Helmet>
        <title>Hiring | American Legacy Tours</title>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile.jpg')",
          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Now Hiring</h2>
              <p
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                Looking for a fun job in Cincinnati or Newport? Now hiring tour
                guides! Do you have a need to stand in front of strangers
                entertaining them with stories from our past? We're looking for
                enthusiastic and entertaining people who can project their voice
                in front of a large crowd. Must be available on weekends and
                weekdays if possible. We pay $12/hour plus tips. If interested
                please email{" "}
                <a href="mailto:craigmaness@americanlegacytours.com?subject=Tour Guide Position">
                  craigmaness@americanlegacytours.com
                </a>{" "}
                with resume and use subject "Tour Guide Position"
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Hiring;
