import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "cloudinary-react";
import { Helmet } from "react-helmet";

const Friends = (props) => {
  return (
    <>
      <Helmet>
        <title>Our Friends | American Legacy Tours</title>
      </Helmet>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile')",

          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2>Our Friends</h2>
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              style={{
                fontSize: "110%",
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
                padding: 20,
                textAlign: "center",
                border: "2px solid white",
              }}
            >
              <a
                href="https://www.northernrow.com"
                title="Northern Row"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="Northern Row Logo"
                  cloudName="american-legacy-tours"
                  publicId="/img/friends/northern_row_logo"
                  style={{ maxHeight: "170px", maxWidth: "80%" }}
                  loading="lazy"
                  fetch_format="auto"
                />
              </a>
              <br />
              <br />
              {/* <br /> */}
              {/* <div style={{ color: "#000000" }}>&nbsp;&nbsp;</div> */}
            </Col>
            <Col
              lg={6}
              style={{
                fontSize: "110%",
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
                padding: 20,
                textAlign: "center",
                border: "2px solid white",
              }}
            >
              <a
                href="https://www.citycenterproperties.com"
                title="City Center Properties"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="City Center Properties"
                  cloudName="american-legacy-tours"
                  publicId="/img/friends/city_center_properties"
                  style={{ maxWidth: "80%" }}
                  loading="lazy"
                  fetch_format="auto"
                />
              </a>
              <br />
              {/* <div style={{ color: "#000000" }}>&nbsp;&nbsp;</div> */}
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              style={{
                fontSize: "110%",
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
                padding: 20,
                textAlign: "center",
                border: "2px solid white",
              }}
            >
              <a
                href="https://modelgroup.net"
                title="Model Group"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="Model Group"
                  cloudName="american-legacy-tours"
                  publicId="/img/friends/modelgroup_logo"
                  style={{ maxHeight: "170px", maxWidth: "80%" }}
                  loading="lazy"
                  fetch_format="auto"
                />
              </a>
              <div style={{ color: "#000000" }}>&nbsp;&nbsp;</div>
            </Col>
            <Col
              lg={6}
              style={{
                fontSize: "110%",
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
                padding: 20,
                textAlign: "center",
                border: "2px solid white",
              }}
            >
              <a
                href="https://www.adamandkeli.com"
                title="Adam &amp; Keli Photography"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="Adam &amp; Keli Photography Logo"
                  cloudName="american-legacy-tours"
                  publicId="/img/friends/aplusk-logo"
                  style={{ maxWidth: "80%" }}
                  loading="lazy"
                  fetch_format="auto"
                />
              </a>
              <br />
              <br />
              <br />
              <div style={{ color: "#000000" }}>Our Official Photographers</div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              style={{
                fontSize: "110%",
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
                padding: 20,
                textAlign: "center",
                border: "2px solid white",
              }}
            >
              <a
                href="https://queencityzbini.com"
                title="Queen City Zumbini"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt="Queen City Zumbini"
                  cloudName="american-legacy-tours"
                  publicId="/img/friends/queencity_zumbini_logo"
                  style={{ maxWidth: "80%" }}
                  loading="lazy"
                  fetch_format="auto"
                />
              </a>
              <div style={{ color: "#000000" }}>
                Created by Zumba® and BabyFirst, Zumbini® is an early childhood
                education program that uses original music and movement to
                promote cognitive, social, fine/gross motor skills, and
                emotional development for children
              </div>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container>
          <Row>
            <Col>
              <h2>Attributions</h2>
              <div
                classNames="col-md-offset-3 col-md-6 col-xs-12 vcenter"
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: 20,
                  border: "1px solid black",
                }}
              >
                <a href="https://www.freepik.com/free-photo/wooden-texture_1000699.htm#query=seamless%20wood%20texture&position=19&from_view=search">
                  Wood Background Image by lyashenko
                </a>
                &nbsp;on Freepik
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Friends;
