import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Body from "../Components/Body";
import { Helmet } from "react-helmet";

const Home = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    image: [
      "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/home/slider/slider-01.jpg",
      "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/home/slider/slider-03.jpg",
      "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/home/slider/slider-02.jpg",
      "http://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/home/slider/slider-04.jpg",
    ],
    name: "American Legacy Tours",
    description:
      "American Legacy Tours offers a variety of walking tours in the Cincinnati, Covington, and Newport areas from haunted tours to underground tours and historical tours to food and drink tours.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1332 Vine St",
      addressLocality: "Cincinnati",
      addressRegion: "OH",
      postalCode: "45202",
      addressCountry: "US",
    },
    // review: {
    //   "@type": "Review",
    //   reviewRating: {
    //     "@type": "Rating",
    //     ratingValue: "4.9",
    //     bestRating: "5",
    //   },
    //   author: {
    //     "@type": "Person",
    //     name: "First Last",
    //   },
    // },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 39.1106104,
      longitude: -84.51524,
    },
    url: "https://www.americanlegacytours.com",
    email: "mailto:questions@americanlegacytours.com",
    telephone: "+18599518560",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      bestRating: "5.0",
      ratingCount: "1670",
    },
    sameAs:
      "https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html",
    sameAs: "https://www.facebook.com/AmericanLegacyTours/?ref=br_rs",
    sameAs: "https://www.instagram.com/americanlegacycincy/?hl=en",
    priceRange: "$25-$60",
    potentialAction: {
      "@type": "ReserveAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: "https://www.americanlegacytours.com",
        inLanguage: "en-US",
        actionPlatform: [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/IOSPlatform",
          "http://schema.org/AndroidPlatform",
        ],
      },
      result: {
        "@type": "ReservationPackage",
        name: "Book a Tour",
      },
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        opens: "9:00",
        closes: "16:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "Sunday",
        opens: "8:00",
        closes: "18:00",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Body />
    </>
  );
};
export default Home;
