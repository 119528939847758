import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import { Image } from "cloudinary-react";
import { SocialIcon } from "react-social-icons";

const isNotMobile = window.screen.width > 600;
const isMobile = window.screen.width < 600;

const tripadvisor_icon = {
  icon: "M72,36c0,19.9-16.1,36-36,36S0,55.9,0,36C0,16.1,16.1,0,36,0S72,16.1,72,36",
  mask: "M 52.542969 26.089844 L 56.421875 21.847656 L 47.757812 21.847656 C 43.421875 18.867188 38.1875 17.152344 32.5 17.152344 C 26.8125 17.152344 21.578125 18.867188 17.242188 21.847656 L 8.578125 21.847656 L 12.457031 26.089844 C 10.019531 28.257812 8.578125 31.417969 8.578125 34.9375 C 8.578125 41.527344 13.902344 46.855469 20.492188 46.855469 C 23.652344 46.855469 26.453125 45.679688 28.617188 43.695312 L 32.5 47.847656 L 36.292969 43.695312 C 38.457031 45.679688 41.257812 46.855469 44.417969 46.855469 C 51.007812 46.855469 56.332031 41.527344 56.332031 34.9375 C 56.421875 31.417969 54.890625 28.257812 52.542969 26.089844 M 20.582031 42.972656 C 16.070312 42.972656 12.457031 39.359375 12.457031 34.847656 C 12.457031 30.332031 16.070312 26.722656 20.582031 26.722656 C 25.097656 26.722656 28.707031 30.332031 28.707031 34.847656 C 28.617188 39.359375 25.007812 42.972656 20.582031 42.972656 M 32.5 34.667969 C 32.5 29.339844 28.617188 24.734375 23.5625 22.839844 C 26.359375 21.667969 29.339844 21.035156 32.5 21.035156 C 35.660156 21.035156 38.730469 21.667969 41.4375 22.839844 C 36.382812 24.828125 32.5 29.339844 32.5 34.667969 M 44.417969 42.972656 C 39.902344 42.972656 36.292969 39.359375 36.292969 34.847656 C 36.292969 30.332031 39.902344 26.722656 44.417969 26.722656 C 48.929688 26.722656 52.542969 30.332031 52.542969 34.847656 C 52.542969 39.359375 48.929688 42.972656 44.417969 42.972656 M 44.417969 30.695312 C 42.070312 30.695312 40.171875 32.589844 40.171875 34.9375 C 40.171875 37.285156 42.070312 39.179688 44.417969 39.179688 C 46.765625 39.179688 48.660156 37.285156 48.660156 34.9375 C 48.660156 32.589844 46.765625 30.695312 44.417969 30.695312 M 24.828125 34.9375 C 24.828125 37.285156 22.929688 39.179688 20.582031 39.179688 C 18.234375 39.179688 16.339844 37.285156 16.339844 34.9375 C 16.339844 32.589844 18.234375 30.695312 20.582031 30.695312 C 22.929688 30.695312 24.828125 32.589844 24.828125 34.9375 ",
  color: "#ffffff",
};

const Footer = (props) => {
  return (
    <footer>
      <div
        className="footer clearfix"
        style={{ backgroundColor: "#262a2e", padding: "50px" }}
      >
        <Container>
          <Row>
            <Col xs={12} className="footerContent">
              <h5 style={{ color: "#FFFFFF" }}>Contact Us</h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.google.com/maps/place/American+Legacy+Tours/@39.1106104,-84.51524,15z/data=!4m5!3m4!1s0x0:0xfae885fabe92c950!8m2!3d39.1106104!4d-84.51524"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#FFFFFF" }}
                  >
                    1332 Vine St, Cincinnati, OH 45202
                  </a>
                </li>
                <li>
                  <a href="tel:8599518560" style={{ color: "#FFFFFF" }}>
                    (859)&nbsp;951-8560
                  </a>
                </li>
                <li>
                  <a
                    href="mailTo:questions@americanlegacytours.com"
                    style={{ color: "#FFFFFF" }}
                  >
                    questions@americanlegacytours.com
                  </a>
                </li>
              </ul>
              <SocialIcon
                network="facebook"
                url="https://www.facebook.com/AmericanLegacyTours"
                target="_blank"
                fgColor="#ffffff"
                style={{ width: 40, height: 40 }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SocialIcon
                network="twitter"
                url="https://twitter.com/AmLegacyTours"
                target="_blank"
                fgColor="#ffffff"
                style={{ width: 40, height: 40 }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SocialIcon
                network="instagram"
                url="https://www.instagram.com/americanlegacycincy"
                target="_blank"
                fgColor="#ffffff"
                style={{ width: 40, height: 40 }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SocialIcon
                label="Trip Advisor"
                url="https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html"
                target="_blank"
                fgColor="#00aa6c"
                defaultSVG={tripadvisor_icon}
                style={{ width: 40, height: 40 }}
              />
              <br />
              <br />
              <a
                href="/about-us"
                style={{ fontSize: "120%", color: "#FFFFFF" }}
              >
                About Us
              </a>
              <span style={{ fontSize: "120%", color: "white" }}>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              {isNotMobile && (
                <a href="/faq" style={{ fontSize: "120%", color: "#FFFFFF" }}>
                  Frequently Asked Questions
                </a>
              )}
              {isMobile && (
                <a href="/faq" style={{ fontSize: "120%", color: "#FFFFFF" }}>
                  FAQ
                </a>
              )}
            </Col>
            {/* <Col sm={4} xs={12}>
              <div className="footerContent">
                <h5 style={{ marginBottom: "35px", color: "#FFFFFF" }}>
                  Newsletter
                </h5>
                <a href="/" className="footer-logo">
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/logo"
                    style={{ width: "95%", marginTop: "30px" }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div
        className="copyRight clearfix"
        style={{
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile')",
          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <Row style={{ padding: "20px 0" }}>
            <Col md={6}>
              <div className="copyRightText">
                <p>
                  Copyright © 2022. All Rights Reserved by{" "}
                  <a href="/" style={{ color: "#FFFFFF" }}>
                    American Legacy Tours
                  </a>
                  .
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
export default Footer;
