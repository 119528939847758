import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";

const About = (props) => {
  return (
    <>
      <Helmet>
        <title>About Us | American Legacy Tours</title>
      </Helmet>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/cincinnati') no-repeat center center fixed",
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Our Story</h2>
              <p
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                In 2009 a group of five friends in Newport, Kentucky embarked on a mission to support their local school through a unique fundraising event – the Newport Gangster Tour. Little did we know that this endeavor would mark the inception of American Legacy Tours, a venture fueled by our passion for storytelling and an unwavering commitment to unraveling the rich tapestry of Cincinnati and Northern Kentucky's history for both locals and visitors.
                <br/>
                <br/>
                The unexpected and overwhelming success of the Newport Gangster Tour was a catalyst, propelling them to expand their horizons and establish American Legacy Tours officially. Our goal and commitment are to explore exclusive spaces of history with our guests and become America's premier tour company by informing, educating, and entertaining people with the unique histories of communities worldwide.
              </p>
              <br />
              <p
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                In 2010, we introduced our second tour, the Queen City Underground Tour, nestled in the heart of Over the Rhine. Quickly gaining popularity, this tour became a cornerstone, delving into the history of OTR and Cincinnati's illustrious brewing heritage.
              </p>
              <br />
              <p
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                Today, American Legacy Tours stands as a beacon of immersive experiences, boasting a diverse repertoire of guided tours that cater to a wide range of interests. Our seasonal Haunted Tours, designed to send shivers down your spine, are a testament to our commitment to providing unforgettable and thrilling experiences. Whether you're seeking historical insights, spine-tingling tales, or culinary adventures, our portfolio spans Underground and Historic Tours to Haunted and Food and Drink Tours.
                <br/>
                <br/>
                Beyond our scheduled tours, we proudly offer private tours tailored for corporate events, team-building activities, family celebrations, reunions, and special events.
              </p>
              <br />
              <p
                style={{
                  fontSize: "110%",
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  border: "5px solid white",
                }}
              >
                The acclaim we've received speaks volumes about the quality of our experiences. Cincinnati Magazine has honored us with the prestigious title of "Best Historical Tour." Furthermore, our recognition from industry giants such as National Geographic, Forbes Travel, BBC America, Politico, New York Daily News, Midwest Living, and Fodor's Travel reinforces our position as a leader in the tour industry. We're thrilled to be acknowledged as a must-see by Lonely Planet.
                <br/>
                <br/>
                We invite you to join us in exploring the fascinating region we call home. Prepare for an unforgettable journey through time and tales, as American Legacy Tours opens the door to the captivating history and hidden gems of our vibrant community.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default About;
