import React from "react";
// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Calendar = (props) => {
  return (
    <>
      <Helmet>
        <title>Tour Event Calendar | American Legacy Tours</title>
      </Helmet>
      <iframe
        className="d-block d-md-none smallonly iframe-class"
        style={{ height: "6000px" }}
        src="https://americanlegacytours.thundertix.com/events/"
        width="100%"
        frameBorder="0"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        title="calendar"
      />
      <Container>
        <Row>
          <Col>
            <iframe
              className="d-none d-sm-block biggerthansmall iframe-class"
              style={{ height: "6000px" }}
              src="https://americanlegacytours.thundertix.com/events/"
              width="100%"
              frameBorder="0"
              marginwidth="0"
              marginheight="0"
              scrolling="auto"
              title="calendar"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Calendar;
