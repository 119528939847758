import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";
import { getTourContent } from "../../Api/TourApi";
import Content from "./Content";

const Contents = (props) => {
  const [tourContent, setTourContent] = useState({});

  useEffect(() => {
    let cancelled = false;

    async function fetchTourContent() {
      const response = await getTourContent(props.slug);

      if (!cancelled && response.data[0]) {
        return setTourContent(response.data[0].attributes);
      }
    }
    fetchTourContent();

    return () => {
      cancelled = true;
    };
  }, [props.slug]);

  return (
    <Container>
      <Row style={{ width: "100%" }} key={props.id}>
        {Object.keys(tourContent).map((i) => {
          // console.log(tourContent[i]);
          return <Content content={tourContent[i]} key={i} />;
        })}
      </Row>
    </Container>
  );
};
export default Contents;
