import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TopCarousel from "./Home/TopCarousel";
import Reviews from "../Components/Reviews";
import Tours from "./Tours";
import { Col, Container, Row } from "react-bootstrap";
import { Image, Transformation, Placeholder } from "cloudinary-react";
import { Helmet } from "react-helmet";
import Banner from "./Banner";

// import { helmetJsonLdProp } from "react-schemaorg";
// import { WebSite } from "schema-dts";

const Body = (props) => {
  return (
    <div
      style={{
        backgroundImage:
          "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/wooden-texture2.webp')",
        // "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile')",
        backgroundRepeat: "repeat",
      }}
    >
      <Helmet>
        <title>American Legacy Tours | Historically Entertaining</title>
        {/* <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(data)}
        </script> */}
        <link
          rel="preload"
          as="image"
          fetchpriority="high"
          href="https://res.cloudinary.com/american-legacy-tours/image/upload/c_fill,h_700,w_1800/f_auto/v1/img/home/slider/slider-01"
        />
      </Helmet>
      <TopCarousel />
      <hr />
      <section
        className="promotionWrapper"
        style={{
          backgroundColor: "#FFFFFF",
          backgroundImage:
            "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/wooden-texture2')",
          backgroundRepeat: "repeat",
        }}
      >
        <Container className="themed-container">
          <p
            style={{
              fontSize: "110%",
              backgroundColor: "rgba(255,255,255,0.8)",
              padding: 20,
              marginTop: 20,
              border: "5px solid white",
            }}
          >
            When you're looking for things to do in Cincinnati, American Legacy
            Tours has you covered with the best walking &amp; bus tours in
            Cincinnati, Newport, and Covington! Our walking tours range from
            Food and Drink Tours and Underground Tours to Historic Tours and
            Haunted Tours. Explore the city and experience Cincinnati like never
            before with historically entertaining tours from American Legacy
            Tours.
          </p>
        </Container>
      </section>

      <div className="themed-container" style={{ textAlign: "center" }}>
        <h1 style={{ color: "#FFFFFF", paddingTop: 10 }}>ALL TOURS</h1>
        <Tours />
      </div>

      <section
        className="promotionWrapper"
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        <Container style={{ paddingTop: "50px", minHeight: "300px" }}>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center" }}>
              <Image
                cloudName="american-legacy-tours"
                publicId="/img/home/tripadvisor-logo"
                style={{ width: 365, height: 55 }}
                width="365"
                height="55"
                loading="lazy"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="55" width="365" />
              </Image>
              <br />
              <h2 style={{ color: "#262a2e" }}>
                2022, 2023, &amp; 2024 Travelers Choice Award and Certificate of Excellence
              </h2>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <Image
                cloudName="american-legacy-tours"
                publicId="/img/home/tripadvisor1"
                style={{ width: "119", height: "20" }}
                width="119"
                height="20"
                loading="lazy"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="20" width="119" />
              </Image>
              &nbsp;
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#000000",
                  textDecoration: "underline",
                }}
              >
                <span id="tripadvisorreviewcount">3,304</span> Reviews
              </a>
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html"
                className="btn buttonCustomPrimary"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Details
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ padding: "0 20 50 0" }}>
        <Banner />
      </section>
      <section
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/ceilingtile')",
          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <Row
            style={{
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Reviews numToDisplay={4} className={"d-sm-none d-md-block"} />
          </Row>
          <Row>
            <Col style={{ textAlign: "center", marginTop: 20 }}>
              <a
                href="/reviews"
                style={{
                  fontSize: "110%",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  padding: 20,
                  marginTop: 20,
                  border: "5px solid white",
                }}
              >
                See more
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="promotionWrapper"
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        <Container style={{ paddingBottom: 100, paddingTop: 50 }}>
          <Row>
            <Col md={7}>
              <Image
                className="w-100 d-none d-sm-block biggerthansmall"
                cloudName="american-legacy-tours"
                publicId="/img/home/private_tour"
                style={{ width: "540", height: "360" }}
                width="540"
                height="360"
                loading="lazy"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="360" width="540" />
              </Image>
              <Image
                className="d-block d-sm-none smallonly"
                cloudName="american-legacy-tours"
                publicId="/img/home/private_tour"
                style={{ width: "360", height: "240" }}
                loading="lazy"
                width="360"
                height="240"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="240" width="360" />
              </Image>
            </Col>
            <Col md={5}>
              <h3>Questions?</h3>
              <p>
                If you have any other questions, we'd be happy to help. Maybe
                you can find some answers for yourself over at our{" "}
                <a href="/faq">Frequently Asked Questions</a>. If not, please
                give us a ring or{" "}
                <a href="/contact-us">contact us by clicking here</a> or give us
                a ring.
              </p>
              <a href="tel:8599518560">
                <i className="fa fa-phone"></i>&nbsp;(859)&nbsp;951-8560
              </a>
              <br />
              <br />
              <h3>Private Tours</h3>
              <p>
                American Legacy Tours are the perfect way for your company,
                family and friends to spend time together. Each of our tours can
                be customized to meet the needs of your group. We can even
                handle all the details for you from booking transportation to
                organizing meals and even lodging.
              </p>
              <a href="private-tours.html">Drop us a line by clicking here!</a>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default Body;
