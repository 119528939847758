/*App.js*/
import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/index";
import GCPage from "./pages/gc";
import CalendarPage from "./pages/calendar";
import PrivateToursPage from "./pages/privatetours";
import FAQPage from "./pages/faq";
import ContactPage from "./pages/contact";
import AboutUsPage from "./pages/about";
import FeaturedPage from "./pages/featured";
import FriendsPage from "./pages/friends";
import HiringPage from "./pages/hiring";
import ReviewsPage from "./pages/reviews";
import TourPage from "./pages/tourpage";
import BuyPage from "./pages/buypage";
import CheckoutPage from "./pages/checkoutpage";
import TopNav from "./Components/TopNav";
import Footer from "./Components/Footer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    // Define your styles
    const defaultStyle = {
      marginBottom: "92px",
    };

    const holidayStyle = {
      // marginBottom: "140px",
      // marginBottom: "120px",
      marginBottom: "150px", // gc holiday
      // marginBottom: "174px", //blackfriday desktop
    };

    const mobileHolidayStyle = {
      // marginBottom: "145px",
      marginBottom: "150px", // gc holiday
      // marginBottom: "164px", //blackfriday mobile
    };
    const style = this.state.windowWidth <= 768 ? mobileHolidayStyle : holidayStyle;
    // const style = defaultStyle;
    return (
      <Router>
        <TopNav />
        <div style={style}></div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/index.html" component={HomePage} />

          <Route exact path="/tours" component={HomePage} />

          {/* <Route exact path="/calendar" component={CalendarPage} />
          <Route exact path="/calendar.html" component={CalendarPage} /> */}

          <Route exact path="/gift-certificates" component={GCPage} />
          <Route exact path="/gift-certificates.html" component={GCPage} />

          <Route exact path="/event-calendar" component={CalendarPage} />
          <Route exact path="/event-calendar.html" component={CalendarPage} />

          <Route
            exact
            path="/private-tours.html"
            component={PrivateToursPage}
          />
          <Route exact path="/private-tours" component={PrivateToursPage} />
          <Route
            exact
            path="/private-tours.html"
            component={PrivateToursPage}
          />

          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/faq.html" component={FAQPage} />

          <Route exact path="/contact-us" component={ContactPage} />
          <Route exact path="/contact-us.html" component={ContactPage} />

          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/about" component={AboutUsPage} />

          <Route exact path="/about-us.html" component={AboutUsPage} />
          <Route exact path="/about.html" component={AboutUsPage} />

          <Route exact path="/featured" component={FeaturedPage} />
          <Route exact path="/friends" component={FriendsPage} />
          <Route exact path="/hiring" component={HiringPage} />
          <Route exact path="/reviews" component={ReviewsPage} />

          {/* <Route exact path="/tourgroup/:type.html" component={ContactPage} />
          <Route exact path="/tourgroup/:type" component={ContactPage} /> */}

          <Route
            exact
            path="/:slug/checkout/:performanceid"
            component={CheckoutPage}
          />

          <Route exact path="/:slug/buy" component={BuyPage} />

          {/* {tourInfo &&
            tourInfo.map((item) => (
              <Route
                exact
                path={"/" + tourInfo[item].attributes.pathTitle}
                component={() => (
                  <TourPage
                    tourInfo={item.attributes}
                    key={item.attributes.pathTitle}
                  />
                )}
              />
            ))} */}

          {/* <Route
            exact
            path="/1919-tour.html"
            component={() => (
              <TourPage slug={"1919-tour"} title={"The 1919 Walking Tour"} />
            )}
          />
          <Route
            exact
            path="/1919-tour"
            component={() => (
              <TourPage slug={"1919-tour"} title={"The 1919 Walking Tour"} />
            )}
          /> */}

          <Route exact path="/:slug" component={TourPage} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
