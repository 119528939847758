import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "cloudinary-react";
import { Helmet } from "react-helmet";
import "../masonry.css";
import Masonry from "react-masonry-css";

const About = (props) => {
  const breakpointColumnsObj = {
    default: 4,
    991: 3,
    768: 2,
    // 500: 1,
  };

  return (
    <>
      <Helmet>
        <title>Featured | American Legacy Tours</title>
      </Helmet>
      <hr />
      <section className="mainContentSection">
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Featured In</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                <a
                  href="http://www.bbcamerica.com/anglophenia/2014/02/05/5-different-ways-brits-see-america"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/bbc"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.cincyweekend.com/queen-city-underground-tour"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/cincyweekend"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.nydailynews.com/life-style/beer-baseball-traditions-drive-home-fun-cincinnati-article-1.2259160"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/dailynewsnewyork"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.fodors.com/world/north-america/usa/ohio/cincinnati/experiences/news/long-weekend-in-cincinnati-11724"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/fodorstravel"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="https://stories.forbestravelguide.com/5-reasons-cincinnati-is-on-our-radar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/forbes"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="https://www.lonelyplanet.com/usa/great-lakes/cincinnati/travel-tips-and-articles/offbeat-cincinnati-going-deep-in-ohios-quirkiest-city/40625c8c-8a11-5710-a052-1479d276befc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/lonelyplanet"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="https://www.nationalgeographic.com/travel/city-guides/cincinnati-traveler"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/natgeo"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="https://www.nytimes.com/interactive/2017/08/17/travel/what-to-do-36-hours-in-cincinnati-ohio.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/nyt"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="https://www.politico.com/magazine/story/2016/06/what-works-cincinnati-ohio-over-the-rhine-crime-neighborhood-turnaround-city-urban-revitalization-213969"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/politico"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.midwestliving.com/blog/travel/top-10-things-for-active-travelers-to-do-cincinnati"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/midwest"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.fox19.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/fox19"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.cincinnatimagazine.com/top5/top-5-cincinnati-tours"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/cincinnatimagazine"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://www.cincinnatimagazine.com/top5/top-5-cincinnati-tours"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/cincinnatirefined"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <a
                  href="http://thesouthernweekend.com/history-spookiness-queen-city-underground-tour"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    cloudName="american-legacy-tours"
                    publicId="/img/press/southernweekend"
                    // height="130"
                    // width="130"
                    crop="fill"
                    quality="auto"
                    style={{
                      width: "100%",
                      padding: 20,
                    }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
              </Masonry>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default About;
