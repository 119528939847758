import axios from "axios";
import moment from "moment";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export async function getTours() {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=tour&slug=all" //requires additional "attributes" remove to use others
  );
  return response;
}

export async function getTourAvailability(thundertixId) {
  const response = await axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=availability&thundertixid=" +
      thundertixId
  );

  let remainingList = [];
  let c = 0;
  for (let i = 0; i < response.data.length; i = i + 1) {
    let eventDetails = response.data[i];
    if (c > 5) {
      break;
    } else {
      // EXAMPLE THUNDERTIX FORMAT: 2022-02-26T10:30:00.000-06:00
      let now = moment();
      let datetime = moment(eventDetails.time, "YYYY-MM-DDTHH:mm:ss.SSSZ");

      let hour_offset = 6;
      if (
        datetime.format("M") == "4" ||
        datetime.format("M") == "5" ||
        datetime.format("M") == "6" ||
        datetime.format("M") == "7" ||
        datetime.format("M") == "8" ||
        datetime.format("M") == "9" ||
        datetime.format("M") == "10" ||
        (datetime.format("M") == "3" && datetime.format("D") >= 13) ||
        (datetime.format("M") == "11" && datetime.format("D") < 6)
      ) {
        hour_offset = 5;
      }

      if (now.isBefore(datetime)) {
        remainingList.push({
          dayofweek: datetime.format("dddd"),
          month: datetime.format("MMM"),
          day: datetime.format("D"),
          year: datetime.format("YYYY"),
          time:
            datetime.zone(hour_offset * 60).format("h") +
            ":" +
            datetime.format("mm"), //5 hours behind GMT
          ampm: datetime.format("A"),
          short: datetime.format("M/D/YY"),
          long: datetime.format("YYYY-MM-DDTHH:mm-05:00"),
          availability:
            eventDetails.seats_capacity === 0
              ? 0
              : eventDetails.remaining_seats < 0
              ? 0
              : eventDetails.remaining_seats,
          performaceId: eventDetails.id,
          totalShowings: response.data.length,
        });
        c++;
      }
    }
  }
  return remainingList;
}
// const htmlRoot = HTMLParser.parse(response.data);
// let remainingList = {};

// const performanceRows = htmlRoot.querySelectorAll("tr");
// for (let i = 0; i < performanceRows.length; i++) {
//   let performanceRow = performanceRows[i];
//   const columns = performanceRow.querySelectorAll("td");
//   let column = columns[1];
//   let buyColumn = columns[3];
//   const time = column.querySelector("time");
//   let remaining = column.querySelector(".tix_remainder");
//   let buyButton = buyColumn.querySelector("a");
//   if (remaining != null) {
//     let numRemaining = remaining.querySelector("span");
//     if (numRemaining != null) {
//       let num = numRemaining.text;

//       // let params = new URLSearchParams(buyButton.attributes.href);
//       // let foo = params.get("performance_id");
//       // console.log(params);

//       if (num) {
//         remainingList[time.text] = {
//           availability: num,
//           performanceId: buyButton.attributes.href.split("=", 2)[1],
//         };
//       }
//     }
//   } else {
//     if (buyButton) {
//       remainingList[time.text] = {
//         availability: 999,
//         performanceId: buyButton.attributes.href.split("=", 2)[1],
//       };
//       // } else { //USEFUL IF WANT TO SHOW SOLD OUT ON MAIN PAGE
//       //   remainingList[time.text] = {
//       //     availability: 0,
//       //     link: "",
//       //   };
//     }
//   }
// }
// return remainingList;
// }

export async function getToursBasicInfo() {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=tour&slug=all"
  );
  return response;
}

export async function getTour(slug) {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=tour&slug=" +
      slug
  );
  return response;
}

export async function getTourContent(slug) {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=content&slug=" +
      slug
  );
  return response;
}

export async function getTourPhotos(slug) {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=photos&slug=" +
      slug
  );
  return response;
}

export async function getTourGroups(slug) {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=groups&slug=" +
      slug
  );
  return response;
}

export async function getTourLocations(slug) {
  const response = axios.get(
    "https://wlsj713nkb.execute-api.us-east-2.amazonaws.com/default/altGetTours?type=locations&slug=" +
      slug
  );
  return response;
}
