import React, { useEffect, useState } from "react";
import { Col, Card, Image } from "react-bootstrap";
import { BsFillStarFill } from "react-icons/bs";

// https://api.reviewsmaker.com/gmb/?placeid=ChIJoW6Lhv2zQYgRUMmSvvqF6Po

const Reviews = (props) => {
  const reviews = [
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlMWZhUUxnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlMWZhUUxnEi4KF0NJSE0wb2dLRUlDQWdJQ2UxZmFRcmdFEhNDZ3dJN05Dam1RWVE2SUR0amdJGi0KFkNJSE0wb2dLRUlDQWdJQ2UxZmFRYmcSE0Nnd0k3TkNqbVFZUTZJRHRqZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STdOQ2ptUVlRNklEdGpnSQ&wv=1&d=286732320",
      plusId: "117502905236431271025",
      reviewAuthor: "dennies321",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0EQ8FqVfe5NUtkr76sZNoV0uEJ4RWrCS13EXZf=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a day ago",
      reviewRating: 5,
      reviewText:
        "Patrick was a great tour guide on the Queen City underground tour, definitely worth the experience",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlMWZhUUxnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe1faQLg%7CCgwI7NCjmQYQ6IDtjgI%7C?hl=en-US",
      rawDate: "09-19-2022 10:08 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlbGFIQ3N3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlbGFIQ3N3RRItChZDSUhNMG9nS0VJQ0FnSUNlbGFIQ2N3EhNDZ3dJdnRLaW1RWVE2TDNEc1FJGi4KF0NJSE0wb2dLRUlDQWdJQ2VsYUhDOHdFEhNDZ3dJdnRLaW1RWVE2TDNEc1FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2dEtpbVFZUTZMM0RzUUk&wv=1&d=286732320",
      plusId: "101434380752578595391",
      reviewAuthor: "Sienna Glaze",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9iNOcVXwz_apj9mMNQnIYktVZHLpeUtALswRVAVw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a day ago",
      reviewRating: 5,
      reviewText:
        "Miranda was absolutely fantastic. She took 10 college-aged people on a private tour for a birthday celebration, and we could not have asked for a better experience! She took the time to get to know us and really made the tour personable. I would absolutely go back.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlbGFIQ3N3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICelaHCswE%7CCgwIvtKimQYQ6L3DsQI%7C?hl=en-US",
      rawDate: "09-19-2022 5:39 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlbGJxeHFBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlbGJxeHFBRRItChZDSUhNMG9nS0VJQ0FnSUNlbGJxeGFBEhNDZ3dJOHNDaW1RWVE0SmJ2ekFJGi4KF0NJSE0wb2dLRUlDQWdJQ2VsYnF4NkFFEhNDZ3dJOHNDaW1RWVE0SmJ2ekFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k4c0NpbVFZUTRKYnZ6QUk&wv=1&d=286732320",
      plusId: "101259303665013527261",
      reviewAuthor: "Emma Hunt",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_9VoxoYjR_Pmdu3bjryOvtMbOtiwtnp2P_KgJF=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a day ago",
      reviewRating: 5,
      reviewText:
        "we had Miranda for our private tour and she was INCREDIBLE. We had a private tour of 10 college theatre students and she totally centered the tour around us! She made each of us feel so special and it was the best way to celebrate a 21st birthday. She also made history so fun and approachable. Miranda really made our group feel super taken care of. We love Miranda.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlbGJxeHFBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICelbqxqAE%7CCgwI8sCimQYQ4JbvzAI%7C?hl=en-US",
      rawDate: "09-19-2022 5:01 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlNVlhbzRnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlNVlhbzRnRRItChZDSUhNMG9nS0VJQ0FnSUNlNVlhb0VnEhNDZ3dJLXRpaG1RWVF1Sm5landNGi4KF0NJSE0wb2dLRUlDQWdJQ2U1WWFva2dFEhNDZ3dJLXRpaG1RWVF1Sm5landNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0ktdGlobVFZUXVKbmVqd00&wv=1&d=286732320",
      plusId: "101597493249470304818",
      reviewAuthor: "Katt Stanley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8unYim4nkNJ1gvo9PyyzTlCQ6dgCkqQ1rkYKg=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "a day ago",
      reviewRating: 5,
      reviewText: "angela & Troy were amazing!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlNVlhbzRnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe5Yao4gE%7CCgwI-tihmQYQuJnejwM%7C?hl=en-US",
      rawDate: "09-19-2022 1:19 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNleFp5ZkN3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNleFp5ZkN3Ei0KF0NJSE0wb2dLRUlDQWdJQ2V4ZHk0a3dFEhJDZ3NJNnFTZm1RWVEyTTZuR2caLAoWQ0lITTBvZ0tFSUNBZ0lDZXhkeTRVdxISQ2dzSTZxU2ZtUVlRMk02bkdnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k2cVNmbVFZUTJNNm5HZw&wv=1&d=286732320",
      plusId: "101319762575354048811",
      reviewAuthor: "Molly Franzen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-jLhbyHpXzDntLgDTOpbO-oeyZFFJ_nkVNEKxK1w=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 days ago",
      reviewRating: 5,
      reviewText:
        "This was by far the best tour i have ever experienced. miranda was the greatest guide our group could have asked for! she was so informed, and constantly catered to our groups interests and needs. I would recommend this tour to anyone/everyone in cincinnati, local or not! Especially with miranda because she made the experience!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNleFp5ZkN3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICexZyfCw%7CCgsI6qSfmQYQ2M6nGg%7C?hl=en-US",
      rawDate: "09-19-2022 2:22 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNleFp5aUl3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNleFp5aUl3Ei4KF0NJSE0wb2dLRUlDQWdJQ2V4Wnlpb3dFEhNDZ3dJeTZPZm1RWVF3TVQzcFFJGi0KFkNJSE0wb2dLRUlDQWdJQ2V4WnlpWXcSE0Nnd0l5Nk9mbVFZUXdNVDNwUUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXk2T2ZtUVlRd01UM3BRSQ&wv=1&d=286732320",
      plusId: "107373895551908950058",
      reviewAuthor: "Benjamin",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu33h1utE9VBvlvt86r5d3XWCbnuknDxnVPFNAqr=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 days ago",
      reviewRating: 5,
      reviewText:
        "What an amazing night! Miranda was so wonderful to me and my group and I would absolutely do it again! Thank you so much!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNleFp5aUl3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICexZyiIw%7CCgwIy6OfmQYQwMT3pQI%7C?hl=en-US",
      rawDate: "09-19-2022 2:19 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlNmVlNE53EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlNmVlNE53Ei4KF0NJSE0wb2dLRUlDQWdJQ2U2ZWU0dHdFEhNDZ3dJaTRPWm1RWVFxTldGeWdJGi0KFkNJSE0wb2dLRUlDQWdJQ2U2ZWU0ZHcSE0Nnd0lpNE9abVFZUXFOV0Z5Z0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWk0T1ptUVlRcU5XRnlnSQ&wv=1&d=286732320",
      plusId: "109720737493972515388",
      reviewAuthor: "Ally Riesenberg",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2goJvMhFo8gFQ19B-s--vHodnjPGdNEYKtt5uk=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 days ago",
      reviewRating: 5,
      reviewText:
        "I grew up here in Cincinnati and still learned a ton on this tour! Awesome, well paced and Laura was a great tour guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlNmVlNE53EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe6ee4Nw%7CCgwIi4OZmQYQqNWFygI%7C?hl=en-US",
      rawDate: "09-17-2022 9:52 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlNmZ1M19RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlNmZ1M19RRRIsChZDSUhNMG9nS0VJQ0FnSUNlNmZ1M0F3EhJDZ3NJN2Y2WW1RWVFpSi1yT0EaLQoXQ0lITTBvZ0tFSUNBZ0lDZTZmdTNnd0USEkNnc0k3ZjZZbVFZUWlKLXJPQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJN2Y2WW1RWVFpSi1yT0E&wv=1&d=286732320",
      plusId: "117577385326952167672",
      reviewAuthor: "Nicholas Mattox",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9eRmvS9ThxA63SeXIouwXsZDXBdf49OOH9GPVP=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 days ago",
      reviewRating: 5,
      reviewText:
        "Miranda did an amazing job giving us a tour. She knows her history about Cincinnati especially for being an Australian\ud83d\ude09",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlNmZ1M19RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe6fu3_QE%7CCgsI7f6YmQYQiJ-rOA%7C?hl=en-US",
      rawDate: "09-17-2022 9:43 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlNmR1QmFBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlNmR1QmFBEi4KF0NJSE0wb2dLRUlDQWdJQ2U2ZHVCNkFFEhNDZ3dJMmZ1WW1RWVFpTkNVelFNGi0KFkNJSE0wb2dLRUlDQWdJQ2U2ZHVCR0ESE0Nnd0kyZnVZbVFZUWlOQ1V6UU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STJmdVltUVlRaU5DVXpRTQ&wv=1&d=286732320",
      plusId: "113282146779031220215",
      reviewAuthor: "Dolly Carwile",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu969m4fIunpzzEPMdrGaKaVtKSXxSXGmLLneNp4RcE=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "3 days ago",
      reviewRating: 5,
      reviewText:
        "We toured with Laura who was fantastic. She was very informative on the locations and knowledgeable when we asked questions. Would definitely do another tour with her.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlNmR1QmFBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe6duBaA%7CCgwI2fuYmQYQiNCUzQM%7C?hl=en-US",
      rawDate: "09-17-2022 9:36 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlNmV2WTJ3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlNmV2WTJ3RRItChZDSUhNMG9nS0VJQ0FnSUNlNmV2WU93EhNDZ3dJMmZpWW1RWVEyTUhxaWdNGi4KF0NJSE0wb2dLRUlDQWdJQ2U2ZXZZdXdFEhNDZ3dJMmZpWW1RWVEyTUhxaWdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kyZmlZbVFZUTJNSHFpZ00&wv=1&d=286732320",
      plusId: "115494790415165274240",
      reviewAuthor: "Renee Brownlie",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0gSUNGCsLgLW94mp8-mXp7FoWIqdwnYceq2GUC=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 days ago",
      reviewRating: 5,
      reviewText:
        "We took a tour today with Laura. It was awesome. We had only 4 people in our group. We did this tour with our friends from Florida and I have only lived here for 2 years and my girlfriend was born and raised in cincinnati. We all learned something and had great conversations with Laura! Highly recommend the ultimate underground tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlNmV2WTJ3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe6evY2wE%7CCgwI2fiYmQYQ2MHqigM%7C?hl=en-US",
      rawDate: "09-17-2022 9:30 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlOFlfNjNBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlOFlfNjNBRRItChZDSUhNMG9nS0VJQ0FnSUNlOFlfNlBBEhNDZ3dJeGZ1VW1RWVEwTHZZdGdNGi4KF0NJSE0wb2dLRUlDQWdJQ2U4WV82dkFFEhNDZ3dJeGZ1VW1RWVEwTHZZdGdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l4ZnVVbVFZUTBMdll0Z00&wv=1&d=286732320",
      plusId: "104073119459044455457",
      reviewAuthor: "Annie Clark",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-fw0-ZAT58MTwfLxV91_UdNJUBc4e-vqe3onZGhw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 days ago",
      reviewRating: 5,
      reviewText:
        "We did the Ultimate OTR tour and Miranda was awesome! This was my second time touring with American Legacy and would recommend them over and over again. As a Cincinnati native I loved learning new fun facts about my city and cannot wait to take my next tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlOFlfNjNBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8Y_63AE%7CCgwIxfuUmQYQ0LvYtgM%7C?hl=en-US",
      rawDate: "09-17-2022 3:23 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlOGF1enl3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlOGF1enl3RRItChZDSUhNMG9nS0VJQ0FnSUNlOGF1ekt3EhNDZ3dJNHVhVW1RWVFzTUg4bXdJGi4KF0NJSE0wb2dLRUlDQWdJQ2U4YXV6cXdFEhNDZ3dJNHVhVW1RWVFzTUg4bXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k0dWFVbVFZUXNNSDhtd0k&wv=1&d=286732320",
      plusId: "106909018173126834572",
      reviewAuthor: "Tom Culhane",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3dRezgb52BOGzkBySqMpGhmgRKrAgyL1_X_WPb=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 days ago",
      reviewRating: 5,
      reviewText:
        "Miranda was full of knowledge and very entertaining. She did a great job making sure everyone was involved during the tour and was able to answer all the questions as well as provide great detailed facts about the history o the area.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlOGF1enl3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8auzywE%7CCgwI4uaUmQYQsMH8mwI%7C?hl=en-US",
      rawDate: "09-17-2022 2:39 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlNGZEd3pRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlNGZEd3pRRRItChZDSUhNMG9nS0VJQ0FnSUNlNGZEd0xREhNDZ3dJX3Q2UG1RWVFrTGItb3dNGi4KF0NJSE0wb2dLRUlDQWdJQ2U0ZkR3clFFEhNDZ3dJX3Q2UG1RWVFrTGItb3dNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lfdDZQbVFZUWtMYi1vd00&wv=1&d=286732320",
      plusId: "107357760460441102362",
      reviewAuthor: "Matt Barnett",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-JrYCJSnrwdbmKO-1AxjEiAxTjhCabURAPbVsqcA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "Miranda was the best! She was very knowledgeable about all of the places we visited and she was a funny and gracious host. She needs a raise pronto, because she gave the tour a life of its own!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlNGZEd3pRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe4fDwzQE%7CCgwI_t6PmQYQkLb-owM%7C?hl=en-US",
      rawDate: "09-16-2022 3:37 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlb1pTSmJREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlb1pTSmJREi4KF0NJSE0wb2dLRUlDQWdJQ2VvWlNKN1FFEhNDZ3dJdWJXT21RWVF1UHpPc3dNGi0KFkNJSE0wb2dLRUlDQWdJQ2VvWlNKSFESE0Nnd0l1YldPbVFZUXVQek9zd00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXViV09tUVlRdVB6T3N3TQ&wv=1&d=286732320",
      plusId: "115667175172720323890",
      reviewAuthor: "Sierra Geier",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu35zprAQjq6WVnBZdeNaCtkT6LDFUxbtKrE0VOD=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "We took our employees out for our monthly team outing, Miranda was our tour guide and we all had a really great time! Interesting knowledgeable tour and great beer! Thank you so much Miranda \ud83d\udc93",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlb1pTSmJREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeoZSJbQ%7CCgwIubWOmQYQuPzOswM%7C?hl=en-US",
      rawDate: "09-15-2022 9:35 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNld2Z1R3BRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNld2Z1R3BRRRItChZDSUhNMG9nS0VJQ0FnSUNld2Z1R1pREhNDZ3dJaTR1T21RWVE2THI4eFFNGi4KF0NJSE0wb2dLRUlDQWdJQ2V3ZnVHNVFFEhNDZ3dJaTR1T21RWVE2THI4eFFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lpNHVPbVFZUTZMcjh4UU0&wv=1&d=286732320",
      plusId: "111523774395367181567",
      reviewAuthor: "Kaitlyn Wittman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu03hIdztHJe2xJGFaKVxtpvR4woKwt37pzyQwQ_=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "We went on this tour for a company outing and it was fantastic!!!! Miranda was so nice, energetic and informative. It was a blast!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNld2Z1R3BRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICewfuGpQE%7CCgwIi4uOmQYQ6Lr8xQM%7C?hl=en-US",
      rawDate: "09-15-2022 8:05 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNld2J2dTJnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNld2J2dTJnRRItChZDSUhNMG9nS0VJQ0FnSUNld2J2dU9nEhNDZ3dJaTRxT21RWVFrS2JiNndJGi4KF0NJSE0wb2dLRUlDQWdJQ2V3YnZ1dWdFEhNDZ3dJaTRxT21RWVFrS2JiNndJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lpNHFPbVFZUWtLYmI2d0k&wv=1&d=286732320",
      plusId: "101718736356448466655",
      reviewAuthor: "Jeremy Wittman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-_b4VNZHI_zy_XQvk85SxYbSp-nnkg1diMScTKiqA=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "Such a great tour, had no idea Cinci had so much amazing history. Miranda was a gem, perfect guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNld2J2dTJnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICewbvu2gE%7CCgwIi4qOmQYQkKbb6wI%7C?hl=en-US",
      rawDate: "09-15-2022 8:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNld2RYVnFnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNld2RYVnFnRRIsChZDSUhNMG9nS0VJQ0FnSUNld2RYVmFnEhJDZ3NJbmZLTm1RWVFpTUN6Q0EaLQoXQ0lITTBvZ0tFSUNBZ0lDZXdkWFY2Z0USEkNnc0luZktObVFZUWlNQ3pDQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJbmZLTm1RWVFpTUN6Q0E&wv=1&d=286732320",
      plusId: "111330008666598659483",
      reviewAuthor: "Maureen Napier",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu09iQ0sZtDnSIO3a71Z-1F2HPhOtSo5AS3lJIiK=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "We did the Ultimate Underground Tour. Our tour guide Miranda, was terrific. Very knowledgeable, entertaining and accommodating. Interesting tour. Even as a Cincinnati native, we learned many things we didn't know. Making plans to take the Haunted Tour. Hopefully Miranda is our guide.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNld2RYVnFnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICewdXVqgE%7CCgsInfKNmQYQiMCzCA%7C?hl=en-US",
      rawDate: "09-15-2022 7:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNld2NyZUV3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNld2NyZUV3Ei4KF0NJSE0wb2dLRUlDQWdJQ2V3Y3Jla3dFEhNDZ3dJdE1tTm1RWVEtUEt5OFFJGi0KFkNJSE0wb2dLRUlDQWdJQ2V3Y3JlVXcSE0Nnd0l0TW1ObVFZUS1QS3k4UUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXRNbU5tUVlRLVBLeThRSQ&wv=1&d=286732320",
      plusId: "112417016323932076649",
      reviewAuthor: "Ellen Davis",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2XlNcfw8vtPTPOhpRWSPPreHVHHRGNK_CAVi6y=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "Queen city ultimate tour was awesome. Informative and SO entertaining with Miranda as our guide.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNld2NyZUV3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICewcreEw%7CCgwItMmNmQYQ-PKy8QI%7C?hl=en-US",
      rawDate: "09-15-2022 5:45 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNld2NxUnVBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNld2NxUnVBRRItChZDSUhNMG9nS0VJQ0FnSUNld2NxUmVBEhNDZ3dJck1tTm1RWVF3S3JPLXdJGi4KF0NJSE0wb2dLRUlDQWdJQ2V3Y3FSLUFFEhNDZ3dJck1tTm1RWVF3S3JPLXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lyTW1ObVFZUXdLck8td0k&wv=1&d=286732320",
      plusId: "100966741622428989738",
      reviewAuthor: "Lois Loch",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu18cZy5Ha-DX7sPhg_bVVrff058ScIt9g78NBgH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "Miranda was a fantastic guide. Entertaining and very knowledgeable. Loved the accent\nShe was easy to underdtand",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNld2NxUnVBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICewcqRuAE%7CCgwIrMmNmQYQwKrO-wI%7C?hl=en-US",
      rawDate: "09-15-2022 5:45 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlZ2FDTXZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlZ2FDTXZBRRItChZDSUhNMG9nS0VJQ0FnSUNlZ2FDTWZBEhNDZ3dJdmNDTW1RWVFzUExxNUFFGi4KF0NJSE0wb2dLRUlDQWdJQ2VnYUNNX0FFEhNDZ3dJdmNDTW1RWVFzUExxNUFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2Y0NNbVFZUXNQTHE1QUU&wv=1&d=286732320",
      plusId: "102954862886890401542",
      reviewAuthor: "J P",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1nCTlGZVJYOH2cTNkL3g8h7nYE0xtqT66FFLPV=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "5 days ago",
      reviewRating: 5,
      reviewText:
        "Our tour guide Zach is very knowledgeable and personable. His tour was great and I would recommend it to anyone living in Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlZ2FDTXZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICegaCMvAE%7CCgwIvcCMmQYQsPLq5AE%7C?hl=en-US",
      rawDate: "09-15-2022 12:53 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlbHR2RjJRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlbHR2RjJRRRItChZDSUhNMG9nS0VJQ0FnSUNlbHR2Rk9REhNDZ3dJdjZ1Qm1RWVFnTGpONkFFGi4KF0NJSE0wb2dLRUlDQWdJQ2VsdHZGdVFFEhNDZ3dJdjZ1Qm1RWVFnTGpONkFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2NnVCbVFZUWdMak42QUU&wv=1&d=286732320",
      plusId: "111194200352727715729",
      reviewAuthor: "Sarah Redington",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1H7GBy0SfChLmEW3OxMN5wY5wDxrwdxaIeMKWr=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "We had an amazing tour with Zach M - his passion for history meant the tour was super engaging, interesting and fun for everyone. We've been recommending this tour and specifically Zach to our friends and family for their next visit to Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlbHR2RjJRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeltvF2QE%7CCgwIv6uBmQYQgLjN6AE%7C?hl=en-US",
      rawDate: "09-13-2022 10:04 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlLXRIRFBREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlLXRIRFBREi4KF0NJSE0wb2dLRUlDQWdJQ2UtdEhEdlFFEhNDZ3dJbFBiN21BWVFpUHpHOVFFGi0KFkNJSE0wb2dLRUlDQWdJQ2UtdEhEZlESE0Nnd0lsUGI3bUFZUWlQekc5UUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWxQYjdtQVlRaVB6RzlRRQ&wv=1&d=286732320",
      plusId: "105757073922215168412",
      reviewAuthor: "Teresa Duncan",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_4CbYytBuERQy_nS_I6XVJdPYGrE6c3FJ-mUB3=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "We did the ultimate underground tour and it was fantastic in part because of the history and architecture but also because our host, Laura, was the best. She knew her stuff and made it fun. our party of 4 enjoyed the tour and will be back to do the other tours.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlLXRIRFBREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe-tHDPQ%7CCgwIlPb7mAYQiPzG9QE%7C?hl=en-US",
      rawDate: "09-12-2022 9:25 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlMnFHWFJREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlMnFHWFJREi4KF0NJSE0wb2dLRUlDQWdJQ2UycUdYeFFFEhNDZ3dJdnNmNW1BWVFzTEdWelFJGi0KFkNJSE0wb2dLRUlDQWdJQ2UycUdYSlESE0Nnd0l2c2Y1bUFZUXNMR1Z6UUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXZzZjVtQVlRc0xHVnpRSQ&wv=1&d=286732320",
      plusId: "103883110792082864768",
      reviewAuthor: "Katelyn Cordell",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8L7dJS-_9AFg4QgMjG2cq058cwYg5x6Nj7KWJHOA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "We did the underground Queen City tour for my friends birthday and it was amazing. Our tour guide Miranda did a lovely job! It was super cool to learn about the places I have previously visited or drove by.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlMnFHWFJREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe2qGXRQ%7CCgwIvsf5mAYQsLGVzQI%7C?hl=en-US",
      rawDate: "09-11-2022 10:39 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlNnIzeWJBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlNnIzeWJBEi4KF0NJSE0wb2dLRUlDQWdJQ2U2cjN5N0FFEhNDZ3dJeHVIM21BWVEwTFBCalFFGi0KFkNJSE0wb2dLRUlDQWdJQ2U2cjN5SEESE0Nnd0l4dUgzbUFZUTBMUEJqUUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXh1SDNtQVlRMExQQmpRRQ&wv=1&d=286732320",
      plusId: "101238068254037844933",
      reviewAuthor: "Ada Honeck",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_vxjEGoYUpE98OpdgwJgexYVBCHwaNx6uixUljPw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText: "Great guide with so much interesting information.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlNnIzeWJBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe6r3ybA%7CCgwIxuH3mAYQ0LPBjQE%7C?hl=en-US",
      rawDate: "09-11-2022 2:29 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNleW91a2xRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNleW91a2xRRRItChZDSUhNMG9nS0VJQ0FnSUNleW91a1ZREhNDZ3dJZ2ZIMW1BWVEtUFBmMUFJGi4KF0NJSE0wb2dLRUlDQWdJQ2V5b3VrMVFFEhNDZ3dJZ2ZIMW1BWVEtUFBmMUFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lnZkgxbUFZUS1QUGYxQUk&wv=1&d=286732320",
      plusId: "101430778282528796742",
      reviewAuthor: "Samantha Schembre",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9z_4sX04_uOekUEWop0YXEbzPKsW9EZhcD9CfAVA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText: "Chris was great!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNleW91a2xRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeyouklQE%7CCgwIgfH1mAYQ-PPf1AI%7C?hl=en-US",
      rawDate: "09-11-2022 5:56 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlaXJlRUZBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlaXJlRUZBEi0KF0NJSE0wb2dLRUlDQWdJQ2VpcmVFbEFFEhJDZ3NJNGMzMG1BWVFvSURSSUEaLAoWQ0lITTBvZ0tFSUNBZ0lDZWlyZUVWQRISQ2dzSTRjMzBtQVlRb0lEUklBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k0YzMwbUFZUW9JRFJJQQ&wv=1&d=286732320",
      plusId: "100446590523018349157",
      reviewAuthor: "E. Burba",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8Wme67X-EymRHWqUTw_KLq0nHOSrZVN8EPQw9O=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "We did the Hidden Caverns tour and it was INCREDIBLE! Miranda was a phenomenal guide! Super funny and engaging. It was a really fun way of learning more about OTR and getting to see some of the tunnels. HIGHLY RECOMMEND.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlaXJlRUZBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeireEFA%7CCgsI4c30mAYQoIDRIA%7C?hl=en-US",
      rawDate: "09-11-2022 12:08 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlaXBtd0pnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlaXBtd0pnEi0KF0NJSE0wb2dLRUlDQWdJQ2VpcG13cGdFEhJDZ3NJakpQMG1BWVF3UHVGUFEaLAoWQ0lITTBvZ0tFSUNBZ0lDZWlwbXdaZxISQ2dzSWpKUDBtQVlRd1B1RlBRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lqSlAwbUFZUXdQdUZQUQ&wv=1&d=286732320",
      plusId: "109507330791472677481",
      reviewAuthor: "Morgan Liley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9cqyIeVBt5oc0q4yDuXyv41yMiQKYnBL4OPA_3Fg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "If you\u2019re in Cincy, you need to use this tour company! Our guide, Zach, was awesome! He was engaging and funny! I would definitely go on their other tours.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlaXBtd0pnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeipmwJg%7CCgsIjJP0mAYQwPuFPQ%7C?hl=en-US",
      rawDate: "09-10-2022 10:02 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlaXZMUlpnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlaXZMUlpnEi4KF0NJSE0wb2dLRUlDQWdJQ2VpdkxSNWdFEhNDZ3dJOXVqem1BWVF5T2VackFFGi0KFkNJSE0wb2dLRUlDQWdJQ2VpdkxSRmcSE0Nnd0k5dWp6bUFZUXlPZVpyQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STl1anptQVlReU9lWnJBRQ&wv=1&d=286732320",
      plusId: "100613358017454981635",
      reviewAuthor: "Brendan Harnish",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1FwxTDbVBtcBmpXdcv06lZAnfz7lfuSdXGQSq0=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText: "Miranda was great!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlaXZMUlpnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeivLRZg%7CCgwI9ujzmAYQyOeZrAE%7C?hl=en-US",
      rawDate: "09-10-2022 8:32 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlaXNqLUFnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlaXNqLUFnEi0KF0NJSE0wb2dLRUlDQWdJQ2Vpc2otZ2dFEhJDZ3NJdk1qem1BWVF1TE8tQkEaLAoWQ0lITTBvZ0tFSUNBZ0lDZWlzai1RZxISQ2dzSXZNanptQVlRdUxPLUJBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l2TWp6bUFZUXVMTy1CQQ&wv=1&d=286732320",
      plusId: "113262244241648630460",
      reviewAuthor: "Tricia Irvin",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9UnsAtQH1RvJcpFUm4HpIUlMhv6SF2MslEFnKkCw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "My husband and I went on The Hidden Caverns Tour and enjoyed ourselves immensely. Miranda was amazing and very entertaining. She was very knowledgeable about the history of the Over-The-Rhine area as well as Cincinnati in general. We will definitely be back in the future to do the haunted tour. The beer at Northern Row Brewery and Distillery wasn't bad either!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlaXNqLUFnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeisj-Ag%7CCgsIvMjzmAYQuLO-BA%7C?hl=en-US",
      rawDate: "09-10-2022 7:23 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlbk9MOXVRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlbk9MOXVRRRItChZDSUhNMG9nS0VJQ0FnSUNlbk9MOWVREhNDZ3dJMHNUbm1BWVFnTGJrcVFFGi4KF0NJSE0wb2dLRUlDQWdJQ2VuT0w5LVFFEhNDZ3dJMHNUbm1BWVFnTGJrcVFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kwc1RubUFZUWdMYmtxUUU&wv=1&d=286732320",
      plusId: "112268022629699741716",
      reviewAuthor: "Evan Hosinski",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0-RltMWSlQM8gMOJCeUrvWIboLMP63mkTl2Eqy=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a week ago",
      reviewRating: 5,
      reviewText:
        "Patrick was a great tour guide on the underground tour. The information shared on the tour was entertaining and taught me a lot about the history of Cincinnati. I would recommend this tour for any locals or out of towners who want to see the history of Cincinnati from a new perspective.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlbk9MOXVRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICenOL9uQE%7CCgwI0sTnmAYQgLbkqQE%7C?hl=en-US",
      rawDate: "09-08-2022 12:38 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNldUt5dlNBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNldUt5dlNBEi4KF0NJSE0wb2dLRUlDQWdJQ2V1S3l2eUFFEhNDZ3dJb0l6Wm1BWVEwSW1mblFFGi0KFkNJSE0wb2dLRUlDQWdJQ2V1S3l2S0ESE0Nnd0lvSXpabUFZUTBJbWZuUUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW9JelptQVlRMEltZm5RRQ&wv=1&d=286732320",
      plusId: "117071503978666770098",
      reviewAuthor: "Julie Meyer",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu94Gg5ZyclbU6I5l-ZCMTotAHBr0_Km3H_FLV1DFg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "It was a great way to experience the city, learn about the historic beginnings, secrets and underground marvels.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNldUt5dlNBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeuKyvSA%7CCgwIoIzZmAYQ0ImfnQE%7C?hl=en-US",
      rawDate: "09-05-2022 6:55 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlaUthR3RRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlaUthR3RRRRItChZDSUhNMG9nS0VJQ0FnSUNlaUthR2RREhNDZ3dJMllqVG1BWVE4T2ZpelFNGi4KF0NJSE0wb2dLRUlDQWdJQ2VpS2FHOVFFEhNDZ3dJMllqVG1BWVE4T2ZpelFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kyWWpUbUFZUThPZml6UU0&wv=1&d=286732320",
      plusId: "102124823843431712740",
      reviewAuthor: "Melodie Lincavage",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8j4DtEVm1KDvqAOGVSt4xl2Ru40Rg8tgarEo8OLw=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "We had an amazing tour with Zach. Very informative and interesting. Whether you are visiting Cincinnati or have lived here your whole life a guarantee you will enjoy this great tour",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlaUthR3RRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICeiKaGtQE%7CCgwI2YjTmAYQ8OfizQM%7C?hl=en-US",
      rawDate: "09-04-2022 3:29 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlOElQTTFBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlOElQTTFBRRIsChZDSUhNMG9nS0VJQ0FnSUNlOElQTU5BEhJDZ3NJeWNMU21BWVFzTmFJYncaLQoXQ0lITTBvZ0tFSUNBZ0lDZThJUE10QUUSEkNnc0l5Y0xTbUFZUXNOYUlidyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJeWNMU21BWVFzTmFJYnc&wv=1&d=286732320",
      plusId: "114588755018864941576",
      reviewAuthor: "Rob Graessle",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9foDu43BAvCddre2RThnW3Qi6OzUTvv-T8Zcal5Q=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "I'm Cincinnati born and raised, and I still learned a ton from this tour. Zack was a great tour guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlOElQTTFBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8IPM1AE%7CCgsIycLSmAYQsNaIbw%7C?hl=en-US",
      rawDate: "09-04-2022 12:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlOEw3elVREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlOEw3elVREi0KF0NJSE0wb2dLRUlDQWdJQ2U4TDd6MFFFEhJDZ3NJaGFUU21BWVF3SWJJQ2caLAoWQ0lITTBvZ0tFSUNBZ0lDZThMN3pNURISQ2dzSWhhVFNtQVlRd0liSUNnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0loYVRTbUFZUXdJYklDZw&wv=1&d=286732320",
      plusId: "112083790039945313732",
      reviewAuthor: "Sarah Wassgren",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1zOsjASIDh5_-gWB1si_3R5s2LrHovq4CyNu4l=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "We took the Ultimate Underground tour, led by Zach. He was a fantastic storyteller and entertainer. Zach made the history of the OTR area we walked really come alive. He did a marvelous job!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlOEw3elVREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8L7zUQ%7CCgsIhaTSmAYQwIbICg%7C?hl=en-US",
      rawDate: "09-04-2022 11:54 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlOE03dXhRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlOE03dXhRRRotChZDSUhNMG9nS0VJQ0FnSUNlOE03dUpREhNDZ3dJbTZIU21BWVFvSUtKbVFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0ltNkhTbUFZUW9JS0ptUUU&wv=1&d=286732320",
      plusId: "113029981421981342168",
      reviewAuthor: "Craig Mowers",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1CrpRkihe77n9QtjaetwoT1y6taXmCD1CeB61Z=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlOE03dXhRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8M7uxQE%7CCgwIm6HSmAYQoIKJmQE%7C?hl=en-US",
      rawDate: "09-04-2022 11:48 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlOE5haFZBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlOE5haFZBEi4KF0NJSE0wb2dLRUlDQWdJQ2U4TmFoMUFFEhNDZ3dJcFozU21BWVE4SXVvdGdJGi0KFkNJSE0wb2dLRUlDQWdJQ2U4TmFoTkESE0Nnd0lwWjNTbUFZUThJdW90Z0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXBaM1NtQVlROEl1b3RnSQ&wv=1&d=286732320",
      plusId: "117499444525566702195",
      reviewAuthor: "Candra Lape",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1xr_CB2OBZMzOVLyOqfYLHI6wcysn5WOuEaLCG=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Angela was a fun and friendly tour guide. She made sure to spend some time talking to each of the members of her group to make sure everyone felt welcomed and included. The tour itself was an easy walk, with fantastic desserts, and lovely drinks. The time spent at Revel Urban Winery was informative, humorous, and you can tell how passionate the owner is as he tells his story and shares his wine.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlOE5haFZBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe8NahVA%7CCgwIpZ3SmAYQ8IuotgI%7C?hl=en-US",
      rawDate: "09-04-2022 11:40 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlMEpHalJnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlMEpHalJnEi0KF0NJSE0wb2dLRUlDQWdJQ2UwSkdqeGdFEhJDZ3NJdy1fUG1BWVEtTGVxSHcaLAoWQ0lITTBvZ0tFSUNBZ0lDZTBKR2pKZxISQ2dzSXctX1BtQVlRLUxlcUh3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l3LV9QbUFZUS1MZXFIdw&wv=1&d=286732320",
      plusId: "116251657399236440807",
      reviewAuthor: "Rick Henry",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu83UnH5iLzBwGk7x6veFCUpDNwO1ZoqWpsunF1DJg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Rad tour. Zach did an amazing job! Super informative, personable, and entertaining.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlMEpHalJnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe0JGjRg%7CCgsIw-_PmAYQ-LeqHw%7C?hl=en-US",
      rawDate: "09-04-2022 12:56 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlMEphcmdBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlMEphcmdBRRItChZDSUhNMG9nS0VJQ0FnSUNlMEphclFBEhNDZ3dJZ2RmUG1BWVFvS0RweVFFGi4KF0NJSE0wb2dLRUlDQWdJQ2UwSmFyd0FFEhNDZ3dJZ2RmUG1BWVFvS0RweVFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lnZGZQbUFZUW9LRHB5UUU&wv=1&d=286732320",
      plusId: "100196477953161126993",
      reviewAuthor: "Sandra Simpson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3fKkF6W6FtLq5mr7SWxLS2wZJq6aPyG2aAygxu=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "We had a great experience with our tour guide Laura ! Lots of info about cinci OTR. The history is rich and the tunnels were fun to explore !",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlMEphcmdBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe0JargAE%7CCgwIgdfPmAYQoKDpyQE%7C?hl=en-US",
      rawDate: "09-04-2022 12:04 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlME9xcTBnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlME9xcTBnRRItChZDSUhNMG9nS0VJQ0FnSUNlME9xcU1nEhNDZ3dJdDhqUG1BWVFnTENzNWdFGi4KF0NJSE0wb2dLRUlDQWdJQ2UwT3Fxc2dFEhNDZ3dJdDhqUG1BWVFnTENzNWdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l0OGpQbUFZUWdMQ3M1Z0U&wv=1&d=286732320",
      plusId: "101545335437930989623",
      reviewAuthor: "Stacia McKeever",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu024frorpebTYGcIe2jo_JRsXiYIf-Zur462cc2=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "We enjoyed the Underground tour with Zach today. It was an interesting historical tour of several places in OTR. Enjoyed the walk and the underground sites. Great for getting a feel for the history of that area in Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlME9xcTBnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe0Oqq0gE%7CCgwIt8jPmAYQgLCs5gE%7C?hl=en-US",
      rawDate: "09-03-2022 11:33 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlMFBDM1F3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlMFBDM1F3Ei4KF0NJSE0wb2dLRUlDQWdJQ2UwUEMzd3dFEhNDZ3dJdXBqUG1BWVF5TTNpM0FFGi0KFkNJSE0wb2dLRUlDQWdJQ2UwUEMzSXcSE0Nnd0l1cGpQbUFZUXlNM2kzQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXVwalBtQVlReU0zaTNBRQ&wv=1&d=286732320",
      plusId: "113277065626182254090",
      reviewAuthor: "Diane Ruff",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-ewCyoXslHsnf7X20eoHJt4SigrbYH7M0IE5oIyB8=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Great job to Zack. We enjoyed his fun facts and stories about the history of Cincinnati. We will definitely do another tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlMFBDM1F3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICe0PC3Qw%7CCgwIupjPmAYQyM3i3AE%7C?hl=en-US",
      rawDate: "09-03-2022 9:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNla1AtSnlBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNla1AtSnlBRRItChZDSUhNMG9nS0VJQ0FnSUNla1AtSktBEhNDZ3dJcG83UG1BWVE4TGFJcXdNGi4KF0NJSE0wb2dLRUlDQWdJQ2VrUC1KcUFFEhNDZ3dJcG83UG1BWVE4TGFJcXdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lwbzdQbUFZUThMYUlxd00&wv=1&d=286732320",
      plusId: "117270815286777523471",
      reviewAuthor: "Dan Lejeune",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0Y_sGfyyBhuFOsHOxCxdgC34LblFD5iOVNbffr=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText: "Great tour and guide. Thanks Kevin!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNla1AtSnlBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekP-JyAE%7CCgwIpo7PmAYQ8LaIqwM%7C?hl=en-US",
      rawDate: "09-03-2022 9:29 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNla00taGxRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNla00taGxRRRItChZDSUhNMG9nS0VJQ0FnSUNla00taFZREhNDZ3dJaTRmUG1BWVF5S1NMd0FNGi4KF0NJSE0wb2dLRUlDQWdJQ2VrTS1oMVFFEhNDZ3dJaTRmUG1BWVF5S1NMd0FNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lpNGZQbUFZUXlLU0x3QU0&wv=1&d=286732320",
      plusId: "106327798207224407917",
      reviewAuthor: "swordoflorn",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9tXpwmAmyU-bxo30oTUgR32_Dw8iTkJBu_MX-x=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zach was knowledgable and funny. I learned a lot of things about Cinci during the tour--some things I wish I didn't! (lol)\n\nI'm definitely coming back here for more tours.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNla00taGxRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekM-hlQE%7CCgwIi4fPmAYQyKSLwAM%7C?hl=en-US",
      rawDate: "09-03-2022 9:13 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNla05mdENBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNla05mdENBEi0KF0NJSE0wb2dLRUlDQWdJQ2VrTmZ0aUFFEhJDZ3NJMDRMUG1BWVF3TkRxTGcaLAoWQ0lITTBvZ0tFSUNBZ0lDZWtOZnRTQRISQ2dzSTA0TFBtQVlRd05EcUxnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kwNExQbUFZUXdORHFMZw&wv=1&d=286732320",
      plusId: "103049547707925853573",
      reviewAuthor: "Allison Long",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-iiaw2_OmmkuExob4WxoEi9-7I6LDtMy9KMYeHTX0=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zach was a great tour guide. Very informative and great storyteller. You will be glad you took this tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNla05mdENBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekNftCA%7CCgsI04LPmAYQwNDqLg%7C?hl=en-US",
      rawDate: "09-03-2022 9:04 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNla0wzeHZnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNla0wzeHZnRRIsChZDSUhNMG9nS0VJQ0FnSUNla0wzeGZnEhJDZ3NJM09uT21BWVFnSS1UZFEaLQoXQ0lITTBvZ0tFSUNBZ0lDZWtMM3hfZ0USEkNnc0kzT25PbUFZUWdJLVRkUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJM09uT21BWVFnSS1UZFE&wv=1&d=286732320",
      plusId: "116494433160442556277",
      reviewAuthor: "derek erickson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3-m3uar1h_D1O8RLHc3xRB7nHuRTjcMak-KtTL=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Thank you Zach! Very entertaining and knowledgeable man. Dad jokes were delightfully tacky!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNla0wzeHZnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekL3xvgE%7CCgsI3OnOmAYQgI-TdQ%7C?hl=en-US",
      rawDate: "09-03-2022 8:11 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNla0xueF93RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNla0xueF93RRItChZDSUhNMG9nS0VJQ0FnSUNla0xXb1J3EhNDZ3dJbnVIT21BWVFpS1dpM2dJGisKFENJSE0wb2dLRUlDQWdJQ2VrTGtKEhNDZ3dJMDluT21BWVE4T19pMmdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0ludUhPbUFZUWlLV2kzZ0k&wv=1&d=286732320",
      plusId: "101707351729198461442",
      reviewAuthor: "Will Jessop",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_dKhKjZqdqY-lFJjXgyCa7-fbyhMX_mdCLrjSc3wU=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText: "Dean was great. Very fun tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNla0xueF93RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekLnx_wE%7CCgwInuHOmAYQiKWi3gI%7C?hl=en-US",
      rawDate: "09-03-2022 7:53 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNla1A2ZzBRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNla1A2ZzBRRRIqChZDSUhNMG9nS0VJQ0FnSUNla1A2Z01REhBDZ29JNnNuT21BWVFxS0lNGisKF0NJSE0wb2dLRUlDQWdJQ2VrUDZnc1FFEhBDZ29JNnNuT21BWVFxS0lNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEENnb0k2c25PbUFZUXFLSU0&wv=1&d=286732320",
      plusId: "117498952461803065652",
      reviewAuthor: "Sarah Bartlett",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2WVjBSInFcZsOGup8dxiBDdTG32YYhR1-eMmaj=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText: "Zach was amazing! Such a good experience!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNla1A2ZzBRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICekP6g0QE%7CCgoI6snOmAYQqKIM%7C?hl=en-US",
      rawDate: "09-03-2022 7:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlZ0lHX0lBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlZ0lHX0lBEi4KF0NJSE0wb2dLRUlDQWdJQ2VnSUdfb0FFEhNDZ3dJNnFYS21BWVF1TFNxeHdNGi0KFkNJSE0wb2dLRUlDQWdJQ2VnSUdfWUESE0Nnd0k2cVhLbUFZUXVMU3F4d00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STZxWEttQVlRdUxTcXh3TQ&wv=1&d=286732320",
      plusId: "108551152911181352329",
      reviewAuthor: "Jennifer Williams",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu18cbu5VU5vsX2EUXCe86m3EfH9qurFSSHBfKJH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Laura was an excellent guide for us today on the Ultimate Queen City Underground Tour! She was very knowledgeable and we had so much fun!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlZ0lHX0lBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICegIG_IA%7CCgwI6qXKmAYQuLSqxwM%7C?hl=en-US",
      rawDate: "09-02-2022 11:34 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNlZ09xYUtBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNlZ09xYUtBEi0KF0NJSE0wb2dLRUlDQWdJQ2VnT3FhcUFFEhJDZ3NJcW9mS21BWVE2SWY3VkEaLAoWQ0lITTBvZ0tFSUNBZ0lDZWdPcWFhQRISQ2dzSXFvZkttQVlRNklmN1ZBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lxb2ZLbUFZUTZJZjdWQQ&wv=1&d=286732320",
      plusId: "113941127645921951555",
      reviewAuthor: "Emanuel Picazo",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8V_96EPBwCEIq1cwUy74C8TxsygW9VbjJSywmKJw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        'I\'ve lived in Cincinnati for 9 years and learned so much more than I thought I would about the Queen City! I would definitely recommend "The Ultimate Queen City Underground Tour" to anyone local or visiting our city. Laura made the experience amazing and was a wealth of knowledge!',
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNlZ09xYUtBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICegOqaKA%7CCgsIqofKmAYQ6If7VA%7C?hl=en-US",
      rawDate: "09-02-2022 10:28 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNlZ05LTDhRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNlZ05LTDhRRRItChZDSUhNMG9nS0VJQ0FnSUNlZ05LTENREhNDZ3dJb1lES21BWVE4UGlMX0FFGi4KF0NJSE0wb2dLRUlDQWdJQ2VnTktMaVFFEhNDZ3dJb1lES21BWVE4UGlMX0FFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lvWURLbUFZUThQaUxfQUU&wv=1&d=286732320",
      plusId: "106797123296318503391",
      reviewAuthor: "Linda Dean",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu19yxO04d9m_PM6l2Zselm_ytVkoUbYItJ4gqLH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "We took the Underground tour and learned a lot about Cincinnati in earlier centuries. Laura was a great guide..the right mix of info, humor with a pinch of snarkiness all rolled into one.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNlZ05LTDhRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICegNKL8QE%7CCgwIoYDKmAYQ8PiL_AE%7C?hl=en-US",
      rawDate: "09-02-2022 10:13 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1N19LMm9RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1N19LMm9RRRItChZDSUhNMG9nS0VJQ0FnSUR1N19LMllREhNDZ3dJb2R2RW1BWVF1TWFXcUFFGi4KF0NJSE0wb2dLRUlDQWdJRHU3X0syNFFFEhNDZ3dJb2R2RW1BWVF1TWFXcUFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lvZHZFbUFZUXVNYVdxQUU&wv=1&d=286732320",
      plusId: "111311162908929497480",
      reviewAuthor: "Callum Locker",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3QPTpg62Q3UZpkQ-lkSyWNgZWjDmx7weUcg5yd=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Amazing tour. I didn\u2019t realise Cincinnati had this whole other side to it. A real must for any visitor or local. Make sure you ask for Zach! His history knowledge is outstanding and he is charming and funny. I hope to return",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1N19LMm9RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu7_K2oQE%7CCgwIodvEmAYQuMaWqAE%7C?hl=en-US",
      rawDate: "09-01-2022 10:09 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1NTYySHZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1NTYySHZBRRItChZDSUhNMG9nS0VJQ0FnSUR1NTYySGZBEhNDZ3dJaWNlOW1BWVEtT0xrOHdFGi4KF0NJSE0wb2dLRUlDQWdJRHU1NjJIX0FFEhNDZ3dJaWNlOW1BWVEtT0xrOHdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lpY2U5bUFZUS1PTGs4d0U&wv=1&d=286732320",
      plusId: "103372332037705088149",
      reviewAuthor: "Ryan Magee",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu06E0bi2fhA3UCohLfah4bIUEI0G-imZTnwcfTi=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 weeks ago",
      reviewRating: 5,
      reviewText:
        "Great tour ask for Zach he was full of knowledge and even a couple of great jokes about the area",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1NTYySHZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu562HvAE%7CCgwIice9mAYQ-OLk8wE%7C?hl=en-US",
      rawDate: "08-31-2022 1:35 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1aDg3SGlBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1aDg3SGlBRRItChZDSUhNMG9nS0VJQ0FnSUR1aDg3SFNBEhNDZ3dJMS11NW1BWVFpTXFRendNGi4KF0NJSE0wb2dLRUlDQWdJRHVoODdIeUFFEhNDZ3dJMS11NW1BWVFpTXFRendNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kxLXU1bUFZUWlNcVF6d00&wv=1&d=286732320",
      plusId: "101621217824113107030",
      reviewAuthor: "Nick Mileusnich",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8nhYcCBSqNfvz3PB-XWruUGwWsc4w1Q-A_Sa52=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zack did a great job with the tour. Knowledgeable about all the history. Great patience with people that showed up late.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1aDg3SGlBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuh87HiAE%7CCgwI1-u5mAYQiMqQzwM%7C?hl=en-US",
      rawDate: "08-30-2022 8:40 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1aDVhc3FBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1aDVhc3FBRRItChZDSUhNMG9nS0VJQ0FnSUR1aDVhc2FBEhNDZ3dJbmVhNW1BWVE2TkhPdkFFGi4KF0NJSE0wb2dLRUlDQWdJRHVoNWFzNkFFEhNDZ3dJbmVhNW1BWVE2TkhPdkFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0luZWE1bUFZUTZOSE92QUU&wv=1&d=286732320",
      plusId: "104459052771557993209",
      reviewAuthor: "Jeff A.",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8oYBDTeaSs6Wp_VE5HDNYy0MEWBEatZjO15qXedw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zack/Zach? Awesome tour guide! He knew his stuff. Very personable, witty, kept it entertaining while learning some history of Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1aDVhc3FBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuh5asqAE%7CCgwInea5mAYQ6NHOvAE%7C?hl=en-US",
      rawDate: "08-30-2022 8:29 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1aDhiY1JREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1aDhiY1JREi0KF0NJSE0wb2dLRUlDQWdJRHVoOGJjeFFFEhJDZ3NJc09PNW1BWVFzTnlCUncaLAoWQ0lITTBvZ0tFSUNBZ0lEdWg4YmNKURISQ2dzSXNPTzVtQVlRc055QlJ3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lzT081bUFZUXNOeUJSdw&wv=1&d=286732320",
      plusId: "103848266434732374384",
      reviewAuthor: "Ryan A.",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1Gjy62wqrGYED3KTXBygnXWaXbcNmRwjATp22-=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zack was great and told the history of the tour well with occasional dad jokes that were well played.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1aDhiY1JREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuh8bcRQ%7CCgsIsOO5mAYQsNyBRw%7C?hl=en-US",
      rawDate: "08-30-2022 8:23 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1Ml92eFpnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1Ml92eFpnEi4KF0NJSE0wb2dLRUlDQWdJRHUyX3Z4NWdFEhNDZ3dJdE8tM21BWVF3UDIxamdFGi0KFkNJSE0wb2dLRUlDQWdJRHUyX3Z4RmcSE0Nnd0l0Ty0zbUFZUXdQMjFqZ0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXRPLTNtQVlRd1AyMWpnRQ&wv=1&d=286732320",
      plusId: "101693556533876675738",
      reviewAuthor: "Julie Hattershire",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9Ge1WUjzh8a22AN2MCwd2gk9F9412jERrfuJS_3Q=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Our group thoroughly enjoyed our tour with Laura. She worked around the rain, and we learned so much about Cincinnati's history! 1 local (me) and 5 out-of-towners were completely entertained and educated. Highly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1Ml92eFpnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu2_vxZg%7CCgwItO-3mAYQwP21jgE%7C?hl=en-US",
      rawDate: "08-30-2022 11:42 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1cy03MC1RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1cy03MC1RRRItChZDSUhNMG9nS0VJQ0FnSUR1cy03MEJREhNDZ3dJdnUtd21BWVEyTEdHdmdJGi4KF0NJSE0wb2dLRUlDQWdJRHVzLTcwaFFFEhNDZ3dJdnUtd21BWVEyTEdHdmdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2dS13bUFZUTJMR0d2Z0k&wv=1&d=286732320",
      plusId: "100807425346865961505",
      reviewAuthor: "Kim Ryan",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-D2OMgCX91ol4JQSfQl9bqO2AC-r5DPo1PjfoNng=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText: "Great tour and great tour guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1cy03MC1RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDus-70-QE%7CCgwIvu-wmAYQ2LGGvgI%7C?hl=en-US",
      rawDate: "08-29-2022 3:51 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1NDRMOEtnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1NDRMOEtnEi4KF0NJSE0wb2dLRUlDQWdJRHU0OEthdlFFEhNDZ3dJejlhdG1BWVF1S1hpdVFJGi0KFkNJSE0wb2dLRUlDQWdJRHU0OEthZlESE0Nnd0l6OWF0bUFZUXVLWGl1UUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXo5YXRtQVlRdUtYaXVRSQ&wv=1&d=286732320",
      plusId: "107545657381501141371",
      reviewAuthor: "Ian Kosko",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3Q5DPjNhSYOp1dAS5i50OiRXuMpIRJzmYOzu0q=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Our group had a great time on the underground tour this weekend. Our tour guide, Zach, contextualized all the fascinating sights and sites with historical background, lively humour, and, as who should say, panache. We greatly hope to enjoy one of the haunted tours with him come Autumn. This man deserves a raise (so he can help the children).",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1NDRMOEtnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu44L8Kg%7CCgwIz9atmAYQuKXiuQI%7C?hl=en-US",
      rawDate: "08-28-2022 1:19 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1by1lcmZnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1by1lcmZnEi0KF0NJSE0wb2dLRUlDQWdJRHVvLWVyX2dFEhJDZ3NJMGJDdG1BWVE0Ti1lVncaLAoWQ0lITTBvZ0tFSUNBZ0lEdW8tZXJBURISQ2dzSTBiQ3RtQVlRNE4tZVZ3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kwYkN0bUFZUTROLWVWdw&wv=1&d=286732320",
      plusId: "102028919959145539534",
      reviewAuthor: "James Bock",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1x1yhGW84-PQtZtV3Iyrm4EPipGvVZXcWiypWr=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "My wife and I did the caverns tour through Northern Row Distillery & Brewery. We were expecting a tour focused on simply the brewery, but what we got instead was a fantastic look at culture in Cincinnati's Over the Rhine district, and it was incredible. Special shoutout to Miranda, who was an absolute pleasure to have as a guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1by1lcmZnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuo-erfg%7CCgsI0bCtmAYQ4N-eVw%7C?hl=en-US",
      rawDate: "08-28-2022 11:58 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1Zy1tTkNBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1Zy1tTkNBEi4KF0NJSE0wb2dLRUlDQWdJRHVnLW1OaUFFEhNDZ3dJdGQycW1BWVFvTHZ6bGdJGi0KFkNJSE0wb2dLRUlDQWdJRHVnLW1OU0ESE0Nnd0l0ZDJxbUFZUW9MdnpsZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXRkMnFtQVlRb0x2emxnSQ&wv=1&d=286732320",
      plusId: "108556893389574388564",
      reviewAuthor: "Jeanne Desmond",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8-E2k37v0ClNzkrYahf-axWkXMN6ps-htuSa0a=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zack was our guide and he was very knowledgeable and super fun. I would highly recommend this tour. It was a treat! Zack did a fantastic job !",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1Zy1tTkNBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDug-mNCA%7CCgwItd2qmAYQoLvzlgI%7C?hl=en-US",
      rawDate: "08-27-2022 11:54 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1ZzhMcGFBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1ZzhMcGFBEi4KF0NJSE0wb2dLRUlDQWdJRHVnOExwNkFFEhNDZ3dJM0tpcW1BWVEtTzNhZ3dJGi0KFkNJSE0wb2dLRUlDQWdJRHVnOExwR0ESE0Nnd0kzS2lxbUFZUS1PM2Fnd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STNLaXFtQVlRLU8zYWd3SQ&wv=1&d=286732320",
      plusId: "117870833998796552645",
      reviewAuthor: "Stephanie Bratton",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu27g-s8wKx4bFPFpMsswEUKZAsMTXlVpHUMCxzl=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Amazing experience that everyone from Cincinnati area should experience !! George was our tour guide. He was very informative and provided times for questions along the way. The tour was about 2 hours walking about a 3-4 block range. So much history in such a small area!! 10/10 experience all around with the entire company in general. Can\u2019t wait to do the haunted tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1ZzhMcGFBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDug8LpaA%7CCgwI3KiqmAYQ-O3agwI%7C?hl=en-US",
      rawDate: "08-27-2022 10:02 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1X2RfZ1NBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1X2RfZ1NBEi4KF0NJSE0wb2dLRUlDQWdJRHVfZF9neUFFEhNDZ3dJbW9HcW1BWVE4SzN1aWdNGi0KFkNJSE0wb2dLRUlDQWdJRHVfZF9nS0ESE0Nnd0ltb0dxbUFZUThLM3VpZ00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW1vR3FtQVlROEszdWlnTQ&wv=1&d=286732320",
      plusId: "109963841051437049726",
      reviewAuthor: "Catelynn Norris",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2MhGTCKPRins8j6eEMVL7ZtcIjDXYgY8LUsclM=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Honestly, 5/5 - 100%, would recommend Queen City Underground tour to any of my friends and family. I'm not sure if this is possible, but if you can GET ZACH for your tourguide! He is informative and really knows how to keep a tourist group entertained. There is a bunch in Cincinnati to learn and my husband and I thought he did an amazing job explaining. Zach had lots of interesting facts about our Queen City and we had such a great time. For anybody else going, they say it on their website, but just incase you didn't see their posting *Make sure you wear gym shoes or sturdy shoes with a good grip*. I genuinely think anyone interested should definitely give their tour a try, especially if you're interested in history. Hope all future tourist have a great time!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1X2RfZ1NBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu_d_gSA%7CCgwImoGqmAYQ8K3uigM%7C?hl=en-US",
      rawDate: "08-27-2022 8:37 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1X1pmMEh3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1X1pmMEh3Ei4KF0NJSE0wb2dLRUlDQWdJRHVfWmYwbndFEhNDZ3dJb3ZpcG1BWVE2TkRTbndFGi0KFkNJSE0wb2dLRUlDQWdJRHVfWmYwWHcSE0Nnd0lvdmlwbUFZUTZORFNud0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW92aXBtQVlRNk5EU253RQ&wv=1&d=286732320",
      plusId: "107661373266815607484",
      reviewAuthor: "Lori Laurendeau",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2Dj2OHQVtukVlNOAAfj-AJAzNvxwAR5whVesl6=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "Zach was an amazing tour guide. He was full of information and made it a fun experience. I really enjoyed the tour. Thank you Zack",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1X1pmMEh3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu_Zf0Hw%7CCgwIovipmAYQ6NDSnwE%7C?hl=en-US",
      rawDate: "08-27-2022 8:18 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1MllidVRBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1MllidVRBEi4KF0NJSE0wb2dLRUlDQWdJRHUyWWJ1ekFFEhNDZ3dJZ05DWW1BWVEwSmVwcGdFGi0KFkNJSE0wb2dLRUlDQWdJRHUyWWJ1TEESE0Nnd0lnTkNZbUFZUTBKZXBwZ0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWdOQ1ltQVlRMEplcHBnRQ&wv=1&d=286732320",
      plusId: "111885938039605284779",
      reviewAuthor: "Karen Mulligan",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_gaJS707kUgQrmPpHAhP0lsySso1HIn4Prl6d5=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 weeks ago",
      reviewRating: 5,
      reviewText:
        "We visit family from out of town at least once a year and we took the ultimate underground tour as something new to do together. Everyone was extremely glad that we did. Much more than we were expecting or could have hoped for. Definitely the highlight of our trip and we can\u2019t stop talking about it.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1MllidVRBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu2YbuTA%7CCgwIgNCYmAYQ0JeppgE%7C?hl=en-US",
      rawDate: "08-24-2022 1:30 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1cWUzVzl3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1cWUzVzl3RRIsChZDSUhNMG9nS0VJQ0FnSUR1cWUzV0R3EhJDZ3NJcDRXV21BWVEyTXJwUEEaLQoXQ0lITTBvZ0tFSUNBZ0lEdXFlMnV2QUUSEkNnc0lwNFdXbUFZUTJNcnBQQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJcDRXV21BWVEyTXJwUEE&wv=1&d=286732320",
      plusId: "102811798583647000742",
      reviewAuthor: "Jennifer Reed",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_oe1-jnsPcfftIozF_peWysZNdCbWp2HCPHk0_=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "Our tour guide, Matt, was super knowledgeable and engaging! I've toured OTR before but there's so much history in the area, no two tours are alike. Thanks Matt for a great Sunday morning!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1cWUzVzl3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuqe3W9wE%7CCgsIp4WWmAYQ2MrpPA%7C?hl=en-US",
      rawDate: "08-24-2022 1:45 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1aWVfeUR3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1aWVfeUR3Ei4KF0NJSE0wb2dLRUlDQWdJRHVpZV95andFEhNDZ3dJMXBlVW1BWVF1SkhNaUFNGi0KFkNJSE0wb2dLRUlDQWdJRHVpZV95VHcSE0Nnd0kxcGVVbUFZUXVKSE1pQU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STFwZVVtQVlRdUpITWlBTQ&wv=1&d=286732320",
      plusId: "101362028405431062226",
      reviewAuthor: "Steve Bartholomew",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0GukVUZJCkd3zbcrBpHgAxHDJmYE-jK8RWIa1l=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "Took the tour intending to simply kill a couple hours. But it ended up being a highlight of our trip. Laura is an incredible tour guide/historian, knowledgeable and engaging. Definitely recommending it to friends visiting Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1aWVfeUR3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuie_yDw%7CCgwI1peUmAYQuJHMiAM%7C?hl=en-US",
      rawDate: "08-23-2022 5:18 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1a1ktZjh3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1a1ktZjh3RRotChZDSUhNMG9nS0VJQ0FnSUR1a1ktZkN3EhNDZ3dJOV9lUW1BWVFnUEstckFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k5X2VRbUFZUWdQSy1yQUk&wv=1&d=286732320",
      plusId: "107011652510888568492",
      reviewAuthor: "Jared Auker",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3ng5UioqSmKZWSEWZK69oUk2aGLlo7JMVWfjR_=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1a1ktZjh3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDukY-f8wE%7CCgwI9_eQmAYQgPK-rAI%7C?hl=en-US",
      rawDate: "08-23-2022 2:31 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1b2FPYnFBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1b2FPYnFBRRIsChZDSUhNMG9nS0VJQ0FnSUR1b2FPYmFBEhJDZ3NJXy02T21BWVFvUGFRYmcaLQoXQ0lITTBvZ0tFSUNBZ0lEdW9hT2I2QUUSEkNnc0lfLTZPbUFZUW9QYVFiZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJXy02T21BWVFvUGFRYmc&wv=1&d=286732320",
      plusId: "113097187333708569139",
      reviewAuthor: "John Clark",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_TrdGe5RdyZf3Zda5XuOSVTIj82Znv9C8jg1Zu=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "This was a wonderful tour, led by a friendly and knowledgeable guide. Kevin was the best. I would recommend this to anyone who would like to know a little more about the history of Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1b2FPYnFBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuoaObqAE%7CCgsI_-6OmAYQoPaQbg%7C?hl=en-US",
      rawDate: "08-22-2022 5:06 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1b1pxQmpnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1b1pxQmpnRRItChZDSUhNMG9nS0VJQ0FnSUR1b1pxQlRnEhNDZ3dJcXNpT21BWVFvTmUxN1FJGi4KF0NJSE0wb2dLRUlDQWdJRHVvWnFCemdFEhNDZ3dJcXNpT21BWVFvTmUxN1FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lxc2lPbUFZUW9OZTE3UUk&wv=1&d=286732320",
      plusId: "115770760389125182766",
      reviewAuthor: "Amy Linneman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2hG5ujp7lRHGuaRppv6Xaf3ktJx7Iye23XObZ3=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "Our family went on the Queen City Underground Tour and it was awesome! Miranda, our tour guide, was entertaining and had a wealth of interesting information/history. Highly recommend!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1b1pxQmpnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuoZqBjgE%7CCgwIqsiOmAYQoNe17QI%7C?hl=en-US",
      rawDate: "08-22-2022 3:43 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1b2Fxd0h3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1b2Fxd0h3Ei4KF0NJSE0wb2dLRUlDQWdJRHVvYXF3bndFEhNDZ3dJME1hT21BWVF1SzdmdVFJGi0KFkNJSE0wb2dLRUlDQWdJRHVvYXF3WHcSE0Nnd0kwTWFPbUFZUXVLN2Z1UUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STBNYU9tQVlRdUs3ZnVRSQ&wv=1&d=286732320",
      plusId: "108597695116618284799",
      reviewAuthor: "paige",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2Mn2thXDtwSv0Grh2tri_7uQKGk8k8TUSstN-i=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "The Ultimate Queen City, Underground Tour was amazing! Our tour guide was Dean and I highly recommend taking this tour with him. You\u2019ll learn a lot about Cincinnati, over the Rhine. The underground portions were great to be able to see.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1b2Fxd0h3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuoaqwHw%7CCgwI0MaOmAYQuK7fuQI%7C?hl=en-US",
      rawDate: "08-22-2022 3:40 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1dnRUTzhBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1dnRUTzhBRRItChZDSUhNMG9nS0VJQ0FnSUR1dnRUT0NBEhNDZ3dJMzdxS21BWVFzTVh5a0FJGi4KF0NJSE0wb2dLRUlDQWdJRHV2dFRPaUFFEhNDZ3dJMzdxS21BWVFzTVh5a0FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzN3FLbUFZUXNNWHlrQUk&wv=1&d=286732320",
      plusId: "100411195426485542943",
      reviewAuthor: "Haley Hlavaty",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_H_JV9CWUQQzFJW2pVjByuiQnipRxlr2Wdk1TH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText:
        "Dean was a great tour guide. We learned so much about the city and underground breweries. I'm not the biggest history buff but this was worth it. Lots of interesting stories and history of the Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1dnRUTzhBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuvtTO8AE%7CCgwI37qKmAYQsMXykAI%7C?hl=en-US",
      rawDate: "08-21-2022 9:02 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1M29fR2ZBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1M29fR2ZBEi4KF0NJSE0wb2dLRUlDQWdJRHUzb19HX0FFEhNDZ3dJLXA2S21BWVF3TnZXNVFJGi0KFkNJSE0wb2dLRUlDQWdJRHUzb19HQWcSE0Nnd0ktcDZLbUFZUXdOdlc1UUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SS1wNkttQVlRd052VzVRSQ&wv=1&d=286732320",
      plusId: "113998415321717955746",
      reviewAuthor: "Maggie Hobbs",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_XwVDG8_TGXKrjWU8cdSRlUiYvFCZ21CrNVOAnbw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "4 weeks ago",
      reviewRating: 5,
      reviewText: "Miranda was amazing! Funny, knowledgeable and engaging.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1M29fR2ZBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu3o_GfA%7CCgwI-p6KmAYQwNvW5QI%7C?hl=en-US",
      rawDate: "08-21-2022 8:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1anZfVXZnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1anZfVXZnRRItChZDSUhNMG9nS0VJQ0FnSUR1anZfVWZnEhNDZ3dJaGFpRm1BWVFzTUdFeGdFGi4KF0NJSE0wb2dLRUlDQWdJRHVqdl9VX2dFEhNDZ3dJaGFpRm1BWVFzTUdFeGdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lucjJHbUFZUTJORENjZw&wv=1&d=286732320",
      plusId: "108715473135850663244",
      reviewAuthor: "Mindy Seibert",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu15TQD6CnrL6G1Ols2uNaBaaQiA8RS0ChTBZUeG=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Wonderful tour of wine & desserts. It was a great birthday gift !",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1anZfVXZnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDujv_UvgE%7CCgsInr2GmAYQ2NDCcg%7C?hl=en-US",
      rawDate: "08-21-2022 2:55 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1enRMN0pBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1enRMN0pBEi4KF0NJSE0wb2dLRUlDQWdJRHV6dEw3cEFFEhNDZ3dJbHRXRm1BWVE4TUdSaXdNGi0KFkNJSE0wb2dLRUlDQWdJRHV6dEw3WkESE0Nnd0lsdFdGbUFZUThNR1Jpd00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWx0V0ZtQVlROE1HUml3TQ&wv=1&d=286732320",
      plusId: "104011804445278057513",
      reviewAuthor: "Christina Wagner",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu12ODOTxFV2Opo7ty6L-XUQkFWPPIqOYKtaOtUG=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I surprised my husband for his birthday with the Hidden Brewery Caverns your in Cincinnati, OH. Miranda was our tour guide and she was incredible! She made the tour fun, entertaining, interesting, and we loved the history she shared. Her sense of humor made the whole thing even more enjoyable! The beer tasting at the end was great! Looking forward to taking another tour at some point.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1enRMN0pBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuztL7JA%7CCgwIltWFmAYQ8MGRiwM%7C?hl=en-US",
      rawDate: "08-20-2022 11:13 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1enFLVU53EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1enFLVU53Ei4KF0NJSE0wb2dLRUlDQWdJRHV6cUtVdHdFEhNDZ3dJd05DRm1BWVEyTEhTLUFJGi0KFkNJSE0wb2dLRUlDQWdJRHV6cUtVZHcSE0Nnd0l3TkNGbUFZUTJMSFMtQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXdOQ0ZtQVlRMkxIUy1BSQ&wv=1&d=286732320",
      plusId: "106038625787536775211",
      reviewAuthor: "Eric Wagner",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9GVGQtH0qIguBna6vEKqlgs_Zg55EFB_UmJDxJkQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "What an absolutely incredible day! My wife surprised me for my birthday and booked the Hidden Brewery Cavern Tour. Miranda was absolutely incredible. Witty, knowledgeable, and a load of fun. Highly recommend this tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1enFLVU53EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuzqKUNw%7CCgwIwNCFmAYQ2LHS-AI%7C?hl=en-US",
      rawDate: "08-20-2022 11:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1bG9YaFp3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1bG9YaFp3Ei4KF0NJSE0wb2dLRUlDQWdJRHVsb1hoNXdFEhNDZ3dJbktLQm1BWVEySXVPaFFNGi0KFkNJSE0wb2dLRUlDQWdJRHVsb1hoRncSE0Nnd0luS0tCbUFZUTJJdU9oUU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW5LS0JtQVlRMkl1T2hRTQ&wv=1&d=286732320",
      plusId: "100568983534109692976",
      reviewAuthor: "nick schultz",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1xQfPTOQhKKuUps05U9VZe65_00WbMM3mHPMaB=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Patrick was a fantastic tour guide and it was so much fun learning about the city! He gave us great history and tips for places to check out!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1bG9YaFp3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuloXhZw%7CCgwInKKBmAYQ2IuOhQM%7C?hl=en-US",
      rawDate: "08-20-2022 3:12 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1eXBienVBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1eXBienVBRRIsChZDSUhNMG9nS0VJQ0FnSUR1eXBiemVBEhJDZ3NJdE1uMmx3WVF5TXJ2V3caLQoXQ0lITTBvZ0tFSUNBZ0lEdXlwYnotQUUSEkNnc0l0TW4ybHdZUXlNcnZXdyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJdE1uMmx3WVF5TXJ2V3c&wv=1&d=286732320",
      plusId: "109664645452149669588",
      reviewAuthor: "Jo Gazo",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3S6tEdI74lV4Wb4vRug0R-p-9AW3eLP3sCYpS0=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 4,
      reviewText:
        "Took the tour on 8/16 with my husband and throughly enjoyed it. The only concern we had was the sketchy neighborhood where the crypt at St. Francis church is located as well as the area where the original brewing tunnels were located. The area had some questionable characters who were eying up the group. We were not intimidated, however if I was traveling with out my hubby I would be concerned. Good thing the sketchy characters kept their distance as my hubby is a big guy whose in law enforcement. Otherwise, an excellent tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1eXBienVBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuypbzuAE%7CCgsItMn2lwYQyMrvWw%7C?hl=en-US",
      rawDate: "08-18-2022 2:32 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1aXFuOVl3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1aXFuOVl3Ei0KF0NJSE0wb2dLRUlDQWdJRHVpcW45NHdFEhJDZ3NJMUwzMWx3WVEyT3VGTUEaLAoWQ0lITTBvZ0tFSUNBZ0lEdWlxbjlFdxISQ2dzSTFMMzFsd1lRMk91Rk1BIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kxTDMxbHdZUTJPdUZNQQ&wv=1&d=286732320",
      plusId: "114104540399897756018",
      reviewAuthor: "Anthony Leksan",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8zWRzKef3XFq_-przLDc9PIoMebHon1DRPxKasQg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The Ultimate Underground Tour was very informative and entertaining. I learned a lot about the history of Cincinnati's Over-the-Rhine neighborhood (quite a lot of it has to do with beer, believe it or not).",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1aXFuOVl3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuiqn9Yw%7CCgsI1L31lwYQ2OuFMA%7C?hl=en-US",
      rawDate: "08-17-2022 9:34 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1OG9XM2J3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1OG9XM2J3Ei0KF0NJSE0wb2dLRUlDQWdJRHU4b1czN3dFEhJDZ3NJMmRYMGx3WVFxTjI0VEEaLAoWQ0lITTBvZ0tFSUNBZ0lEdThvVzNIdxISQ2dzSTJkWDBsd1lRcU4yNFRBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kyZFgwbHdZUXFOMjRUQQ&wv=1&d=286732320",
      plusId: "100197907963487429968",
      reviewAuthor: "Amanda M",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8BTkIgpQiBLPKHnrLpYn6keW3ORAkVnZSfnwobXA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The Queen City Underground Tour was really informative and interesting. My whole family, including teenagers, had a good time.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1OG9XM2J3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu8oW3bw%7CCgsI2dX0lwYQqN24TA%7C?hl=en-US",
      rawDate: "08-17-2022 5:52 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1NHFuTmZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1NHFuTmZREi4KF0NJSE0wb2dLRUlDQWdJRHU0cW5OX1FFEhNDZ3dJOHZMd2x3WVE4T3l5bWdJGi0KFkNJSE0wb2dLRUlDQWdJRHU0cW5OQXcSE0Nnd0k4dkx3bHdZUThPeXltZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STh2THdsd1lROE95eW1nSQ&wv=1&d=286732320",
      plusId: "102001730387247722077",
      reviewAuthor: "James Kern",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu24i8KkycNaK8Chm2GqMHjeiJxHU05m5wP1XskA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Great tour full of fun details, history, and surprises. Zach was so knowledgeable and the best tour guide ever!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1NHFuTmZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu4qnNfQ%7CCgwI8vLwlwYQ8OyymgI%7C?hl=en-US",
      rawDate: "08-17-2022 12:42 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1b3FtM2RBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1b3FtM2RBEi4KF0NJSE0wb2dLRUlDQWdJRHVvcW0zOUFFEhNDZ3dJOHVUdmx3WVFpSlRXbHdJGi0KFkNJSE0wb2dLRUlDQWdJRHVvcW0zREESE0Nnd0k4dVR2bHdZUWlKVFdsd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STh1VHZsd1lRaUpUV2x3SQ&wv=1&d=286732320",
      plusId: "104620987757448050744",
      reviewAuthor: "Amina Haq",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-NsZDR6xfrgs94ls3pFlB3gvnom9x0JyD6CmNhKrA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Went on the underground Cincinnati tour with Zach, and it was a highly enjoyable experience. He told us about unknown facts of Cincinnati using anecdotes, myths, and actual history. It was truly memorable experience and it was fun because of Zach's tour guide skills. He made all of us feel welcome and part of the journey.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1b3FtM2RBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuoqm3dA%7CCgwI8uTvlwYQiJTWlwI%7C?hl=en-US",
      rawDate: "08-16-2022 7:39 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1M09PZV9RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1M09PZV9RRRItChZDSUhNMG9nS0VJQ0FnSUR1M09PZUF3EhNDZ3dJbWUzcmx3WVFvS2JWendFGi4KF0NJSE0wb2dLRUlDQWdJRHUzT09lZ3dFEhNDZ3dJbWUzcmx3WVFvS2JWendFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0ltZTNybHdZUW9LYlZ6d0U&wv=1&d=286732320",
      plusId: "110896783629607830147",
      reviewAuthor: "Greg Anchondo",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-Yq91ICc9PQMAL2lMOehg8s9i-jZHxc_CPLY2iJA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "This was a great experience to see and learn a new city. Our tour guide was Miranda, request her! She was fun and new her facts and was passionate about what she does. Her Aussie accent made it fun to listen to the tour. We will be back to join her on the haunted tour!! Fun hanging with her afterwards to enjoy a beer",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1M09PZV9RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu3OOe_QE%7CCgwIme3rlwYQoKbVzwE%7C?hl=en-US",
      rawDate: "08-16-2022 1:44 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1bk0zOHp3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1bk0zOHp3RRItChZDSUhNMG9nS0VJQ0FnSUR1bk0zOEx3EhNDZ3dJcDh2cWx3WVFzTG1CdHdJGi4KF0NJSE0wb2dLRUlDQWdJRHVuTTM4cndFEhNDZ3dJcDh2cWx3WVFzTG1CdHdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lwOHZxbHdZUXNMbUJ0d0k&wv=1&d=286732320",
      plusId: "109269045675188095638",
      reviewAuthor: "Jamie Williams",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3SvBQ8VBh4jJ_J-kRUZi7qVxlOTbtaj2KopKEX=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "very knowledgeable with great stories. Highly recommend. Especially if you can get Laura as your guide",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1bk0zOHp3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDunM38zwE%7CCgwIp8vqlwYQsLmBtwI%7C?hl=en-US",
      rawDate: "08-15-2022 7:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1akpEQVV3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1akpEQVV3Ei4KF0NJSE0wb2dLRUlDQWdJRHVqSkRBMHdFEhNDZ3dJcUtqbWx3WVF5Tk95bUFFGi0KFkNJSE0wb2dLRUlDQWdJRHVqSkRBTXcSE0Nnd0lxS2ptbHdZUXlOT3ltQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXFLam1sd1lReU5PeW1BRQ&wv=1&d=286732320",
      plusId: "108406642102667621796",
      reviewAuthor: "Susan Seal",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0uvB1wgZz_P1o5TNlvAC5vnsvtC6H8BfLaNOPV=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Great tour! Awesome guide. Very informative.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1akpEQVV3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDujJDAUw%7CCgwIqKjmlwYQyNOymAE%7C?hl=en-US",
      rawDate: "08-15-2022 12:32 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1OUx1SEdREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1OUx1SEdREi4KF0NJSE0wb2dLRUlDQWdJRHU5THVIbVFFEhNDZ3dJdDRybWx3WVE0TnFsNndJGi0KFkNJSE0wb2dLRUlDQWdJRHU5THVIV1ESE0Nnd0l0NHJtbHdZUTROcWw2d0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXQ0cm1sd1lRNE5xbDZ3SQ&wv=1&d=286732320",
      plusId: "115330601304399458898",
      reviewAuthor: "Marcy Gloeckler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8RZPAz-Dm5Qn2Bewr87NLAn75y3EgTB3op-8q8mmw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "My family and friends who were visiting took a tour today with Matt and absolutely loved it! We learned so much about the city we live in and had the best time. Thank you so much!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1OUx1SEdREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu9LuHGQ%7CCgwIt4rmlwYQ4Nql6wI%7C?hl=en-US",
      rawDate: "08-14-2022 11:28 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1MU92MmdBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1MU92MmdBRRItChZDSUhNMG9nS0VJQ0FnSUR1MU92MlFBEhNDZ3dJeVpma2x3WVF5SU9pdkFNGi4KF0NJSE0wb2dLRUlDQWdJRHUxT3Yyd0FFEhNDZ3dJeVpma2x3WVF5SU9pdkFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l5WmZrbHdZUXlJT2l2QU0&wv=1&d=286732320",
      plusId: "106924521900320222019",
      reviewAuthor: "Beevil Aginn",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2qMlfIcCz4E4EtXcSz4mBuWJXcPftlFQw_hYCG=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We took the tour of the lagering tunnels with Laura. The tour was great, and Laura is a fabulous guide! She's funny, super-knowledgeable, personable, patient. If you have a choice, definitely see if there's a tour with Laura as the guide!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1MU92MmdBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu1Ov2gAE%7CCgwIyZfklwYQyIOivAM%7C?hl=en-US",
      rawDate: "08-14-2022 2:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1MU9tSU53EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1MU9tSU53Ei4KF0NJSE0wb2dLRUlDQWdJRHUxT21JdHdFEhNDZ3dJaHY3amx3WVEwUERvandNGi0KFkNJSE0wb2dLRUlDQWdJRHUxT21JZHcSE0Nnd0lodjdqbHdZUTBQRG9qd00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWh2N2psd1lRMFBEb2p3TQ&wv=1&d=286732320",
      plusId: "103755788835194804246",
      reviewAuthor: "Sue Woughter",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_S7tnMNOKnUaNIp6PDr_q7A1hFdZsBn968LWDH3rI=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Our group of four went on the Queen City Underground tour. It was great and had us chatting about it over dinner at one of the destinations from the tour. Our guide George was excellent! He was loud and clear and very interesting to listen to. He welcomed questions and was really knowledgeable. Go on the tour. You'll be glad you did.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1MU9tSU53EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu1OmINw%7CCgwIhv7jlwYQ0PDojwM%7C?hl=en-US",
      rawDate: "08-14-2022 1:56 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1NUk3YlVnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1NUk3YlVnEi4KF0NJSE0wb2dLRUlDQWdJRHU1STdiMGdFEhNDZ3dJOE9EaGx3WVFnTEw1MXdJGi0KFkNJSE0wb2dLRUlDQWdJRHU1STdiTWcSE0Nnd0k4T0RobHdZUWdMTDUxd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SThPRGhsd1lRZ0xMNTF3SQ&wv=1&d=286732320",
      plusId: "105517564702888155717",
      reviewAuthor: "Brittany Brausch",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9hFKxSm1lt_k-jwT-oK6oARZsYl8ouxEGt0xJ7zg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I booked the OTR ghost tour for my sisters bachelorette and we had a blast! Our tour guide was Miranda and she is absolutely fantastic! I would highly recommended you book with her if you want to have a great time and learn some amazing Cincinnati history!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1NUk3YlVnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu5I7bUg%7CCgwI8ODhlwYQgLL51wI%7C?hl=en-US",
      rawDate: "08-14-2022 3:48 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1NUxUQjBnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1NUxUQjBnRRItChZDSUhNMG9nS0VJQ0FnSUR1NUxUQk1nEhNDZ3dJeEszaGx3WVEyTUdmcGdJGi4KF0NJSE0wb2dLRUlDQWdJRHU1TFRCc2dFEhNDZ3dJeEszaGx3WVEyTUdmcGdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l4SzNobHdZUTJNR2ZwZ0k&wv=1&d=286732320",
      plusId: "106586582666091100129",
      reviewAuthor: "Abigail Lemme",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2GujVDOoeFtWBA9tbPu9DVgkSqDW9S6xabhhK1=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Laura was a great guide for our Ultimate Queen City Underground tour. We had a lot of fun and discovered places to checkout following the tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1NUxUQjBnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu5LTB0gE%7CCgwIxK3hlwYQ2MGfpgI%7C?hl=en-US",
      rawDate: "08-14-2022 1:58 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1cE5la19RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1cE5la19RRRItChZDSUhNMG9nS0VJQ0FnSUR1cE5la0F3EhNDZ3dJd3Z2Z2x3WVE0TkhQMndNGi4KF0NJSE0wb2dLRUlDQWdJRHVwTmVrZ3dFEhNDZ3dJd3Z2Z2x3WVE0TkhQMndNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l3dnZnbHdZUTROSFAyd00&wv=1&d=286732320",
      plusId: "102072641668101442165",
      reviewAuthor: "Brooklynn Kerns",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-dJyawkAKSx6wkuxfpH8qdByUB87x1ieQDB7f_gg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda was absolutely amazing!!! She knew so much about Cincinnati and genuinely loved telling us about it. It got me (a giant chicken) to enjoy a ghost tour!!! She felt like a friend by the end and I\u2019m kind of sad not to hang out with her! The tour was so informative and entertaining. We love Miranda!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1cE5la19RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDupNek_QE%7CCgwIwvvglwYQ4NHP2wM%7C?hl=en-US",
      rawDate: "08-14-2022 12:11 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1cEs2eGJnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1cEs2eGJnEi0KF0NJSE0wb2dLRUlDQWdJRHVwSzZ4N2dFEhJDZ3NJdGFfZ2x3WVF5S253Q3caLAoWQ0lITTBvZ0tFSUNBZ0lEdXBLNnhIZxISQ2dzSXRhX2dsd1lReUtud0N3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l0YV9nbHdZUXlLbndDdw&wv=1&d=286732320",
      plusId: "101762831457128761784",
      reviewAuthor: "Aarushi Vashisht",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3Qr9Q6lFLQi-YOEj9KCP2JiNiGYKIydec3qmyf=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Peggy was a great tour guide and so informative! I loved all the cool knowledge she shared about the city! Definitely would recommend taking a tour with them while in Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1cEs2eGJnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDupK6xbg%7CCgsIta_glwYQyKnwCw%7C?hl=en-US",
      rawDate: "08-13-2022 9:29 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNDdVBXUVhnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNDdVBXUVhnEi0KF0NJSE0wb2dLRUlDQWdJQ0N1UFdRM2dFEhJDZ3NJMlBUZmx3WVEwSU9NSncaLQoWQ0lITTBvZ0tFSUNBZ0lDQ3VQV1FQZxITQ2d3SXlxVEgtUVVReUx2aGlBSSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJMlBUZmx3WVEwSU9NSnc&wv=1&d=286732320",
      plusId: "104051604406369344995",
      reviewAuthor: "Marley McHugh",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1KTi-zzeLlFdfKfoa2cmANMKb79-LNI1PByKj9=s120-c-c0x00000000-cc-rp-mo-ba5-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Yesterday we did the gangster tour. Tim was fabulous. He has wealth of knowledge and knows how to tell the tales. Today we did the 1919 tour with George. It was great. I learned a lot on both tours.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNDdVBXUVhnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICCuPWQXg%7CCgsI2PTflwYQ0IOMJw%7C?hl=en-US",
      rawDate: "08-13-2022 7:24 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1MkpTS1RBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1MkpTS1RBEi4KF0NJSE0wb2dLRUlDQWdJRHUySlNLekFFEhNDZ3dJMi16YWx3WVF3TEcxemdFGi0KFkNJSE0wb2dLRUlDQWdJRHUySlNLTEESE0Nnd0kyLXphbHdZUXdMRzF6Z0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STItemFsd1lRd0xHMXpnRQ&wv=1&d=286732320",
      plusId: "107402523389928253274",
      reviewAuthor: "Tammy Wachter",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2TN7zQ1XDdPcoBQcsSp8U8lqUeIyWG-3_Bi11F=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We went on the Ultimate Queen City Underground tour with Laura and it was well worth it! Laura was a fabulous tour guide, mixing in the right amount of humor, anecdote and history. It was fascinating and fun and if we were staying longer we absolutely would do another tour with her. Highly recommend.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1MkpTS1RBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu2JSKTA%7CCgwI2-zalwYQwLG1zgE%7C?hl=en-US",
      rawDate: "08-12-2022 8:21 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1MklUT1BBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1MklUT1BBEi0KF0NJSE0wb2dLRUlDQWdJRHUySVNWcVFFEhJDZ3NJcHVuYWx3WVFzUGFlY2caLAoWQ0lITTBvZ0tFSUNBZ0lEdTJJU1ZhURISQ2dzSXB1bmFsd1lRc1BhZWNnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lwdW5hbHdZUXNQYWVjZw&wv=1&d=286732320",
      plusId: "110712364133128470197",
      reviewAuthor: "George Ellis",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-iBYgrAlKpIGL9uRwY-Ln8xCWzHd1mPGY2K5or=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Very informative about the early history of Cincinnati and of the development of the local beer industry. Our guide, Martin, did an excellent job of narrating everything and answering questions. All well worth the ticket price.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1MklUT1BBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu2ITOPA%7CCgsIpunalwYQsPaecg%7C?hl=en-US",
      rawDate: "08-12-2022 8:14 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1MktqYkZnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1MktqYkZnEi4KF0NJSE0wb2dLRUlDQWdJRHUyS2pibGdFEhNDZ3dJZ3VUYWx3WVFpTlBXa0FJGi0KFkNJSE0wb2dLRUlDQWdJRHUyS2piVmcSE0Nnd0lndVRhbHdZUWlOUFdrQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWd1VGFsd1lRaU5QV2tBSQ&wv=1&d=286732320",
      plusId: "106534507367102847827",
      reviewAuthor: "Rose Kapor",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu009ro0Lq9HHaLXPqGwoorS5RmK8NQ99gr3vL-L=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Fabulous and fun. Miranda made so interesting and enjoyable. She is incredibly informative and passionate.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1MktqYkZnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu2KjbFg%7CCgwIguTalwYQiNPWkAI%7C?hl=en-US",
      rawDate: "08-12-2022 8:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1NkpXNWl3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1NkpXNWl3RRItChZDSUhNMG9nS0VJQ0FnSUR1NkpXNVN3EhNDZ3dJdmREWmx3WVFrSmVWaHdJGi4KF0NJSE0wb2dLRUlDQWdJRHU2Slc1eXdFEhNDZ3dJdmREWmx3WVFrSmVWaHdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2ZERabHdZUWtKZVZod0k&wv=1&d=286732320",
      plusId: "104275338234655306439",
      reviewAuthor: "Shannon Monach",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-Evgt4QxQTiyiE-vFnUduLAErsMGRi-_m1NTMr2g=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Thank you Miranda for this entertaining, informative tour! We appreciate your quick wit with our fun group.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1NkpXNWl3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu6JW5iwE%7CCgwIvdDZlwYQkJeVhwI%7C?hl=en-US",
      rawDate: "08-12-2022 2:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1aU52d3h3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1aU52d3h3RRIsChZDSUhNMG9nS0VJQ0FnSUR1aU52d0p3EhJDZ3NJOFBIV2x3WVE4SVRER0EaLQoXQ0lITTBvZ0tFSUNBZ0lEdWlOdndwd0USEkNnc0k4UEhXbHdZUThJVERHQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJOFBIV2x3WVE4SVRER0E&wv=1&d=286732320",
      plusId: "106234239162708527954",
      reviewAuthor: "Heidi Garcia-Lambert",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-KtS0G6FQB9m3tznsA0X0hsngUna1LHXIv6Ab5-Q=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Such an insightful, fun tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1aU52d3h3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuiNvwxwE%7CCgsI8PHWlwYQ8ITDGA%7C?hl=en-US",
      rawDate: "08-12-2022 2:20 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1NE1UWVRnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1NE1UWVRnEi0KF0NJSE0wb2dLRUlDQWdJRHU0TVRZemdFEhJDZ3NJbl8zUWx3WVFzSXlGRncaLAoWQ0lITTBvZ0tFSUNBZ0lEdTRNVFlMZxISQ2dzSW5fM1Fsd1lRc0l5RkZ3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0luXzNRbHdZUXNJeUZGdw&wv=1&d=286732320",
      plusId: "104499996272357805657",
      reviewAuthor: "Wendy Smith",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3CPOSnzw9rrY8Shmc7E774Oz7E5pMVFR5l7abc=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "My husband and I were on vacation in the area and booked an Underground tour. It was AMAZING! Miranda was an excellent tour guide. I wish we had booked the ghost tour as well but we will save that for next time we're in town. Five out of five, well worth it.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1NE1UWVRnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDu4MTYTg%7CCgsIn_3QlwYQsIyFFw%7C?hl=en-US",
      rawDate: "08-10-2022 11:26 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUR1b0wzM1N3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUR1b0wzM1N3Ei4KF0NJSE0wb2dLRUlDQWdJRHVvTDMzeXdFEhNDZ3dJZ01IUWx3WVEtUExoaXdNGi0KFkNJSE0wb2dLRUlDQWdJRHVvTDMzS3cSE0Nnd0lnTUhRbHdZUS1QTGhpd00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWdNSFFsd1lRLVBMaGl3TQ&wv=1&d=286732320",
      plusId: "118142947853648777778",
      reviewAuthor: "Phil Smith",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0zNlmbEg2-_uRs7gvmDM4ViJoZXAjg1nXyGv3Q=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Martin was the man!!!! Me and my wife had so much fun. The tour was just a little bit too long though, this isn\u2019t a bad thing, but I feel the tour could be condensed into 90 minutes with no problem. Seriously had a blast and learned so much stuff though.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1b0wzM1N3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuoL33Sw%7CCgwIgMHQlwYQ-PLhiwM%7C?hl=en-US",
      rawDate: "08-10-2022 9:17 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR1d1Azb213RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUR1d1Azb213RRItChZDSUhNMG9nS0VJQ0FnSUR1d1Azb1d3EhNDZ3dJM05QUGx3WVEyT2o1MXdJGi4KF0NJSE0wb2dLRUlDQWdJRHV3UDNvMndFEhNDZ3dJM05QUGx3WVEyT2o1MXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzTlBQbHdZUTJPajUxd0k&wv=1&d=286732320",
      plusId: "100563660423879106576",
      reviewAuthor: "donald wilson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9zE6kGbwSKpxQtXWQ9zjs5mM8jOyUICyUfoZ6I9A=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Dean was our tour guide and he was amazing. He introduced us with historical information ending in some corny jokes. He was very knowledgeable and interesting to learn from. Loves this tour!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR1d1Azb213RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDuwP3omwE%7CCgwI3NPPlwYQ2Oj51wI%7C?hl=en-US",
      rawDate: "08-10-2022 5:24 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1XzZPNVJ3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1XzZPNVJ3Ei0KF0NJSE0wb2dLRUlDQWdJQ3VfNk81eHdFEhJDZ3NJdjVUT2x3WVFnSnpGVlEaLAoWQ0lITTBvZ0tFSUNBZ0lDdV82TzVKdxISQ2dzSXY1VE9sd1lRZ0p6RlZRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l2NVRPbHdZUWdKekZWUQ&wv=1&d=286732320",
      plusId: "110672099123970375694",
      reviewAuthor: "Laura Jerrels",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2POH_5OG3Y8wMdbU_2xztvIbInfObWm9tqkgHt=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 4,
      reviewText:
        "This was a wonderful look into the history and architecture of the area including some one of a kind peeks into some hidden underground gems. Our tour guide, Dean, was friendly and informative. If you like history stuff, this tour is for you.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1XzZPNVJ3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu_6O5Rw%7CCgsIv5TOlwYQgJzFVQ%7C?hl=en-US",
      rawDate: "08-10-2022 10:36 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1My1LOFFnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1My1LOFFnEi4KF0NJSE0wb2dLRUlDQWdJQ3UzLUs4d2dFEhNDZ3dJN0tQTGx3WVFnS2Fpb2dJGi0KFkNJSE0wb2dLRUlDQWdJQ3UzLUs4SWcSE0Nnd0k3S1BMbHdZUWdLYWlvZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STdLUExsd1lRZ0thaW9nSQ&wv=1&d=286732320",
      plusId: "115051379182329358859",
      reviewAuthor: "Michele F",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2qSaaiD5Z7jB9kdSix0Md08awB2mrBh0MhLKdh=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I absolutely loved this tour. So much of Cincinnati that I did not know. Miranda was the best guide. She was very knowledgeable and funny! Will sign up for other tours with her!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1My1LOFFnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu3-K8Qg%7CCgwI7KPLlwYQgKaiogI%7C?hl=en-US",
      rawDate: "08-09-2022 9:30 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1bl9Da0JREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1bl9Da0JREi4KF0NJSE0wb2dLRUlDQWdJQ3VuX0NraFFFEhNDZ3dJNzZMS2x3WVEwSldfa1FFGi0KFkNJSE0wb2dLRUlDQWdJQ3VuX0NrUlESE0Nnd0k3NkxLbHdZUTBKV19rUUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STc2TEtsd1lRMEpXX2tRRQ&wv=1&d=286732320",
      plusId: "115558919427859493405",
      reviewAuthor: "Nereida Guzman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-fw6-LjboRSG5g_SVJdbIH_a3lvhkn16SeqJ9A6Ac=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda was absolutely amazing... We love all their tours. I love her professionalism and funny character!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1bl9Da0JREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICun_CkBQ%7CCgwI76LKlwYQ0JW_kQE%7C?hl=en-US",
      rawDate: "08-09-2022 4:55 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1cDhianZ3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1cDhianZ3RRIsChZDSUhNMG9nS0VJQ0FnSUN1cDhiamZ3EhJDZ3NJdU16Qmx3WVF1STNFSWcaLQoXQ0lITTBvZ0tFSUNBZ0lDdXA4Ympfd0USEkNnc0l1TXpCbHdZUXVJM0VJZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJdU16Qmx3WVF1STNFSWc&wv=1&d=286732320",
      plusId: "110377334725796495450",
      reviewAuthor: "Debra Haire",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-6LtyUWXu8jPd4dxUHoZ6PfLKuUr4Bh08C7m7I3rw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Queen City Underground Tour was awesome! Miranda was so knowledgeable and a great story teller.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1cDhianZ3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICup8bjvwE%7CCgsIuMzBlwYQuI3EIg%7C?hl=en-US",
      rawDate: "08-08-2022 1:26 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1cC1xRzBnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1cC1xRzBnRRItChZDSUhNMG9nS0VJQ0FnSUN1cC1xR01nEhNDZ3dJdnNQQmx3WVEwUHZPMFFJGi4KF0NJSE0wb2dLRUlDQWdJQ3VwLXFHc2dFEhNDZ3dJdnNQQmx3WVEwUHZPMFFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2c1BCbHdZUTBQdk8wUUk&wv=1&d=286732320",
      plusId: "104120832325121686377",
      reviewAuthor: "James Blair",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8Nr_j-vLPZBTW4xxe9lkm1CKTS6fxMvKWcYleHug=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda did an incredible job leading our group. Her storytelling was very compelling and kept us all wanting to know more and more about the Cincinnati Underground.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1cC1xRzBnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICup-qG0gE%7CCgwIvsPBlwYQ0PvO0QI%7C?hl=en-US",
      rawDate: "08-08-2022 1:07 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1cDhEdFBnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1cDhEdFBnEi4KF0NJSE0wb2dLRUlDQWdJQ3VwOER0dmdFEhNDZ3dJbllqQmx3WVEwTXIzcWdNGi0KFkNJSE0wb2dLRUlDQWdJQ3VwOER0ZmcSE0Nnd0luWWpCbHdZUTBNcjNxZ00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW5ZakJsd1lRME1yM3FnTQ&wv=1&d=286732320",
      plusId: "112661535335253663850",
      reviewAuthor: "Bret Furtwengler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8XxxmUIOdxBKhboo-eDtMzkVpNAKesA-_1ReP5oA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "This was such a fun activity!! Miranda did an amazing job of keeping the tour fun, lively and entertaining while also educating us. I have lived in Cincinnati for 50 years and I learned a lot about Cincinnati\u2019s history and OTR that I never knew. I would highly recommend this tour to anyone and I\u2019m planning on coming back for the ghost tour!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1cDhEdFBnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICup8DtPg%7CCgwInYjBlwYQ0Mr3qgM%7C?hl=en-US",
      rawDate: "08-07-2022 11:00 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1dTdmbUdnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1dTdmbUdnEi4KF0NJSE0wb2dLRUlDQWdJQ3V1N2ZtbWdFEhNDZ3dJM3M2LWx3WVFzTV9YalFFGi0KFkNJSE0wb2dLRUlDQWdJQ3V1N2ZtV2cSE0Nnd0kzczYtbHdZUXNNX1hqUUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STNzNi1sd1lRc01fWGpRRQ&wv=1&d=286732320",
      plusId: "114799506685845945251",
      reviewAuthor: "Mary Sharon Rinear",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1jk7XvMkGk8PFg61C9L0305cX7shiNKZV37OE4=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Very informative!\nI am a lifelong Cincinnati resident and learned so much about the history and current improvements in the OTR area\nIt was fascinating and fun\nThanks Matt",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dTdmbUdnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuu7fmGg%7CCgwI3s6-lwYQsM_XjQE%7C?hl=en-US",
      rawDate: "08-07-2022 11:51 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1bTRid3pnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1bTRid3pnRRItChZDSUhNMG9nS0VJQ0FnSUN1bTRid0xnEhNDZ3dJc05XN2x3WVE4SV9lN0FFGi4KF0NJSE0wb2dLRUlDQWdJQ3VtNGFvbndFEhNDZ3dJc05XN2x3WVE4SV9lN0FFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lzTlc3bHdZUThJX2U3QUU&wv=1&d=286732320",
      plusId: "116663860723200125227",
      reviewAuthor: "Sandy Geraci",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2kShwD4M78qIzLtZLxpCTcNQwa603yuLqjEu-C=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "George was great!\nLoved all the history",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1bTRid3pnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICum4bwzgE%7CCgwIsNW7lwYQ8I_e7AE%7C?hl=en-US",
      rawDate: "08-06-2022 10:26 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1bV9xQ0hBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1bV9xQ0hBEi4KF0NJSE0wb2dLRUlDQWdJQ3VtX3FDbkFFEhNDZ3dJczlTN2x3WVE2UFhpMkFJGi0KFkNJSE0wb2dLRUlDQWdJQ3VtX3FDWEESE0Nnd0lzOVM3bHdZUTZQWGkyQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXM5Uzdsd1lRNlBYaTJBSQ&wv=1&d=286732320",
      plusId: "112658189209697490903",
      reviewAuthor: "Julia Geraci",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_oWJ4OavWGXDDAM2UBGUu3nVGDh6ooLwyXWcmX-RA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "George did a fantastic job! So much interesting history. We really enjoyed the tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1bV9xQ0hBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICum_qCHA%7CCgwIs9S7lwYQ6PXi2AI%7C?hl=en-US",
      rawDate: "08-06-2022 10:24 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1bTZDQmd3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1bTZDQmd3RRItChZDSUhNMG9nS0VJQ0FnSUN1bTZDQlF3EhNDZ3dJMEtTN2x3WVFxSW5MeVFJGi4KF0NJSE0wb2dLRUlDQWdJQ3VtNkNCd3dFEhNDZ3dJMEtTN2x3WVFxSW5MeVFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kwS1M3bHdZUXFJbkx5UUk&wv=1&d=286732320",
      plusId: "117557869820911722744",
      reviewAuthor: "Justin Hopkins",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9CZ4EteIAPyXtANOLS_jcIbf96rVGVwqPJnzQAnw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I went on the Queen City Underground tour. George did a fantastic job regaling is with stories of old Cincinnati. His entertaining discussions on the brewery and prohibition history was great. Thanks for a great excursion!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1bTZDQmd3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICum6CBgwE%7CCgwI0KS7lwYQqInLyQI%7C?hl=en-US",
      rawDate: "08-06-2022 8:42 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1dzdYWDZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1dzdYWDZBRRIsChZDSUhNMG9nS0VJQ0FnSUN1dzdYWEdBEhJDZ3NJdUl1eGx3WVE0TG5YWkEaLQoXQ0lITTBvZ0tFSUNBZ0lDdXc3WFhtQUUSEkNnc0l1SXV4bHdZUTRMblhaQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJdUl1eGx3WVE0TG5YWkE&wv=1&d=286732320",
      plusId: "104738560349789937093",
      reviewAuthor: "Matthew Canter",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8idUWsP1Iy2QJXLM5jCJtrnE9_PrbXem8z443QMQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Tour was great, Miranda was an excellent guide.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1dzdYWDZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuw7XX6AE%7CCgsIuIuxlwYQ4LnXZA%7C?hl=en-US",
      rawDate: "08-04-2022 10:18 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1OWNDVEpnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1OWNDVEpnEi4KF0NJSE0wb2dLRUlDQWdJQ3U5Y0NUcGdFEhNDZ3dJdmVxbWx3WVF5UFREM3dJGi0KFkNJSE0wb2dLRUlDQWdJQ3U5Y0NUWmcSE0Nnd0l2ZXFtbHdZUXlQVEQzd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXZlcW1sd1lReVBURDN3SQ&wv=1&d=286732320",
      plusId: "115719505108504666958",
      reviewAuthor: "Jeff Carmean",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3KLTOxVZGiWRVXekBpE9LX3EKFeboHin8a0882=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Did the ULTIMATE QUEEN CITY UNDERGROUND TOUR. Miranda was our tour guide and was amazing. My daughter is 12 and had and loved the tour. My son is 10 and thought the tour was pretty good which is an excellent review from him. My wife and I enjoyed the tour as well. Tour moved well, tour guide provided a very informative experience.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1OWNDVEpnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu9cCTJg%7CCgwIveqmlwYQyPTD3wI%7C?hl=en-US",
      rawDate: "08-02-2022 11:37 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1dGJ6VVNREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1dGJ6VVNREi0KFkNJSE0wb2dLRUlDQWdJQ3V0YnpVS1ESE0Nnd0kwZnFsbHdZUXNQRzBoQUkaLgoXQ0lITTBvZ0tFSUNBZ0lDdXRielVxUUUSE0Nnd0kwZnFsbHdZUXNQRzBoQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STBmcWxsd1lRc1BHMGhBSQ&wv=1&d=286732320",
      plusId: "106904466827166364623",
      reviewAuthor: "Morgan Young",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0qQvI-nCr6-XuBLWpbKOzjZfQ26JWBuv8EtgVL=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We took the ultimate queen city underground tour with Zack. He was super knowledgeable, had great jokes, and gave an amazing walking tour! Learned so much about over the Rhine and got to go underground twice. 10/10 would recommend to friends.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dGJ6VVNREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICutbzUSQ%7CCgwI0fqllwYQsPG0hAI%7C?hl=en-US",
      rawDate: "08-02-2022 7:38 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1dWZfWjNBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1dWZfWjNBRRItChZDSUhNMG9nS0VJQ0FnSUN1dWZfWlBBEhNDZ3dJc2I2Zmx3WVE0SnlIOFFFGi4KF0NJSE0wb2dLRUlDQWdJQ3V1Zl9adkFFEhNDZ3dJc2I2Zmx3WVE0SnlIOFFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lzYjZmbHdZUTRKeUg4UUU&wv=1&d=286732320",
      plusId: "107016955506664083590",
      reviewAuthor: "Kelli K",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2NjuQqNg3ISXA9KNiF58-SP3GRpNloHZEBjVA8=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "This tour was a great way to learn about Cincinnati. George was an excellent guide and answered all our questions.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1dWZfWjNBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuuf_Z3AE%7CCgwIsb6flwYQ4JyH8QE%7C?hl=en-US",
      rawDate: "08-01-2022 2:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1dWY2ak5BEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1dWY2ak5BEi4KF0NJSE0wb2dLRUlDQWdJQ3V1ZjZqdEFFEhNDZ3dJMEk2Zmx3WVEyUC1pcVFNGi0KFkNJSE0wb2dLRUlDQWdJQ3V1ZjZqZEESE0Nnd0kwSTZmbHdZUTJQLWlxUU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STBJNmZsd1lRMlAtaXFRTQ&wv=1&d=286732320",
      plusId: "107366976678906071377",
      reviewAuthor: "Jason Goby",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3R_VE03IS9Itbg2kXcCuXlg4Ouy91h68cNDeyM=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Zach was the best tour guide. We loved this tour and rate 10 out of 10",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dWY2ak5BEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuuf6jNA%7CCgwI0I6flwYQ2P-iqQM%7C?hl=en-US",
      rawDate: "08-01-2022 12:30 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1NmF6azFRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1NmF6azFRRRItChZDSUhNMG9nS0VJQ0FnSUN1NmF6a05REhNDZ3dJNDlTYmx3WVEtSTN3clFNGi4KF0NJSE0wb2dLRUlDQWdJQ3U2YXpTcFFFEhNDZ3dJNDlTYmx3WVEtSTN3clFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k0OVNibHdZUS1JM3dyUU0&wv=1&d=286732320",
      plusId: "106505746636170149551",
      reviewAuthor: "Tiffany Evans Putnam",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8pwyWoCd_b3CCiFjs864zYupKEm8NJ4-ewiafu3w=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We did the Ultimate Queen City Tour with George and it was an amazing time. He was uber knowledgeable about the city, and genuinely seemed like he was enjoying himself. We learned so much about the OTR area, which we would have previously stayed away from, due to its prior reputation. I am so glad that we did this tour, it opened up our eyes to this great neighborhood with amazing history!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1NmF6azFRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu6azk1QE%7CCgwI49SblwYQ-I3wrQM%7C?hl=en-US",
      rawDate: "07-31-2022 8:47 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1eWZxajZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1eWZxajZBRRIsChZDSUhNMG9nS0VJQ0FnSUN1eWZxakdBEhJDZ3NJcXBpYWx3WVFnUHFqU0EaLQoXQ0lITTBvZ0tFSUNBZ0lDdXlmcWptQUUSEkNnc0lxcGlhbHdZUWdQcWpTQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJcXBpYWx3WVFnUHFqU0E&wv=1&d=286732320",
      plusId: "109952522696081609326",
      reviewAuthor: "Rose Hay",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_jHaLQ6l8tXI0lqK0zcm2PIzE4ExjntKNoc_bcgw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I had heard about the underground caverns in Cincinnati and wanted to see them, after some googling I came across this tour service. I\u2019m so happy I went with them! Our tour guide Zack was very well spoken, knowledgeable, and told enough jokes to lighten the historical mood. We had a couple teens and kids on the tour and he made she to engage and include them while telling the stories. Ask for Zack! You spend time on this tour above ground walking along/near Vine St and in Washington Park. The underground portions are in a Crypt under a church and abandoned brewery tunnels under an apartment building. You will have to go down very small stairs so if this makes you uncomfortable, this tour might not be for you. The tunnels were my favorite part and the history surrounding them is too neat to miss! I highly recommend going on this tour if you\u2019d like to learn more about Cincinnati and see some unique sights.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1eWZxajZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuyfqj6AE%7CCgsIqpialwYQgPqjSA%7C?hl=en-US",
      rawDate: "07-31-2022 2:05 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1aWZHM2dBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1aWZHM2dBRRItChZDSUhNMG9nS0VJQ0FnSUN1aWZHM1FBEhNDZ3dJOThTWmx3WVFvTktfakFFGi4KF0NJSE0wb2dLRUlDQWdJQ3VpZkczd0FFEhNDZ3dJOThTWmx3WVFvTktfakFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k5OFNabHdZUW9OS19qQUU&wv=1&d=286732320",
      plusId: "108776967470201713689",
      reviewAuthor: "Cathy Cox",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu30Az2RTZQAir-OI0PoOgRON-NHJfiDDpC2dIda=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "So interesting and Zak was an amazing guide.\nSo glad we did the tour!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1aWZHM2dBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuifG3gAE%7CCgwI98SZlwYQoNK_jAE%7C?hl=en-US",
      rawDate: "07-31-2022 11:07 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1c2R2amZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1c2R2amZREi0KF0NJSE0wb2dLRUlDQWdJQ3VzZHZqX1FFEhJDZ3NJNDhhWGx3WVF5TUtSR2caLAoWQ0lITTBvZ0tFSUNBZ0lDdXNkdmpBdxISQ2dzSTQ4YVhsd1lReU1LUkdnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k0OGFYbHdZUXlNS1JHZw&wv=1&d=286732320",
      plusId: "108694503313096290242",
      reviewAuthor: "K W",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu382eJRlSHsVeBZQruR1PsAd6ZmLF7lyDsqY4oC=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Our tour was great! Our tour guide George was knowledgeable and entertaining. My 10 and 12 year olds also loved it!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1c2R2amZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICusdvjfQ%7CCgsI48aXlwYQyMKRGg%7C?hl=en-US",
      rawDate: "07-31-2022 2:05 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1c2IzR0RnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1c2IzR0RnEi0KF0NJSE0wb2dLRUlDQWdJQ3VzYjNHamdFEhJDZ3NJMDdDWGx3WVFpTVhLWVEaLAoWQ0lITTBvZ0tFSUNBZ0lDdXNiM0dUZxISQ2dzSTA3Q1hsd1lRaU1YS1lRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kwN0NYbHdZUWlNWEtZUQ&wv=1&d=286732320",
      plusId: "102481779072269461721",
      reviewAuthor: "Karin Kloss",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3i7XzRkUwYmI7dsZNHDa2TF1SvP-DA8dgPoyrL=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda was an awesome tour guide! Very knowledgeable and engaging. I was very impressed that she remembered everyone\u2019s name and was very personable. One of the best tours I\u2019ve been on! We will be back with our family.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1c2IzR0RnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICusb3GDg%7CCgsI07CXlwYQiMXKYQ%7C?hl=en-US",
      rawDate: "07-31-2022 1:18 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1MGNtNGZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1MGNtNGZREi4KF0NJSE0wb2dLRUlDQWdJQ3UwY200X1FFEhNDZ3dJbElLV2x3WVE2S19IMEFFGi0KFkNJSE0wb2dLRUlDQWdJQ3UwY200QXcSE0Nnd0lsSUtXbHdZUTZLX0gwQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWxJS1dsd1lRNktfSDBBRQ&wv=1&d=286732320",
      plusId: "110305238676414958907",
      reviewAuthor: "Stefanie James",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9HVBalYcXpgcTYo8oFI1a-vnVhYJnb5yWGdpImlg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "George was awesome! He knows his stuff and provided so much relevant info, on the underground tour. And\u2026now I know why FDR was my grandmothers fave president.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1MGNtNGZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu0cm4fQ%7CCgwIlIKWlwYQ6K_H0AE%7C?hl=en-US",
      rawDate: "07-30-2022 7:05 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1MGVHaktBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1MGVHaktBEi4KF0NJSE0wb2dLRUlDQWdJQ3UwZUdqcUFFEhNDZ3dJcl8yVmx3WVE2TGI1aEFJGi0KFkNJSE0wb2dLRUlDQWdJQ3UwZUdqYUESE0Nnd0lyXzJWbHdZUTZMYjVoQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXJfMlZsd1lRNkxiNWhBSQ&wv=1&d=286732320",
      plusId: "103986847427848914924",
      reviewAuthor: "Ali Agee",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-QfZL64PT80hTE3SopgSnf51_Luf7S4niEztmfQg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "George was a phenomenal tour guide. So many fun facts and very knowledgeable. It was fun to learn more about the city!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1MGVHaktBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu0eGjKA%7CCgwIr_2VlwYQ6Lb5hAI%7C?hl=en-US",
      rawDate: "07-30-2022 6:55 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1d2ZpdUl3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1d2ZpdUl3Ei0KF0NJSE0wb2dLRUlDQWdJQ3V3Zml1b3dFEhJDZ3NJMXJTUmx3WVF3SVczY1EaLAoWQ0lITTBvZ0tFSUNBZ0lDdXdmaXVZdxISQ2dzSTFyU1Jsd1lRd0lXM2NRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kxclNSbHdZUXdJVzNjUQ&wv=1&d=286732320",
      plusId: "104517830787636093701",
      reviewAuthor: "Jason Greenwood",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8a0zM7Bpo4UZjsd9WlmGZL4WKy-HSnZ_Nh0_Dm=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The Hidden Caverns Tour\nReceived tour tickets as a Christmas gift and had to reschedule to July. Thankfully we had Miranda as our tour guide! Miranda is awesome at what she does and is very passionate about her customers having a great experience. Love that she took time with each little group on the tour to get to know them a little bit. Miranda makes the the tour feel like we are part of her family and she wants to show off her new neighborhood.\n\nWe are in OTR quite often but didn't know much of the history behind the Cincinnati beer scene or OTR. Miranda's tour has made the time we spend in OTR a little more fun as we can now share facts with others.\n\nThank you Miranda!\nJason/James/Justin",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1d2ZpdUl3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuwfiuIw%7CCgsI1rSRlwYQwIW3cQ%7C?hl=en-US",
      rawDate: "07-29-2022 10:08 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1Z1oyVi1nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1Z1oyVi1nRRotChZDSUhNMG9nS0VJQ0FnSUN1Z1oyVkJnEhNDZ3dJMG9DUmx3WVEyTm5UandNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kwb0NSbHdZUTJOblRqd00&wv=1&d=286732320",
      plusId: "107034990443327506322",
      reviewAuthor: "Donna Steineman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2FQxyU49LkAnvr0A5c3YWTPwnPOif83FFbYoiW=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1Z1oyVi1nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICugZ2V-gE%7CCgwI0oCRlwYQ2NnTjwM%7C?hl=en-US",
      rawDate: "07-29-2022 8:17 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1dHRXMXp3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1dHRXMXp3RRItChZDSUhNMG9nS0VJQ0FnSUN1dHRXMUx3EhNDZ3dJaTdpSGx3WVE4TjJBMHdNGi4KF0NJSE0wb2dLRUlDQWdJQ3V0dFcxcndFEhNDZ3dJaTdpSGx3WVE4TjJBMHdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lpN2lIbHdZUThOMkEwd00&wv=1&d=286732320",
      plusId: "104997553160161811742",
      reviewAuthor: "Chelsea Kennett",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu04RcOlCYJi65bsLy-3uSJX_ICQHoZ_fduN2MIg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "My friends and I took the Ultimate Queen City Underground Tour while in Cincinnati for work. The tour was extremely informative about the history of the city and some of the hidden secrets/underground world. We try to do tours in every city we pass through and this was definitely the most informative and interesting one I can remember. Our tour guide Zach was really funny and knowledgeable and definitely made the tour great. We are going to look into other tours with Legacy and would highly recommend!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1dHRXMXp3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuttW1zwE%7CCgwIi7iHlwYQ8N2A0wM%7C?hl=en-US",
      rawDate: "07-28-2022 12:44 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1dHVXRy13RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1dHVXRy13RRItChZDSUhNMG9nS0VJQ0FnSUN1dHVXR0J3EhNDZ3dJMmJTSGx3WVF5T0RFMEFNGi4KF0NJSE0wb2dLRUlDQWdJQ3V0dVdHaHdFEhNDZ3dJMmJTSGx3WVF5T0RFMEFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kyYlNIbHdZUXlPREUwQU0&wv=1&d=286732320",
      plusId: "111747608922222416566",
      reviewAuthor: "Valerie C",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0lq5UjKn3sr7pkUC2sk2ZUsf4k2Efq-rAMeaZZ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Zack definitely made the tour awesome! The history was great and we loved all the recommendations he gave us for local spots after! One of our favorite tours in a while! Also if you are interested in Tiffany from Texas phone number let me know \ud83d\ude02",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1dHVXRy13RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICutuWG-wE%7CCgwI2bSHlwYQyODE0AM%7C?hl=en-US",
      rawDate: "07-28-2022 12:37 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1dHNxVFVREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1dHNxVFVREi4KF0NJSE0wb2dLRUlDQWdJQ3V0c3FUMFFFEhNDZ3dJcFBxR2x3WVF3Szd6a0FFGi0KFkNJSE0wb2dLRUlDQWdJQ3V0c3FUTVESE0Nnd0lwUHFHbHdZUXdLN3prQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXBQcUdsd1lRd0s3emtBRQ&wv=1&d=286732320",
      plusId: "105296012849536339778",
      reviewAuthor: "Floyd Sanders",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8R98kp9pFl8sF5J1f8eaxddNE6h6aZp6Yn-9SZvQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The whole experience was just wonderful. I have recommend the tour to my coworkers. I really enjoyed every minute of it.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dHNxVFVREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICutsqTUQ%7CCgwIpPqGlwYQwK7zkAE%7C?hl=en-US",
      rawDate: "07-27-2022 10:33 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1MXFmWjVBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1MXFmWjVBRRItChZDSUhNMG9nS0VJQ0FnSUN1MXFmWkZBEhNDZ3dJc2ItR2x3WVE4S0d0b1FNGi4KF0NJSE0wb2dLRUlDQWdJQ3UxcWZabEFFEhNDZ3dJc2ItR2x3WVE4S0d0b1FNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lzYi1HbHdZUThLR3RvUU0&wv=1&d=286732320",
      plusId: "104953795458697426414",
      reviewAuthor: "Shelby Masterson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu16iCwPZjrLUak0u4tdiHPEwyJ2JDSoKdKlLaSB=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Miranda was amazing! So much fun!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1MXFmWjVBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu1qfZ5AE%7CCgwIsb-GlwYQ8KGtoQM%7C?hl=en-US",
      rawDate: "07-27-2022 8:27 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1aHJhblVBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1aHJhblVBEi4KF0NJSE0wb2dLRUlDQWdJQ3VocmFuMEFFEhNDZ3dJbWNPQmx3WVEwSkxCNUFFGi0KFkNJSE0wb2dLRUlDQWdJQ3VocmFuTUESE0Nnd0ltY09CbHdZUTBKTEI1QUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW1jT0Jsd1lRMEpMQjVBRQ&wv=1&d=286732320",
      plusId: "106860454133294619345",
      reviewAuthor: "susan johns",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_07_aNTaPYoo3hkkQJX1jaoc4rZOXgMefBE4tu6T4=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We did the underground tour and it was great. Our tour leader Miranda was extremely friendly and knowledgeable and made the tour extra fun. I learned a lot about Cincy that I wasn't aware of! Highly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1aHJhblVBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuhranUA%7CCgwImcOBlwYQ0JLB5AE%7C?hl=en-US",
      rawDate: "07-26-2022 9:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1aHBMOTJRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1aHBMOTJRRRItChZDSUhNMG9nS0VJQ0FnSUN1aHBMOU9REhNDZ3dJckxHQmx3WVFxTGZwcVFNGi4KF0NJSE0wb2dLRUlDQWdJQ3VocEw5dVFFEhNDZ3dJckxHQmx3WVFxTGZwcVFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lyTEdCbHdZUXFMZnBxUU0&wv=1&d=286732320",
      plusId: "115489009523215901135",
      reviewAuthor: "Sarah Kenzer",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-dRu_Ipw75XFGww9DmRtqSY9JxoMX6JwsqnfehEQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda was the best!! She had the best stories and you could tell she really loves her job and wants everyone to love Cincinnati ad much ad she does. 22/10 would recommend to a friend!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1aHBMOTJRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuhpL92QE%7CCgwIrLGBlwYQqLfpqQM%7C?hl=en-US",
      rawDate: "07-26-2022 9:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1aHRUckp3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1aHRUckp3Ei4KF0NJSE0wb2dLRUlDQWdJQ3VodFRycHdFEhNDZ3dJcEtTQmx3WVF1UGFnd3dNGi0KFkNJSE0wb2dLRUlDQWdJQ3VodFRyWncSE0Nnd0lwS1NCbHdZUXVQYWd3d00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXBLU0Jsd1lRdVBhZ3d3TQ&wv=1&d=286732320",
      plusId: "116237552165395845598",
      reviewAuthor: "Mitch Bond",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9uDprjnJ-susjKkmaUQtxAsacrq0SlGOAt47HbEiY=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The tour guide Miranda was very funny and informative! We really enjoyed our time on the tour and would definitely recommend asking for her if you choose this tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1aHRUckp3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuhtTrJw%7CCgwIpKSBlwYQuPagwwM%7C?hl=en-US",
      rawDate: "07-26-2022 8:44 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1dXVEOEN3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1dXVEOEN3Ei4KF0NJSE0wb2dLRUlDQWdJQ3V1dUQ4aXdFEhNDZ3dJeU9iX2xnWVEySjJMaWdFGi0KFkNJSE0wb2dLRUlDQWdJQ3V1dUQ4U3cSE0Nnd0l5T2JfbGdZUTJKMkxpZ0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXlPYl9sZ1lRMkoyTGlnRQ&wv=1&d=286732320",
      plusId: "100453985405756520061",
      reviewAuthor: "Eric Vespierre",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-HeyDC5qtquq3Z1phcYPWVuyCc1oXTgtck8wSpug=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "The tour was well balanced in terms of duration, amount of information, and proximity of the different sites. Our tour leader, Laura, was very knowledgeable, and made the experience pleasant and interesting.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dXVEOEN3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuuuD8Cw%7CCgwIyOb_lgYQ2J2LigE%7C?hl=en-US",
      rawDate: "07-26-2022 1:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1bXRyOGdBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1bXRyOGdBRRItChZDSUhNMG9nS0VJQ0FnSUN1bXRyOFFBEhNDZ3dJMUx2LWxnWVE4TUtDaFFJGi4KF0NJSE0wb2dLRUlDQWdJQ3VtdHI4d0FFEhNDZ3dJMUx2LWxnWVE4TUtDaFFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kxTHYtbGdZUThNS0NoUUk&wv=1&d=286732320",
      plusId: "107015236404952440761",
      reviewAuthor: "Victoria Hoerst",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-s15n21j0CPJaflJ6AoR6TQdOCH9tWf833EwRvBg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Go no farther and book your tour with American Legacy Tours. Miranda lead our group. She captivated the audience by keeping us engaged with her theatrical sense of humor and knowledgeable tales of Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1bXRyOGdBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICumtr8gAE%7CCgwI1Lv-lgYQ8MKChQI%7C?hl=en-US",
      rawDate: "07-26-2022 7:55 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1cXNXZ3pBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1cXNXZ3pBRRItChZDSUhNMG9nS0VJQ0FnSUN1cXNXZ0xBEhNDZ3dJc01MOGxnWVF1SlBkbGdFGi4KF0NJSE0wb2dLRUlDQWdJQ3Vxc1dnckFFEhNDZ3dJc01MOGxnWVF1SlBkbGdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lzTUw4bGdZUXVKUGRsZ0U&wv=1&d=286732320",
      plusId: "101048101668233898999",
      reviewAuthor: "\u8881\u65b9\u5e86",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1YJwRdqr3fTzaZFvvOeqWiAe5BDWGTx-c_wxis=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText: "Laura is the best!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1cXNXZ3pBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuqsWgzAE%7CCgwIsML8lgYQuJPdlgE%7C?hl=en-US",
      rawDate: "07-25-2022 11:03 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1eXNPQlV3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1eXNPQlV3Ei0KF0NJSE0wb2dLRUlDQWdJQ3V5c09CMHdFEhJDZ3NJenN6N2xnWVF5TGZYY3caLAoWQ0lITTBvZ0tFSUNBZ0lDdXlzT0JNdxISQ2dzSXpzejdsZ1lReUxmWGN3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l6c3o3bGdZUXlMZlhjdw&wv=1&d=286732320",
      plusId: "110260600271433145353",
      reviewAuthor: "John Mask",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu28ZpVsgt8NIIj9fkkm25AKUxbGbCvB7eyLDrb2=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "This was a great experience! I enjoyed learning the history of the area while seeing the structures that made the Queen City so unique. Dean, our tour guide, was very hospitable and knowledgeable!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1eXNPQlV3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuysOBUw%7CCgsIzsz7lgYQyLfXcw%7C?hl=en-US",
      rawDate: "07-25-2022 6:51 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1eXF6ZGVnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1eXF6ZGVnEi4KF0NJSE0wb2dLRUlDQWdJQ3V5cXpkLWdFEhNDZ3dJM0pEN2xnWVE4S0M1c1FFGi0KFkNJSE0wb2dLRUlDQWdJQ3V5cXpkQmcSE0Nnd0kzSkQ3bGdZUThLQzVzUUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STNKRDdsZ1lROEtDNXNRRQ&wv=1&d=286732320",
      plusId: "111091191407363458352",
      reviewAuthor: "Michele Giarrano",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8FQIIShaZuT5UU6LIoq6y5R1kIpfWfMSzt2U7Leg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We thought the tour was great! We had a really fun experience. Our guide Zack did all the right things...he kept us moving and interested while we learned some really cool history!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1eXF6ZGVnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuyqzdeg%7CCgwI3JD7lgYQ8KC5sQE%7C?hl=en-US",
      rawDate: "07-25-2022 4:44 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1OHFlY2JBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1OHFlY2JBEi4KF0NJSE0wb2dLRUlDQWdJQ3U4cWVjN0FFEhNDZ3dJX3AzNmxnWVFrTnFrd0FJGi0KFkNJSE0wb2dLRUlDQWdJQ3U4cWVjSEESE0Nnd0lfcDM2bGdZUWtOcWt3QUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SV9wMzZsZ1lRa05xa3dBSQ&wv=1&d=286732320",
      plusId: "104178781675307324154",
      reviewAuthor: "cristi ohearn",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8WBwWtzSdWQOF2eFX5s3DSE3i0tdvc1cMU0t99Ud8=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Great tour! Was very informational and included great treats and drinks! Our tour guides were very friendly.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1OHFlY2JBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu8qecbA%7CCgwI_p36lgYQkNqkwAI%7C?hl=en-US",
      rawDate: "07-25-2022 12:39 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1MG9IT2F3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1MG9IT2F3Ei4KF0NJSE0wb2dLRUlDQWdJQ3Uwb0hPNndFEhNDZ3dJdjhiM2xnWVF1TUgyemdFGi0KFkNJSE0wb2dLRUlDQWdJQ3Uwb0hPR3cSE0Nnd0l2OGIzbGdZUXVNSDJ6Z0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXY4YjNsZ1lRdU1IMnpnRQ&wv=1&d=286732320",
      plusId: "109371392970720706950",
      reviewAuthor: "Laura Zalcberg",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1Jw7NL1UkGayyUhLRDave8B-z7UWVgngraYIF-=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We came in town for the weekend and we love it! Laura, our guide, was very entertaining and knowledgeable \u2665\ufe0f\nWe highly recommend it \ud83d\udc4f\ud83c\udffb\ud83d\udc4f\ud83c\udffb",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1MG9IT2F3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu0oHOaw%7CCgwIv8b3lgYQuMH2zgE%7C?hl=en-US",
      rawDate: "07-25-2022 12:26 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1NG8yQXBBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1NG8yQXBBRRItChZDSUhNMG9nS0VJQ0FnSUN1NG8yQVpBEhNDZ3dJeWNuMWxnWVEtTVhWbHdJGi4KF0NJSE0wb2dLRUlDQWdJQ3U0bzJBNUFFEhNDZ3dJeWNuMWxnWVEtTVhWbHdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l5Y24xbGdZUS1NWFZsd0k&wv=1&d=286732320",
      plusId: "106325043019264501189",
      reviewAuthor: "Kyra M.",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_B8-wJYUZcbzXc--2waqURNB8g0LDS2iX2CC9WEw=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Interesting walking tour of OTR. Lots of detailed history. A very nice way to spend an afternoon and get to know the area better.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1NG8yQXBBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu4o2ApAE%7CCgwIycn1lgYQ-MXVlwI%7C?hl=en-US",
      rawDate: "07-24-2022 3:27 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1b3YtM3NnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1b3YtM3NnRRIsChZDSUhNMG9nS0VJQ0FnSUN1b3YtM2NnEhJDZ3NJbFBfMGxnWVF5SjJzSXcaLQoXQ0lITTBvZ0tFSUNBZ0lDdW92LTM4Z0USEkNnc0lsUF8wbGdZUXlKMnNJdyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJbFBfMGxnWVF5SjJzSXc&wv=1&d=286732320",
      plusId: "111883616243167856583",
      reviewAuthor: "Amy Knell",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-kSMo6nZrK-3Jg1lnBx0zu9oE29wgeiYTdYvAH2w=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "So great! Took the Hidden Brewery Caverns Tour with Beer Tasting. Our guide MIRANDA was amazing! Very funny and knowledgeable. Possibly the best tour guide I've ever had on one of these types of short neighborhood tours. Highly recommend! Definitely suggest tennis shoes for this tour, as the stairs leading down to the caverns ain't no joke.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1b3YtM3NnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuov-3sgE%7CCgsIlP_0lgYQyJ2sIw%7C?hl=en-US",
      rawDate: "07-24-2022 12:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1Z3EyaGV3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1Z3EyaGV3Ei4KF0NJSE0wb2dLRUlDQWdJQ3VncTJoLXdFEhNDZ3dJbmN2eWxnWVFvTzJON1FFGi0KFkNJSE0wb2dLRUlDQWdJQ3VncTJoQncSE0Nnd0luY3Z5bGdZUW9PMk43UUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW5jdnlsZ1lRb08yTjdRRQ&wv=1&d=286732320",
      plusId: "116147447689242350917",
      reviewAuthor: "Kelly Partridge",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3-Nqo0zy00LsOdq-CngogMwtuuI2OOWmPNtAQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Zach presented the information in an entertaining way. We really enjoyed the tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1Z3EyaGV3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICugq2hew%7CCgwIncvylgYQoO2N7QE%7C?hl=en-US",
      rawDate: "07-24-2022 1:51 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1Z3ZyeHNnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1Z3ZyeHNnRRItChZDSUhNMG9nS0VJQ0FnSUN1Z3ZyeGNnEhNDZ3dJN1pEeWxnWVFrSjNJbndJGi4KF0NJSE0wb2dLRUlDQWdJQ3VndnJ4OGdFEhNDZ3dJN1pEeWxnWVFrSjNJbndJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k3WkR5bGdZUWtKM0lud0k&wv=1&d=286732320",
      plusId: "110593378296356764877",
      reviewAuthor: "Lisa Hyland",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0tVGXzLdbbBzRqub5-IMbK2S1tB3_NFXGmgpJb=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Our tour guide Laura was informative, funny engaging. Definitely learned a lot about the history in Cincinnati",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1Z3ZyeHNnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICugvrxsgE%7CCgwI7ZDylgYQkJ3InwI%7C?hl=en-US",
      rawDate: "07-23-2022 11:46 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1X0tDdmFREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1X0tDdmFREi4KF0NJSE0wb2dLRUlDQWdJQ3VfS0N2NlFFEhNDZ3dJX09Md2xnWVFrS0hjaHdNGi0KFkNJSE0wb2dLRUlDQWdJQ3VfS0N2R1ESE0Nnd0lfT0x3bGdZUWtLSGNod00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SV9PTHdsZ1lRa0tIY2h3TQ&wv=1&d=286732320",
      plusId: "114663086850197830283",
      reviewAuthor:
        "\u0399\u03bf\u03c5\u03c3\u03c4\u03af\u03bd\u03bf\u03c2 \u039c\u03cc\u03c1\u03b3\u03b1\u03bd",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8s-MfizadRqpuZHdWu3RLeDZrzVp3UMjkA4Iid=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "My wife and I had an incredible time on our Hidden Brewery tour, led by Miranda. I\u2019ll be coming back with my daughter in a few months to try one of the haunted tours. (My wife won\u2019t go on one of those).",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1X0tDdmFREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu_KCvaQ%7CCgwI_OLwlgYQkKHchwM%7C?hl=en-US",
      rawDate: "07-23-2022 5:35 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1dkl2b3ZRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1dkl2b3ZRRRItChZDSUhNMG9nS0VJQ0FnSUN1dkl2b2ZREhNDZ3dJOTh6d2xnWVF5STZ5OEFJGi4KF0NJSE0wb2dLRUlDQWdJQ3V2SXZvX1FFEhNDZ3dJOTh6d2xnWVF5STZ5OEFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k5OHp3bGdZUXlJNnk4QUk&wv=1&d=286732320",
      plusId: "117269786960701766184",
      reviewAuthor: "Kurtis",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8Y9VlJAxwXV8q12Gcc8Gl4POpP97BHIQMDcnav=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Let\u2019s be honest, Miranda shows off our city like a finely groomed show pony. a+, 5 star for this comedy show and history lesson. Must take if local of visiting.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1dkl2b3ZRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuvIvovQE%7CCgwI98zwlgYQyI6y8AI%7C?hl=en-US",
      rawDate: "07-23-2022 4:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1N055WDRRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1N055WDRRRRItChZDSUhNMG9nS0VJQ0FnSUN1N055WEVREhNDZ3dJbjRMdGxnWVFxS3psOUFJGi4KF0NJSE0wb2dLRUlDQWdJQ3U3TnlYa1FFEhNDZ3dJbjRMdGxnWVFxS3psOUFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0luNEx0bGdZUXFLemw5QUk&wv=1&d=286732320",
      plusId: "106411866782707527775",
      reviewAuthor: "Eddie Susshine",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu83k_KW_YANDr7uaEGwZTLX27FGXijvKvy0WoOYmA=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "What an amazing experience. Today we did the Ultimate Queen City Underground Tour. Patrick was an excellent tour guide with TONS of information. Highly recommended and will definitely look into the Haunted Cincinnati Tour",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1N055WDRRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu7NyX4QE%7CCgwIn4LtlgYQqKzl9AI%7C?hl=en-US",
      rawDate: "07-23-2022 12:30 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1cklpNmRREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1cklpNmRREi4KF0NJSE0wb2dLRUlDQWdJQ3VySWk2OVFFEhNDZ3dJNXVMcmxnWVFnTU9BdXdFGi0KFkNJSE0wb2dLRUlDQWdJQ3VySWk2RFESE0Nnd0k1dUxybGdZUWdNT0F1d0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STV1THJsZ1lRZ01PQXV3RQ&wv=1&d=286732320",
      plusId: "112556260545101604496",
      reviewAuthor: "Lisa Ellison",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9alwuJXNXhTf2sjoZYoU4tSSVAZyIJ2t9JdtxIiQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Went on the Queen City Underground Tour with Miranda. She was a fantastic guide - knowledgeable, funny, witty, and a great provider of shade on a hot day.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1cklpNmRREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICurIi6dQ%7CCgwI5uLrlgYQgMOAuwE%7C?hl=en-US",
      rawDate: "07-22-2022 6:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1ekl1UVNBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1ekl1UVNBEi4KF0NJSE0wb2dLRUlDQWdJQ3V6SXVReUFFEhNDZ3dJOWNicmxnWVEtS21VbHdFGi0KFkNJSE0wb2dLRUlDQWdJQ3V6SXVRS0ESE0Nnd0k5Y2JybGdZUS1LbVVsd0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STljYnJsZ1lRLUttVWx3RQ&wv=1&d=286732320",
      plusId: "110099726684015499457",
      reviewAuthor: "Danielle White",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9Zn52xhqcZJezeRTKuvV8E49UWsq25ZpTmEH3yTQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Great tour, and Miranda was knowledgeable and entertaining. Hope to return for additional tours.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1ekl1UVNBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuzIuQSA%7CCgwI9cbrlgYQ-KmUlwE%7C?hl=en-US",
      rawDate: "07-22-2022 5:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1ekpQS1N3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1ekpQS1N3Ei4KF0NJSE0wb2dLRUlDQWdJQ3V6SlBLeXdFEhNDZ3dJN3NQcmxnWVFxT0x6bGdJGi0KFkNJSE0wb2dLRUlDQWdJQ3V6SlBLS3cSE0Nnd0k3c1BybGdZUXFPTHpsZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STdzUHJsZ1lRcU9MemxnSQ&wv=1&d=286732320",
      plusId: "112015423707873067086",
      reviewAuthor: "Todd White",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-OIBlTHcfML8OnTFGLtAE3-dSFujFCR-3nrqNMT6w=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "We did the 11am tour guided by Miranda. She is a very humorous Australian transplant that lives in the neighborhood. Short walk above ground and then went to crypt of a church and tunnels under an old brewery. Very enjoyable. Highly recommended!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1ekpQS1N3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuzJPKSw%7CCgwI7sPrlgYQqOLzlgI%7C?hl=en-US",
      rawDate: "07-22-2022 5:44 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1OUxlbi1RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1OUxlbi1RRRIsChZDSUhNMG9nS0VJQ0FnSUN1OUxlbkJREhJDZ3NJcjR2cWxnWVFpTTZ1RmcaLQoXQ0lITTBvZ0tFSUNBZ0lDdTlMZW5oUUUSEkNnc0lyNHZxbGdZUWlNNnVGZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJcjR2cWxnWVFpTTZ1Rmc&wv=1&d=286732320",
      plusId: "116411129269676211454",
      reviewAuthor: "Taylor Kessen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1ea9ljODAF5jwdbudzUckSrnX6ylsWJVRmejal=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "I had an absolutely wonderful experience with American Legacy Tours. My fianc\u00e9 and I are long-term lovers of American Legacy Tours. We have done nearly all of their tours. My last experience, however was the best by far. I scheduled a private tour in order to propose to my girlfriend of four years. Craig with ALT was extremely helpful in getting my tour scheduledand finding a guide who would best be able to fulfill my rather specific needs. Craig put me in contact with his tour guide, Miranda, who was absolutely stoked to help make my proposal perfect. She provided me with her personal phone and after a very detailed conversation other phone, she even provided me with her home address so that I could drop off a prop for my proposal (since my working hours are pretty restricted). Miranda literally bent over backwards to make sure that every detail of my tour and my proposal was planned out. She even agreed to work on her off day (Sunday) because it worked better for me. To say the least, I could not have dreamed of a better experience from a company. So I have to commend Craig and ESPECIALLY Miranda. Craig hooked me up with the best tour guide ever and Miranda provided for my every need with exuberance and professionalism. She worked hard to keep my proposal a secret to my fianc\u00e9 while still giving an excellent tour. I would give them 6 stars if possible and will be booking more tours with Miranda!! Thank you again for making our very special day perfect!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1OUxlbi1RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu9Len-QE%7CCgsIr4vqlgYQiM6uFg%7C?hl=en-US",
      rawDate: "07-22-2022 11:10 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1MU5tWHpBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1MU5tWHpBRRItChZDSUhNMG9nS0VJQ0FnSUN1MU5tWExBEhNDZ3dJMTZYbmxnWVFnSnJTdWdNGi4KF0NJSE0wb2dLRUlDQWdJQ3UxTm1YckFFEhNDZ3dJMTZYbmxnWVFnSnJTdWdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kxNlhubGdZUWdKclN1Z00&wv=1&d=286732320",
      plusId: "105428466379986823781",
      reviewAuthor: "Mark Garrett",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8H5SWymW7qBUXXZK419LlM7oxJ3yfT0T6M6hWKWg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Was a very fun experience. Miranda was a great tour guide. She was knowledgeable and witty. Would recommend.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1MU5tWHpBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu1NmXzAE%7CCgwI16XnlgYQgJrSugM%7C?hl=en-US",
      rawDate: "07-21-2022 10:27 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1MVBhRHpnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1MVBhRHpnRRItChZDSUhNMG9nS0VJQ0FnSUN1MVBhRExnEhNDZ3dJMG96bmxnWVE2TFhPelFFGi4KF0NJSE0wb2dLRUlDQWdJQ3UxUGFEcmdFEhNDZ3dJMG96bmxnWVE2TFhPelFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kwb3pubGdZUTZMWE96UUU&wv=1&d=286732320",
      plusId: "107123318121884536286",
      reviewAuthor: "Arielle Schmidt",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8BmSCfIDZw1YlbbxE8nCLtF2T_W2WxozuOZGC3dg=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Miranda was amazing as our guide a few weeks ago (if I got the name wrong its the Australian lol), if you can snag a tour with her she was great, funny, informative, her voice carries well through the streets and loud areas of town. I have been on several tours with this company and will continue to book their tours. I had previously been on the underground tour in the past but new elements were added so don't worry about rebooking if its been awhile. I loved where we started this time.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1MVBhRHpnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu1PaDzgE%7CCgwI0oznlgYQ6LXOzQE%7C?hl=en-US",
      rawDate: "07-21-2022 9:34 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1cEtUcDdRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1cEtUcDdRRRItChZDSUhNMG9nS0VJQ0FnSUN1cEtUcEhREhNDZ3dJN2JUa2xnWVE4TmFpaGdJGi4KF0NJSE0wb2dLRUlDQWdJQ3VwS1RwblFFEhNDZ3dJN2JUa2xnWVE4TmFpaGdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k3YlRrbGdZUThOYWloZ0k&wv=1&d=286732320",
      plusId: "105494003758479185039",
      reviewAuthor: "Andrew Fino",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3fnV35lLnt4bLQLO8CnpxwNRsSvhn0VVGuKDk0=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "a month ago",
      reviewRating: 5,
      reviewText:
        "Took the hidden underground brewery cavern tour. It was very good, it was fun and informative. Our tour guide Dean was great he was very interesting and funny.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1cEtUcDdRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICupKTp7QE%7CCgwI7bTklgYQ8NaihgI%7C?hl=en-US",
      rawDate: "07-21-2022 9:20 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1aExhQlJnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1aExhQlJnEi0KF0NJSE0wb2dLRUlDQWdJQ3VoTGFCeGdFEhJDZ3NJbHRQaWxnWVF3T3EwU3caLAoWQ0lITTBvZ0tFSUNBZ0lDdWhMYUJKZxISQ2dzSWx0UGlsZ1lRd09xMFN3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lsdFBpbGdZUXdPcTBTdw&wv=1&d=286732320",
      plusId: "103901652360317574480",
      reviewAuthor: "Bronwyn Kropp",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu28W715N-h7aZhcWEtIwzQVOAP9lMF87XEJgA9l=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Did the Ultimate Queen City Underground Tour on a Wednesday afternoon. Zach did a great job providing a wide array of stories, information, and answering our questions. Tour includes a good amount of history without too much walking.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1aExhQlJnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuhLaBRg%7CCgsIltPilgYQwOq0Sw%7C?hl=en-US",
      rawDate: "07-21-2022 1:19 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1LU1XWW9nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1LU1XWW9nRRItChZDSUhNMG9nS0VJQ0FnSUN1LU1XWVlnEhNDZ3dJX2RYaGxnWVEySXpuZ3dNGi4KF0NJSE0wb2dLRUlDQWdJQ3UtTVdZNGdFEhNDZ3dJX2RYaGxnWVEySXpuZ3dNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lfZFhobGdZUTJJem5nd00&wv=1&d=286732320",
      plusId: "104673044090519599541",
      reviewAuthor: "Julien Kern",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2Tpevb1W6TLd0q7pimis8eag7X5WqDsxZxl0ji=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Cincy is a sleeper hit! We loved learning the history and taking in the character of the city. Zach was the best guide ever!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1LU1XWW9nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu-MWYogE%7CCgwI_dXhlgYQ2IzngwM%7C?hl=en-US",
      rawDate: "07-20-2022 8:52 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1LVBIdEtREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1LVBIdEtREi4KF0NJSE0wb2dLRUlDQWdJQ3UtUEh0cVFFEhNDZ3dJZzgzaGxnWVEwS0t2aEFJGi0KFkNJSE0wb2dLRUlDQWdJQ3UtUEh0YVESE0Nnd0lnODNobGdZUTBLS3ZoQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWc4M2hsZ1lRMEtLdmhBSQ&wv=1&d=286732320",
      plusId: "101059874329008133023",
      reviewAuthor: "jackie chambers dahling",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_2M4_Gzmj75CvHw6K9nl0YLvKSw-v9iMElw4tqotA=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "2 months ago",
      reviewRating: 4,
      reviewText: "Very informative. Grateful",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1LVBIdEtREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICu-PHtKQ%7CCgwIg83hlgYQ0KKvhAI%7C?hl=en-US",
      rawDate: "07-20-2022 8:33 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1eUtmSUNnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1eUtmSUNnEi4KF0NJSE0wb2dLRUlDQWdJQ3V5S2ZJaWdFEhNDZ3dJeGMzY2xnWVEwTC1FblFJGi0KFkNJSE0wb2dLRUlDQWdJQ3V5S2ZJU2cSE0Nnd0l4YzNjbGdZUTBMLUVuUUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXhjM2NsZ1lRMEwtRW5RSQ&wv=1&d=286732320",
      plusId: "107805384120039018489",
      reviewAuthor: "Kim Hansen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu13N8o76bUSqoGub0LggbGLx0SlfES1R7WVznkW=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        'A unique tour of the Cincinnati historic OTR district. Our guide, Miranda, did an amazing job of providing a lot of interesting facts and historical details about the city, sites, and surrounding area as well as some "not so well known" stories. I would highly recommend both the tour and Miranda as your tour guide.',
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1eUtmSUNnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuyKfICg%7CCgwIxc3clgYQ0L-EnQI%7C?hl=en-US",
      rawDate: "07-19-2022 9:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1eU91NWtBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1eU91NWtBRRItChZDSUhNMG9nS0VJQ0FnSUN1eU91NVVBEhNDZ3dJM3NiY2xnWVEyTS1fLXdJGi4KF0NJSE0wb2dLRUlDQWdJQ3V5T3U1MEFFEhNDZ3dJM3NiY2xnWVEyTS1fLXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzc2JjbGdZUTJNLV8td0k&wv=1&d=286732320",
      plusId: "102506119968966441146",
      reviewAuthor: "Anna Hansen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9Lat27qYM58FvcM8zJvjMiXfTmHhft_QLF0GGQYg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My family and I were trying to decide whether we wanted to do the rock and roll hall of Fame or this. We chose this and it was definitely the right choice. The tour was incredibly interesting and informative and our tour guide, Miranda, made the entire tour even better. She was funny, cheerful, and made the tour anything but boring. I highly recommend this place if you like history or just want to see a bunch of cool parts of the city.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1eU91NWtBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuyOu5kAE%7CCgwI3sbclgYQ2M-_-wI%7C?hl=en-US",
      rawDate: "07-19-2022 9:34 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUN1a05QZC1nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUN1a05QZC1nRRIsChZDSUhNMG9nS0VJQ0FnSUN1a05QZEJnEhJDZ3NJaTd6WWxnWVFvS1hVRFEaLQoXQ0lITTBvZ0tFSUNBZ0lDdWtOUGRoZ0USEkNnc0lpN3pZbGdZUW9LWFVEUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJaTd6WWxnWVFvS1hVRFE&wv=1&d=286732320",
      plusId: "114565915613493781943",
      reviewAuthor: "Karen Turner",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3ex5zLNhOqhpUW6drmUpHMUcuili-MiuY6cem3=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We thoroughly enjoyed the Ultimate Underground Tour. Our tour guide, Zack, was very knowledgeable and a great story teller. I have lived in the area for over 30 years and learned so much about Over the Rhine that I never knew. I highly recommend this tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUN1a05QZC1nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICukNPd-gE%7CCgsIi7zYlgYQoKXUDQ%7C?hl=en-US",
      rawDate: "07-19-2022 2:59 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN1b0lHY1l3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUN1b0lHY1l3Ei0KF0NJSE0wb2dLRUlDQWdJQ3VvSUdjNHdFEhJDZ3NJODZ2V2xnWVE2TDdWSXcaLAoWQ0lITTBvZ0tFSUNBZ0lDdW9JR2NFdxISQ2dzSTg2dldsZ1lRNkw3Vkl3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k4NnZXbGdZUTZMN1ZJdw&wv=1&d=286732320",
      plusId: "106483189216985090822",
      reviewAuthor: "MATT MEYUNG",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3Q0iqTs-EUorPCDn2S8bMvOX8cSHcOpwV1jgbk=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Dean was an excellent tour guide. We had a great time!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1b0lHY1l3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICuoIGcYw%7CCgsI86vWlgYQ6L7VIw%7C?hl=en-US",
      rawDate: "07-18-2022 5:18 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdjRPak53EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdjRPak53Ei0KF0NJSE0wb2dLRUlDQWdJRE92NE9qdHdFEhJDZ3NJN3NIVGxnWVFvT0tKT3caLAoWQ0lITTBvZ0tFSUNBZ0lET3Y0T2pkdxISQ2dzSTdzSFRsZ1lRb09LSk93IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k3c0hUbGdZUW9PS0pPdw&wv=1&d=286732320",
      plusId: "117771668161271092088",
      reviewAuthor: "d Cooper-Strother",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_phZnhl6mckQcbnc447_YL_1steDuKX4QjkzBG=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Angela did a great job on the Cincinnati Wine and Dessert tour. I am in love with Holtman's German Chocolate Cake doughnut.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdjRPak53EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOv4OjNw%7CCgsI7sHTlgYQoOKJOw%7C?hl=en-US",
      rawDate: "07-18-2022 4:26 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPMzdXd19nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPMzdXd19nRRItChZDSUhNMG9nS0VJQ0FnSURPMzdXd0FREhNDZ3dJdnBIU2xnWVF1TDJOckFFGi4KF0NJSE0wb2dLRUlDQWdJRE8zN1d3Z1FFEhNDZ3dJdnBIU2xnWVF1TDJOckFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2cEhTbGdZUXVMMk5yQUU&wv=1&d=286732320",
      plusId: "117625808525990626760",
      reviewAuthor: "Amy Steinmetz",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9KngjNfTkxOJneJCzVJ8LlxN8XmljLdv3IMysRgQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We did a private hidden caverns tour for my friend\u2019s 40th birthday! It was a blast. Our tour guide Miranda was awesome and very knowledgeable. Can\u2019t wait book one of the other tours!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPMzdXd19nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO37Ww_gE%7CCgwIvpHSlgYQuL2NrAE%7C?hl=en-US",
      rawDate: "07-17-2022 10:10 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPNzVuaUNREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPNzVuaUNREi0KF0NJSE0wb2dLRUlDQWdJRE83NW5paVFFEhJDZ3NJcWJ2UWxnWVFzTVN5WXcaLAoWQ0lITTBvZ0tFSUNBZ0lETzc1bmlTURISQ2dzSXFidlFsZ1lRc01TeVl3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lxYnZRbGdZUXNNU3lZdw&wv=1&d=286732320",
      plusId: "107070221741419097848",
      reviewAuthor: "Rick Slusher",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu92TRFlqxj55iBj2cMOKCgXHtYzk6XV1KvbMrmz=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "George took us on the Ultimate Queen City Underground Tour. It was great; fun and informative!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPNzVuaUNREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO75niCQ%7CCgsIqbvQlgYQsMSyYw%7C?hl=en-US",
      rawDate: "07-17-2022 2:33 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPcjRmMFVnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPcjRmMFVnEi4KF0NJSE0wb2dLRUlDQWdJRE9yNGYwMGdFEhNDZ3dJcGZEUGxnWVE4SWZOdUFJGi0KFkNJSE0wb2dLRUlDQWdJRE9yNGYwTWcSE0Nnd0lwZkRQbGdZUThJZk51QUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXBmRFBsZ1lROElmTnVBSQ&wv=1&d=286732320",
      plusId: "103141761475250386841",
      reviewAuthor: "kennedy reid",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9hnGjv66rivzWN6NU2Ki-FdjZuj2mCvMLbJPgui40=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We had Barbara and she was absolutely amazing, highly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPcjRmMFVnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOr4f0Ug%7CCgwIpfDPlgYQ8IfNuAI%7C?hl=en-US",
      rawDate: "07-17-2022 11:53 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPajhYYl9BRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPajhYYl9BRRIqChZDSUhNMG9nS0VJQ0FnSURPajhYYkFnEhBDZ29JMGI3TmxnWVFrTGd4GisKF0NJSE0wb2dLRUlDQWdJRE9qOFhiZ2dFEhBDZ29JMGI3TmxnWVFrTGd4IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEENnb0kwYjdObGdZUWtMZ3g&wv=1&d=286732320",
      plusId: "114366732882235522768",
      reviewAuthor: "Thomas Owen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0VlcguedCGF2j8fudKy03r3_orGUaE6xg1ToBl=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 4,
      reviewText:
        "A fun day with Miranda. She is very knowledgeable and has the worst jokes that you just have to laugh at. Very bubbly and friendly. Well worth the money just make sure you ask for Miranda, she makes the tour what it is. FUN!!!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPajhYYl9BRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOj8Xb_AE%7CCgoI0b7NlgYQkLgx%7C?hl=en-US",
      rawDate: "07-17-2022 1:01 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPajZuVVZ3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPajZuVVZ3Ei4KF0NJSE0wb2dLRUlDQWdJRE9qNm5VMXdFEhNDZ3dJcWJUTmxnWVFnTEtWcUFFGi0KFkNJSE0wb2dLRUlDQWdJRE9qNm5VTncSE0Nnd0lxYlRObGdZUWdMS1ZxQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXFiVE5sZ1lRZ0xLVnFBRQ&wv=1&d=286732320",
      plusId: "107579352561036878975",
      reviewAuthor: "Ralph Newman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2QnVm1heLVW6ahlJEk6s5Hm14dYTc7fi3QAXL7=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We took the Ultimate Queen City Underground tour with George. He made the tour enjoyable, informative and entertaining. His enthusiasm was palpable even though it was the last tour of the day. Bravo, George!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPajZuVVZ3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOj6nUVw%7CCgwIqbTNlgYQgLKVqAE%7C?hl=en-US",
      rawDate: "07-17-2022 12:39 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPai15dmRnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPai15dmRnEi0KF0NJSE0wb2dLRUlDQWdJRE9qLXl2OWdFEhJDZ3NJcnZuTWxnWVF1TW10SXcaLAoWQ0lITTBvZ0tFSUNBZ0lET2oteXZEZxISQ2dzSXJ2bk1sZ1lRdU1tdEl3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lydm5NbGdZUXVNbXRJdw&wv=1&d=286732320",
      plusId: "112021087881152569890",
      reviewAuthor: "Helena Zavala",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_mvsX4imo4MjvoEpDvtWi1PrQCC_lMjj1fm1j2Lgg=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Great tour where we learned a lot about the city + beer! George was a great Tour Guide",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPai15dmRnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOj-yvdg%7CCgsIrvnMlgYQuMmtIw%7C?hl=en-US",
      rawDate: "07-16-2022 10:33 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPOTZfWFRnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPOTZfWFRnEi0KF0NJSE0wb2dLRUlDQWdJRE85Nl9YemdFEhJDZ3NJcmRYTWxnWVFvS3FITkEaLAoWQ0lITTBvZ0tFSUNBZ0lETzk2X1hMZxISQ2dzSXJkWE1sZ1lRb0txSE5BIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lyZFhNbGdZUW9LcUhOQQ&wv=1&d=286732320",
      plusId: "104109075511184914860",
      reviewAuthor: "Natalie Leslie",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8STxFcyuytW2TTA9IVo0jJMBj2U1w9VZcspPD4pA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My husband and I were in town for a conference bday decided to stay an extra few days to explore the city. Miranda was our tour guide and she was absolutely amazing! She was funny yet very informative which I\u2019m sure is harder than it looks. It was so much fun to be part of her tour group. She also gave great suggestions for restaurants and bars in the area. Very helpful! Oh and May I add that Miranda is a phenomenal photographer!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPOTZfWFRnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO96_XTg%7CCgsIrdXMlgYQoKqHNA%7C?hl=en-US",
      rawDate: "07-16-2022 9:16 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPOTYzNzlnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPOTYzNzlnRRItChZDSUhNMG9nS0VJQ0FnSURPOTYzN0RnEhNDZ3dJejdmTWxnWVEyTi11OFFJGi4KF0NJSE0wb2dLRUlDQWdJRE85NjM3amdFEhNDZ3dJejdmTWxnWVEyTi11OFFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l6N2ZNbGdZUTJOLXU4UUk&wv=1&d=286732320",
      plusId: "101641904074122072774",
      reviewAuthor: "Zachary Wikette",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3r7kdHuYYtiQgYkswWpMXzwQBqJple5Jv28IJj=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Miranda was amazing! Very educational and fun!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPOTYzNzlnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO96379gE%7CCgwIz7fMlgYQ2N-u8QI%7C?hl=en-US",
      rawDate: "07-16-2022 8:13 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPOS1XQTNBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPOS1XQTNBRRItChZDSUhNMG9nS0VJQ0FnSURPOS1XQVBBEhNDZ3dJbnJETWxnWVFrTjdOcEFJGi4KF0NJSE0wb2dLRUlDQWdJRE85LVdBdkFFEhNDZ3dJbnJETWxnWVFrTjdOcEFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0luckRNbGdZUWtON05wQUk&wv=1&d=286732320",
      plusId: "111053761882485977328",
      reviewAuthor: "Tyler Wichmann",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3s3BeIKSm4VttDesfXkFpHgbCYKqv9alRtDHXN=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Do yourself a favor and take this tour. Being native to the area we did this on a whim and im super glad we did. George was very informative and made the tour that much better!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPOS1XQTNBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO9-WA3AE%7CCgwInrDMlgYQkN7NpAI%7C?hl=en-US",
      rawDate: "07-16-2022 7:57 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPOTZIdExBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPOTZIdExBEi4KF0NJSE0wb2dLRUlDQWdJRE85Nkh0ckFFEhNDZ3dJaXFITWxnWVFvS09VNVFFGi0KFkNJSE0wb2dLRUlDQWdJRE85Nkh0YkESE0Nnd0lpcUhNbGdZUW9LT1U1UUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWlxSE1sZ1lRb0tPVTVRRQ&wv=1&d=286732320",
      plusId: "114319893714714365677",
      reviewAuthor: "Heather Jerolaman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8gFYebFm32gaillCTBZlZfdo6Yb-f2jyfqdnT2zA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My husband and I took out of town relatives on the Ultimate Queen City Underground Tour and it was fantastic! Our Tour guide, Miranda, was wonderful and so fun to take the tour with. She delivered information about OTR with finesse and humor, which made all of us smile. Our relatives learned new things about Cincy and can't wait to return for another tour, hopefully, with Miranda as our guide.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPOTZIdExBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO96HtLA%7CCgwIiqHMlgYQoKOU5QE%7C?hl=en-US",
      rawDate: "07-16-2022 7:24 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPOTliYlpBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPOTliYlpBEisKF0NJSE0wb2dLRUlDQWdJRE85OWJiNUFFEhBDZ29JZzVYTWxnWVFpS3RTGioKFkNJSE0wb2dLRUlDQWdJRE85OWJiRkESEENnb0lnNVhNbGdZUWlLdFMiEgmhbouG_bNBiBFQyZK--oXo-ioQQ2dvSWc1WE1sZ1lRaUt0Uw&wv=1&d=286732320",
      plusId: "111903560275665511407",
      reviewAuthor: "Katie Wichmann",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9b4cxDftunbekdXu2fX9rKd8BgV5Rcitoo0RyN=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "This tour was even better than I expected. George was fantastic and was very knowledgeable. He answered all the questions we asked and was a great historian! Thank you!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPOTliYlpBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO99bbZA%7CCgoIg5XMlgYQiKtS%7C?hl=en-US",
      rawDate: "07-16-2022 6:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaThhR2lRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaThhR2lRRRItChZDSUhNMG9nS0VJQ0FnSURPaThhR1NREhNDZ3dJc0txOWxnWVE4TEtqM1FJGi4KF0NJSE0wb2dLRUlDQWdJRE9pOGFHeVFFEhNDZ3dJc0txOWxnWVE4TEtqM1FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lzS3E5bGdZUThMS2ozUUk&wv=1&d=286732320",
      plusId: "109609848708168989966",
      reviewAuthor: "diabeticcamel",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8qwcfOh_fgDJJkG_5d_xWQLFUY5EmAS3WLCmceLA=s120-c-c0x00000000-cc-rp-mo-ba5-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My wife and I had a wonderful time on the ultimate queen city underground tour. Our guide, Zack, had a wonderful temperament and was very knowledgeable about every landmark of the tour. Though a large part of the tour related to alcohol, even my non-drinking wife and my own non-drinking self were thoroughly entertained and informed as he taught us about how it wove into and shaped the history of the city. The church and crypt visits were intensely interesting and also a trove of fantastic picture taking opportunities. It was a two hour tour, and it flew by.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaThhR2lRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOi8aGiQE%7CCgwIsKq9lgYQ8LKj3QI%7C?hl=en-US",
      rawDate: "07-13-2022 11:28 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPaThxd1p3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPaThxd1p3Ei0KF0NJSE0wb2dLRUlDQWdJRE9pOHF3NXdFEhJDZ3NJelotOWxnWVF5TmpnR1EaLAoWQ0lITTBvZ0tFSUNBZ0lET2k4cXdGdxISQ2dzSXpaLTlsZ1lReU5qZ0dRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l1cUM5bGdZUXFOaTdGQQ&wv=1&d=286732320",
      plusId: "109564088168482412828",
      reviewAuthor: "Amanda Ferguson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu96r-Ocwxh3hxuTfMxax04Vk-uJCbpYqDk16Xt0l0I=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "This was such a neat experience! Very unique will make quite the memory. Loved learning the history, our guide Zack was absolutely fantastic he made it alot of fun! Highly recommend!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPaThxd1p3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOi8qwZw%7CCgsIuqC9lgYQqNi7FA%7C?hl=en-US",
      rawDate: "07-13-2022 11:07 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPOC12Qi1nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPOC12Qi1nRRItChZDSUhNMG9nS0VJQ0FnSURPOC12QkJnEhNDZ3dJck4yOGxnWVEwS0xubXdJGi4KF0NJSE0wb2dLRUlDQWdJRE84LXZCaGdFEhNDZ3dJck4yOGxnWVEwS0xubXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lyTjI4bGdZUTBLTG5td0k&wv=1&d=286732320",
      plusId: "118345205661121221235",
      reviewAuthor: "Mike Heinz",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3TvTV3NOcqHcC8-PdC1tHwd5M1Cz5GLQE6CLDM=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "I thought the tour was great and tour guide Miranda was excellent.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPOC12Qi1nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO8-vB-gE%7CCgwIrN28lgYQ0KLnmwI%7C?hl=en-US",
      rawDate: "07-13-2022 8:44 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdy1tb1hnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdy1tb1hnEi4KF0NJSE0wb2dLRUlDQWdJRE93LW1vM2dFEhNDZ3dJczRXM2xnWVFrTmpHcEFNGi0KFkNJSE0wb2dLRUlDQWdJRE93LW1vUGcSE0Nnd0lzNFczbGdZUWtOakdwQU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXM0VzNsZ1lRa05qR3BBTQ&wv=1&d=286732320",
      plusId: "109615301774819261685",
      reviewAuthor: "Christina McEntire",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2_qWiw0suUQOKDqF2U7b2pbmCZI2iIn9aFn58B=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "George was an awesome tour guide! We will be back for the haunted tours soon!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdy1tb1hnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOw-moXg%7CCgwIs4W3lgYQkNjGpAM%7C?hl=en-US",
      rawDate: "07-12-2022 6:51 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPdGIyRG5BRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPdGIyRG5BRRItChZDSUhNMG9nS0VJQ0FnSURPdGIyRFhBEhNDZ3dJcXN1dGxnWVF5TnYwandFGi4KF0NJSE0wb2dLRUlDQWdJRE90YjJEM0FFEhNDZ3dJcXN1dGxnWVF5TnYwandFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lxc3V0bGdZUXlOdjBqd0U&wv=1&d=286732320",
      plusId: "108864094516391763972",
      reviewAuthor: "Nancy Menapace",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu16gHjcAanLhYY7CnstqhH9_C2zYg0wP_z22gPQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 3,
      reviewText:
        "Just ok. History was interesting but we went to several places right by where my son lives so it\u2019s wasn\u2019t great for us. I thought by the title that we would be exploring underground. Tour was 1 hour 45 mins. For $39 a person - way overpriced.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPdGIyRG5BRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtb2DnAE%7CCgwIqsutlgYQyNv0jwE%7C?hl=en-US",
      rawDate: "07-10-2022 11:50 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdGNhSUZBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdGNhSUZBEi4KF0NJSE0wb2dLRUlDQWdJRE90Y2FJbEFFEhNDZ3dJeHBLdGxnWVFnTjZaZ0FNGi0KFkNJSE0wb2dLRUlDQWdJRE90Y2FJVkESE0Nnd0l4cEt0bGdZUWdONlpnQU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXhwS3RsZ1lRZ042WmdBTQ&wv=1&d=286732320",
      plusId: "113485080336893607712",
      reviewAuthor: "John Siemer",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2z0sQEjbBxJW39JBnu3we0nUU11tnl2Eu8SJ97=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We went on the Newport Gangster Tour. Kevin was our guide. He is very knowledgeable of Newport\u2019s history, and made the rainy day tour an enjoyable and fun experience. The tour included visits to some of the well-known and lesser-known buildings and sites in Newport, KY, that have history in Newport\u2019s seedy past. The time period covered is primarily between the 1930s the 1960s. I knew about some of the things that were toured, but had never heard those finer details. It was interesting to learn about northern Kentucky\u2019s link to Las Vegas.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdGNhSUZBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtcaIFA%7CCgwIxpKtlgYQgN6ZgAM%7C?hl=en-US",
      rawDate: "07-10-2022 9:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPdGZMMHNRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPdGZMMHNRRRItChZDSUhNMG9nS0VJQ0FnSURPdGZMMGNREhNDZ3dJMVlldGxnWVFvTkhsc1FNGi4KF0NJSE0wb2dLRUlDQWdJRE90ZkwwOFFFEhNDZ3dJMVlldGxnWVFvTkhsc1FNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kxWWV0bGdZUW9OSGxzUU0&wv=1&d=286732320",
      plusId: "117331864463890704010",
      reviewAuthor: "Andrea Grace",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_OaXWsWifuFO2nIXXj7yGuoz5pEJyXT3mzc9rF=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Our tour of Cincinnati was so enjoyable with Peggy! I learned a lot of fun facts and history that I would not have known otherwise. She was so enthusiastic and knowledgeable - I would definitely recommend her and I look forward to taking the other tours offered in the future when visiting Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPdGZMMHNRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtfL0sQE%7CCgwI1YetlgYQoNHlsQM%7C?hl=en-US",
      rawDate: "07-10-2022 9:25 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdFpLWU93EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdFpLWU93Gi0KF0NJSE0wb2dLRUlDQWdJRE90WktZdXdFEhJDZ3NJdVlTdGxnWVEtSjNJQ2ciEgmhbouG_bNBiBFQyZK--oXo-ioSQ2dzSXVZU3RsZ1lRLUozSUNn&wv=1&d=286732320",
      plusId: "107257015860516747494",
      reviewAuthor: "Nick Woodall",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1w7Yjr6UpTe66lrDmNCUh3K8wIyLoRn2kLCEqZ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdFpLWU93EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtZKYOw%7CCgsIuYStlgYQ-J3ICg%7C?hl=en-US",
      rawDate: "07-10-2022 9:18 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPbGVDMFZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPbGVDMFZREi0KF0NJSE0wb2dLRUlDQWdJRE9sZUMwMVFFEhJDZ3NJdUpTcmxnWVFrSkxaWmcaLAoWQ0lITTBvZ0tFSUNBZ0lET2xlQzBOURISQ2dzSXVKU3JsZ1lRa0pMWlpnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l1SlNybGdZUWtKTFpaZw&wv=1&d=286732320",
      plusId: "101864722053795144864",
      reviewAuthor: "Becky Spencer",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-CjrJWMd_AEhVH5teflB5lrDUXq13U76RqdmxSSg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We went on the ultimate queen underground your and it was so much fun! We learned a lot and saw a lot of cool things and our guide George was awesome!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPbGVDMFZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOleC0VQ%7CCgsIuJSrlgYQkJLZZg%7C?hl=en-US",
      rawDate: "07-10-2022 12:46 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPbFlDa3ZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPbFlDa3ZBRRIsChZDSUhNMG9nS0VJQ0FnSURPbFlDa2ZBEhJDZ3NJNUpLcmxnWVEySWpnY0EaLQoXQ0lITTBvZ0tFSUNBZ0lET2xZQ2tfQUUSEkNnc0k1SktybGdZUTJJamdjQSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJNUpLcmxnWVEySWpnY0E&wv=1&d=286732320",
      plusId: "101770743713780996880",
      reviewAuthor: "Carlin Stamm",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0zJpgZyrzS7KpN4QTU5biNo4FvKEo2YXEhIoTM=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "George was informative and entertaining. It is always nice to see someone who seems to really enjoy their work.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPbFlDa3ZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOlYCkvAE%7CCgsI5JKrlgYQ2IjgcA%7C?hl=en-US",
      rawDate: "07-10-2022 12:43 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPeGVyOXFBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPeGVyOXFBRRosChZDSUhNMG9nS0VJQ0FnSURPeGVyOWFBEhJDZ3NJaWUyb2xnWVE2Tkg1QnciEgmhbouG_bNBiBFQyZK--oXo-ioSQ2dzSWllMm9sZ1lRNk5INUJ3&wv=1&d=286732320",
      plusId: "115121792784042645770",
      reviewAuthor: "Peter Garcia",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3HeHhD7o4BxC4q8UQN-n6W9Q9UTJ3Lhhfk-hju=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPeGVyOXFBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOxer9qAE%7CCgsIie2olgYQ6NH5Bw%7C?hl=en-US",
      rawDate: "07-10-2022 2:16 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPeGZMc2F3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPeGZMc2F3Ei4KF0NJSE0wb2dLRUlDQWdJRE94ZkxzNndFEhNDZ3dJa09lb2xnWVEyTGphd0FFGi0KFkNJSE0wb2dLRUlDQWdJRE94ZkxzR3cSE0Nnd0lrT2VvbGdZUTJMamF3QUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWtPZW9sZ1lRMkxqYXdBRQ&wv=1&d=286732320",
      plusId: "117387797007157661489",
      reviewAuthor: "Melissa Aull",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1mmWTcQFL04itqfvXpawJhR5kepPW6pdN7HJhJ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "What a great tour of Cincinnati. Peggy is such an enthusiastic and knowledgeable tour guide. I highly recommend the Underground Tour with her.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPeGZMc2F3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOxfLsaw%7CCgwIkOeolgYQ2LjawAE%7C?hl=en-US",
      rawDate: "07-10-2022 2:04 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPaGJmOEl3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPaGJmOEl3Ei0KF0NJSE0wb2dLRUlDQWdJRE9oYmY4b3dFEhJDZ3NJX0tlb2xnWVFnUGZpWFEaLAoWQ0lITTBvZ0tFSUNBZ0lET2hiZjhZdxISQ2dzSV9LZW9sZ1lRZ1BmaVhRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lfS2VvbGdZUWdQZmlYUQ&wv=1&d=286732320",
      plusId: "102788570457507733182",
      reviewAuthor: "Angie G.",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9Kxwp1mZ_Zvgv_nqdSkcOWMWXepJp7A8V0e99l6A=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Peggy was an awesome tour guide and we learned so much about Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPaGJmOEl3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhbf8Iw%7CCgsI_KeolgYQgPfiXQ%7C?hl=en-US",
      rawDate: "07-09-2022 11:49 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaGRQV29RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaGRQV29RRRIsChZDSUhNMG9nS0VJQ0FnSURPaGRQV1lREhJDZ3NJalpLb2xnWVFvUGpIRmcaLQoXQ0lITTBvZ0tFSUNBZ0lET2hkUFc0UUUSEkNnc0lqWktvbGdZUW9QakhGZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJalpLb2xnWVFvUGpIRmc&wv=1&d=286732320",
      plusId: "107049412102366635991",
      reviewAuthor: "Beth Howe",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9GyINqzijfTepEuUuZ8TR061JiYnGTTBU12yrjKA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "George was a great guide! Made the tour interesting with his stories and facts about the history of breweries in Cincinnati. From past to current, he knew it all!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaGRQV29RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhdPWoQE%7CCgsIjZKolgYQoPjHFg%7C?hl=en-US",
      rawDate: "07-09-2022 11:02 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaGZiNTlnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaGZiNTlnRRItChZDSUhNMG9nS0VJQ0FnSURPaGZiNURnEhNDZ3dJME42bmxnWVFxUEtZLXdFGi4KF0NJSE0wb2dLRUlDQWdJRE9oZmI1amdFEhNDZ3dJME42bmxnWVFxUEtZLXdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kwTjZubGdZUXFQS1ktd0U&wv=1&d=286732320",
      plusId: "102197799839225234805",
      reviewAuthor: "Gillian Kuszewski",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu36lTNu_zU_jzolsHILc_hbU-Y4wi4NZ7o5qICO=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was a wonderful guide. She was knowledgeable, courteous, enthusiastic and kind. The caves were so cool! The beer was great. You should do this tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaGZiNTlnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhfb59gE%7CCgwI0N6nlgYQqPKY-wE%7C?hl=en-US",
      rawDate: "07-09-2022 9:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPaGJhUEh3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPaGJhUEh3Ei0KF0NJSE0wb2dLRUlDQWdJRE9oYmFQbndFEhJDZ3NJOU4ybmxnWVFnUGlBUmcaLAoWQ0lITTBvZ0tFSUNBZ0lET2hiYVBYdxISQ2dzSTlOMm5sZ1lRZ1BpQVJnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0k5TjJubGdZUWdQaUFSZw&wv=1&d=286732320",
      plusId: "103089039285446450298",
      reviewAuthor: "Alysson Olsen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9iE60I1pkCwuiONgEhvHa_UQZKu-5j0YTqDTON=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was so much fun! You should do this tour. A little comedy, a lot of education, and spooky tunnels!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPaGJhUEh3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhbaPHw%7CCgsI9N2nlgYQgPiARg%7C?hl=en-US",
      rawDate: "07-09-2022 9:11 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaGV6eTlnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaGV6eTlnRRItChZDSUhNMG9nS0VJQ0FnSURPaGV6eURnEhNDZ3dJeE1LbmxnWVE2S2Y2c2dFGi4KF0NJSE0wb2dLRUlDQWdJRE9oZXp5amdFEhNDZ3dJeE1LbmxnWVE2S2Y2c2dFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l4TUtubGdZUTZLZjZzZ0U&wv=1&d=286732320",
      plusId: "103156148041120351204",
      reviewAuthor: "Alicia Stayonovich",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu23GIFgP_Z10fEw18EP49klcewKU5lSDvMq5o4a=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Such a great tour!! I\u2019ve lived in the Cincinnati area for several years (to include OTR) and took this tour with an out of town visitor and we both enjoyed it immensely! It was so fascinating learning so much about Cincinnati that I never knew and seeing places I\u2019d never normally get to see.\n\nGeorge (our tour guide) was phenomenal! Incredibly knowledgeable and entertaining. For 2 hours he held the attention of every person on our tour (which is hard to do nowadays!).",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaGV6eTlnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhezy9gE%7CCgwIxMKnlgYQ6Kf6sgE%7C?hl=en-US",
      rawDate: "07-09-2022 8:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPLWZQZHB3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPLWZQZHB3RRIsChZDSUhNMG9nS0VJQ0FnSURPLWZQZFp3EhJDZ3NJX0pLbmxnWVE4Tl9EZlEaLQoXQ0lITTBvZ0tFSUNBZ0lETy1mUGQ1d0USEkNnc0lfSktubGdZUThOX0RmUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJX0pLbmxnWVE4Tl9EZlE&wv=1&d=286732320",
      plusId: "113264875428062973162",
      reviewAuthor: "Jessica Lanz",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-e0UnlLOK1-NEXmNYGaqJcI5fBXVJqQwy12Mwy=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Peggy was a fantastic guide! She gave lots of fun facts and really knew her stuff. I can\u2019t wait to come back to Cincinnati and take another tour!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPLWZQZHB3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO-fPdpwE%7CCgsI_JKnlgYQ8N_DfQ%7C?hl=en-US",
      rawDate: "07-09-2022 6:31 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPNll6OVdnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPNll6OVdnEi0KF0NJSE0wb2dLRUlDQWdJRE82WXo5MmdFEhJDZ3NJdGRHaWxnWVE4Smp1UHcaLAoWQ0lITTBvZ0tFSUNBZ0lETzZZejlPZxISQ2dzSXRkR2lsZ1lROEpqdVB3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l0ZEdpbGdZUThKanVQdw&wv=1&d=286732320",
      plusId: "104434697483831100803",
      reviewAuthor: "Kyle Kirkbride",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3bdtOufXtpx0EWGL8B1BMveN9m4NonzjXmRcv3=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was fantastic at explaining the history of the breweries in cincinatti. She was very knowledgeable and an excellent guide. Couldn't reccomend more!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPNll6OVdnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO6Yz9Wg%7CCgsItdGilgYQ8JjuPw%7C?hl=en-US",
      rawDate: "07-08-2022 9:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPeWZlclJBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPeWZlclJBEi4KF0NJSE0wb2dLRUlDQWdJRE95ZmVyeEFFEhNDZ3dJNWJtaGxnWVFnUGFUckFJGi0KFkNJSE0wb2dLRUlDQWdJRE95ZmVySkESE0Nnd0k1Ym1obGdZUWdQYVRyQUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STVibWhsZ1lRZ1BhVHJBSQ&wv=1&d=286732320",
      plusId: "101918710949508134504",
      reviewAuthor: "Jason Craft",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8EKyODA6t_GVsn0kDQbVyWpfBG8k0vHPOIDmz4ZQ=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Zach led our Cincinnati Under the Underground tour earlier this week. It was just myself, my wife, and 2 kids. Zach mixed fun and history. Includes a fair amount of city walking between sites and some very skinny stairs which was no problem for us. We will take the 18th amendment mob tour next time in town. Thanks!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPeWZlclJBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOyferRA%7CCgwI5bmhlgYQgPaTrAI%7C?hl=en-US",
      rawDate: "07-08-2022 4:35 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPeVlhQ3JBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPeVlhQ3JBRRItChZDSUhNMG9nS0VJQ0FnSURPeVlhQ2JBEhNDZ3dJN3Y2Z2xnWVFrTmZfbndNGi4KF0NJSE0wb2dLRUlDQWdJRE95WWFDN0FFEhNDZ3dJN3Y2Z2xnWVFrTmZfbndNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k3djZnbGdZUWtOZl9ud00&wv=1&d=286732320",
      plusId: "106734352087737766390",
      reviewAuthor: "Margaret Fouts",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0JQ9d-jhQ_Ucd7LH-tjXlAWJ5mSkoJtnwrqO7u=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        'My husband, sister,-in-law and I chose the Ultimate Queen City Underground tour. Great tour! Our guide, Miranda, was sensational!. Her commentary was entertaining, informative, and the whole group seemed to enjoy her sense of humor. Mike says, "She hit it out of the park!!" Highly recommend!',
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPeVlhQ3JBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOyYaCrAE%7CCgwI7v6glgYQkNf_nwM%7C?hl=en-US",
      rawDate: "07-08-2022 2:30 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPc2JEa29nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPc2JEa29nRRItChZDSUhNMG9nS0VJQ0FnSURPc2JEa1lnEhNDZ3dJMlpLZGxnWVF1SU93aFFNGi4KF0NJSE0wb2dLRUlDQWdJRE9zYkRrNGdFEhNDZ3dJMlpLZGxnWVF1SU93aFFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kyWktkbGdZUXVJT3doUU0&wv=1&d=286732320",
      plusId: "110389659808819001691",
      reviewAuthor: "Sergio Zapata",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-7yKx5NMUTt_OiHS7shXRRkcLtRUhpXZKOf-SK1g=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "This is my 2nd tour with them (1st one was the haunted tour, highly recommended!), and this time was cincinnati underground and our guide Miranda was great! She\u2019s charming, charismatic, patient with kids, and very knowledgeable in Cincinnati/OTR history. Would recommend 100%.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPc2JEa29nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOsbDkogE%7CCgwI2ZKdlgYQuIOwhQM%7C?hl=en-US",
      rawDate: "07-07-2022 9:00 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPMGZ2ekxREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPMGZ2ekxREi4KF0NJSE0wb2dLRUlDQWdJRE8wZnZ6clFFEhNDZ3dJbVB5Y2xnWVE2Smp0dUFFGi0KFkNJSE0wb2dLRUlDQWdJRE8wZnZ6YlESE0Nnd0ltUHljbGdZUTZKanR1QUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW1QeWNsZ1lRNkpqdHVBRQ&wv=1&d=286732320",
      plusId: "103033946057781961385",
      reviewAuthor: "Katrina Tiedeman",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2PdrQUGCpICxospxv85_w2ECRzgVEl-vRyXXDm=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Dean was our tour guide on the Hidden Caverns tour and was super friendly and very knowledgeable! We had a great time!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPMGZ2ekxREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO0fvzLQ%7CCgwImPyclgYQ6JjtuAE%7C?hl=en-US",
      rawDate: "07-07-2022 8:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPa2Qyc2ZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPa2Qyc2ZREi4KF0NJSE0wb2dLRUlDQWdJRE9rZDJzX1FFEhNDZ3dJcWYyYmxnWVEySmJWZ0FFGi0KFkNJSE0wb2dLRUlDQWdJRE9rZDJzQXcSE0Nnd0lxZjJibGdZUTJKYlZnQUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXFmMmJsZ1lRMkpiVmdBRQ&wv=1&d=286732320",
      plusId: "102519665881361927503",
      reviewAuthor: "Caitlin Powers",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3QMnpdVqyIEjjqO26jKIfJgLSVlrsrBOb9PzcT=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "The Hidden Caverns tour with Miranda was amazing! She kept us laughing the entire time! She was very knowledgeable about the caverns and Cinncinati history. Highly recommend.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPa2Qyc2ZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOkd2sfQ%7CCgwIqf2blgYQ2JbVgAE%7C?hl=en-US",
      rawDate: "07-07-2022 3:41 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPd2RpS2NREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPd2RpS2NREi4KF0NJSE0wb2dLRUlDQWdJRE93ZGlLOFFFEhNDZ3dJdzdhWWxnWVF5SVBocHdNGi0KFkNJSE0wb2dLRUlDQWdJRE93ZGlLQ1ESE0Nnd0l3N2FZbGdZUXlJUGhwd00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXc3YVlsZ1lReUlQaHB3TQ&wv=1&d=286732320",
      plusId: "107892596501286717792",
      reviewAuthor: "Laura Smith",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-0C3UEgSpwVtNJNEy3mPoRoPExGxGDzrRd5uMEIA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We had a great time on this tour! I\u2019m a native Cincinnatian and Dean taught me things about this city that I never knew. Like what, you may ask? Take the Underground Cincinnati tour and find out!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPd2RpS2NREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOwdiKcQ%7CCgwIw7aYlgYQyIPhpwM%7C?hl=en-US",
      rawDate: "07-06-2022 11:31 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPX3MtaWl3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPX3MtaWl3RRItChZDSUhNMG9nS0VJQ0FnSURPX3MtaVN3EhNDZ3dJdUtXWGxnWVEyS0ROeHdFGi4KF0NJSE0wb2dLRUlDQWdJRE9fcy1peXdFEhNDZ3dJdUtXWGxnWVEyS0ROeHdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l1S1dYbGdZUTJLRE54d0U&wv=1&d=286732320",
      plusId: "104883770133780997027",
      reviewAuthor: "Karyn Stenzler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2SMLnvJktuthvE0qiVbsf83iF8xO1rV-bdtSw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We just finished a tour with Miranda! She was such a fabulous tour guide with a lot of knowledge and most of all she was funny! Highly recommend this your group!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPX3MtaWl3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO_s-iiwE%7CCgwIuKWXlgYQ2KDNxwE%7C?hl=en-US",
      rawDate: "07-06-2022 6:21 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPX3ZlRmR3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPX3ZlRmR3Ei4KF0NJSE0wb2dLRUlDQWdJRE9fdmVGOXdFEhNDZ3dJbmFTWGxnWVE2UHpDMXdFGi0KFkNJSE0wb2dLRUlDQWdJRE9fdmVGRHcSE0Nnd0luYVNYbGdZUTZQekMxd0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SW5hU1hsZ1lRNlB6QzF3RQ&wv=1&d=286732320",
      plusId: "118072247848794964746",
      reviewAuthor: "erin s",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0-xcYodTbbecvN4NXmeGVnnPFAfwLnAWRhtj_q=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We had the Queen City Underground tour with Miranda and it was EXCELLENT! She shared her love of the city with so much knowledge. She had great recommendations for other thing to do for people from out-of-town. She was very personable and kept everyone entertained. Thank you!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPX3ZlRmR3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO_veFdw%7CCgwInaSXlgYQ6PzC1wE%7C?hl=en-US",
      rawDate: "07-06-2022 6:19 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPX3R2NmJREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPX3R2NmJREi4KF0NJSE0wb2dLRUlDQWdJRE9fdHY2N1FFEhNDZ3dJMXB1WGxnWVFxSXZveWdNGi0KFkNJSE0wb2dLRUlDQWdJRE9fdHY2SFESE0Nnd0kxcHVYbGdZUXFJdm95Z00iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STFwdVhsZ1lRcUl2b3lnTQ&wv=1&d=286732320",
      plusId: "105761581107098112313",
      reviewAuthor: "Cheryl Mostin",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9j0GVEKDiqI_YViZRLU1Aum1MtcCgNDsB_OtMA7w=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        'Great tour! Melinda, "the blond chick with the Australian accent", was extremely entertaining, friendly, and informative. Thank you!',
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPX3R2NmJREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO_tv6bQ%7CCgwI1puXlgYQqIvoygM%7C?hl=en-US",
      rawDate: "07-06-2022 6:00 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPX3RHY1VnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPX3RHY1VnEi0KF0NJSE0wb2dLRUlDQWdJRE9fdEdjMGdFEhJDZ3NJbnZ5V2xnWVFrT1dyZWcaLAoWQ0lITTBvZ0tFSUNBZ0lET190R2NNZxISQ2dzSW52eVdsZ1lRa09XcmVnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0ludnlXbGdZUWtPV3JlZw&wv=1&d=286732320",
      plusId: "101884856498130384648",
      reviewAuthor: "Danielle Johnson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9yjb4obG46pe2VNvrmoRDnOOcrhjw2rVcvlwFZiA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Maranda was amazing, funny, kind and knowledgeable! Loved learning so much about Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPX3RHY1VnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO_tGcUg%7CCgsInvyWlgYQkOWreg%7C?hl=en-US",
      rawDate: "07-06-2022 4:53 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPcnFyUWd3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPcnFyUWd3RRItChZDSUhNMG9nS0VJQ0FnSURPcnFyUVF3EhNDZ3dJN19LUmxnWVFzSnpNd0FFGi4KF0NJSE0wb2dLRUlDQWdJRE9ycXJRd3dFEhNDZ3dJN19LUmxnWVFzSnpNd0FFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k3X0tSbGdZUXNKek13QUU&wv=1&d=286732320",
      plusId: "104141695988853973266",
      reviewAuthor: "Breanne Daugherty",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3ywFmXZn86KL_Zjj0ESZxS4U4dIxsRmYZTB6NN=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Excellent!! We had so much fun. Miranda our tour guide was amazing \ud83d\udc4f",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPcnFyUWd3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOrqrQgwE%7CCgwI7_KRlgYQsJzMwAE%7C?hl=en-US",
      rawDate: "07-05-2022 5:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaHVIM2l3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaHVIM2l3RRItChZDSUhNMG9nS0VJQ0FnSURPaHVIM1N3EhNDZ3dJMklhSWxnWVF1TmlLcHdJGi4KF0NJSE0wb2dLRUlDQWdJRE9odUgzeXdFEhNDZ3dJMklhSWxnWVF1TmlLcHdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kySWFJbGdZUXVOaUtwd0k&wv=1&d=286732320",
      plusId: "109838046039700164509",
      reviewAuthor: "James Melone",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8bfX3_V-9l59LItFzAfLF9ZyT5P7_ghPUP5EdI=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Laura was a fantastic tour guide! Enjoyed seeing the hidden history of this great city.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaHVIM2l3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhuH3iwE%7CCgwI2IaIlgYQuNiKpwI%7C?hl=en-US",
      rawDate: "07-03-2022 9:00 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPaHNyNEdnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPaHNyNEdnEi0KF0NJSE0wb2dLRUlDQWdJRE9oc3I0bWdFEhJDZ3NJby1xSGxnWVFxTy0wZFEaLAoWQ0lITTBvZ0tFSUNBZ0lET2hzcjRXZxISQ2dzSW8tcUhsZ1lRcU8tMGRRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lvLXFIbGdZUXFPLTBkUQ&wv=1&d=286732320",
      plusId: "105306461808455728066",
      reviewAuthor: "Mike Hood",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8phP7TEVXc4WkN_lXPiwXg2ga5CbFUxv2FSqpFGg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Meranda makes the tour! Great history and interesting 90 minutes makes it worth the money. Our guide, Meranda, obviously has a passion for the city and the OTR history behind it. We had such a great time and had great conversations with the other guests on the tour afterwards while sampling some great beer at Northern Row.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPaHNyNEdnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhsr4Gg%7CCgsIo-qHlgYQqO-0dQ%7C?hl=en-US",
      rawDate: "07-03-2022 7:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPaHZ6bXh3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPaHZ6bXh3RRItChZDSUhNMG9nS0VJQ0FnSURPaHZ6bUp3EhNDZ3dJeWVtSGxnWVE2S09haWdJGi4KF0NJSE0wb2dLRUlDQWdJRE9odnptcHdFEhNDZ3dJcnVDSGxnWVE0TkNucVFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l5ZW1IbGdZUTZLT2FpZ0k&wv=1&d=286732320",
      plusId: "110246954413167086483",
      reviewAuthor: "Monarch Automation",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3uUto9ODVz137Wgi8dFUVQaIMudjBvRiEeGBoZ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Meranda makes the tour! Great history and interesting 90 minutes makes it worth the money. Our guide, Meranda, obviously has a passion for the city and the OTR history behind it. We had such a great time and had great conversations with the other guests on the tour afterwards while sampling some great beer at Northern Row.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPaHZ6bXh3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOhvzmxwE%7CCgwIyemHlgYQ6KOaigI%7C?hl=en-US",
      rawDate: "07-03-2022 7:58 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPNnA3bHN3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPNnA3bHN3RRotChZDSUhNMG9nS0VJQ0FnSURPNnA3bGN3EhNDZ3dJOGNhQ2xnWVFvSjZPclFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k4Y2FDbGdZUW9KNk9yUUk&wv=1&d=286732320",
      plusId: "111344642647648686431",
      reviewAuthor: "Derrick Marshall",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-bBHz1Tu10nnZT7P48dUKtdnWovLAOUkEFNgFO=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPNnA3bHN3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO6p7lswE%7CCgwI8caClgYQoJ6OrQI%7C?hl=en-US",
      rawDate: "07-02-2022 7:58 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPcXAtMGR3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPcXAtMGR3Ei4KF0NJSE0wb2dLRUlDQWdJRE9xcC0wOXdFEhNDZ3dJdm9hQ2xnWVE4SzJQZ3dJGi0KFkNJSE0wb2dLRUlDQWdJRE9xcC0wRHcSE0Nnd0l2b2FDbGdZUThLMlBnd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXZvYUNsZ1lROEsyUGd3SQ&wv=1&d=286732320",
      plusId: "109652065517436754148",
      reviewAuthor: "Jacqueline Gauronskas",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu22kzalFw2pmwTblO7R5LxPfAJ_VN-trrC2s2-s=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "This was the perfect tour to do (especially in the summer) once you\u2019ve gotten a bit hot you walk down to caverns to cool off and then back for beer. Miranda was great at being sure we heard her at all times and if we had any questions!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPcXAtMGR3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOqp-0dw%7CCgwIvoaClgYQ8K2PgwI%7C?hl=en-US",
      rawDate: "07-02-2022 5:41 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPcXUtVWhnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPcXUtVWhnRRIsChZDSUhNMG9nS0VJQ0FnSURPcXUtVVJnEhJDZ3NJdUlXQ2xnWVFvTUwtWFEaLQoXQ0lITTBvZ0tFSUNBZ0lET3F1LVV4Z0USEkNnc0l1SVdDbGdZUW9NTC1YUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJdUlXQ2xnWVFvTUwtWFE&wv=1&d=286732320",
      plusId: "112725638666591503132",
      reviewAuthor: "Grace Smith",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2MdNZ-uP0s_0UOKBYZ50pTMSc-0ZfWHIKsGRxp=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My husband and I just got done with this tour! Miranda was very knowledgeable and funny !! I would have known there was so much history to this little area!! Definetly request Miranda!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPcXUtVWhnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOqu-UhgE%7CCgsIuIWClgYQoML-XQ%7C?hl=en-US",
      rawDate: "07-02-2022 5:39 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPMG8tQzVRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPMG8tQzVRRRItChZDSUhNMG9nS0VJQ0FnSURPMG8tQ0ZREhNDZ3dJbk9IOGxRWVFrT1c0eVFFGi4KF0NJSE0wb2dLRUlDQWdJRE8wby1DbFFFEhNDZ3dJbk9IOGxRWVFrT1c0eVFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0luT0g4bFFZUWtPVzR5UUU&wv=1&d=286732320",
      plusId: "110919364682267503786",
      reviewAuthor: "Kristen Kloss",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_wpPbr4LXAFvnkMDSzGyYiccehjTVtN4Z9NOFTzhY=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Enjoyed their Ultimate Queen City Underground tour. Our tour guide Miranda was super nice and very knowledgeable about Cincinnati history and architecture. Don't let her accent throw you off, she really knows about this beautiful city. We'll be back for their other tours. Thanks, Miranda for your sense of humor and ability to connect with everyone in our group.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPMG8tQzVRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO0o-C5QE%7CCgwInOH8lQYQkOW4yQE%7C?hl=en-US",
      rawDate: "07-01-2022 5:36 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPd3R6Rm1RRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPd3R6Rm1RRRItChZDSUhNMG9nS0VJQ0FnSURPd3R6RldREhNDZ3dJbnBYNGxRWVFxTWJqMHdNGi4KF0NJSE0wb2dLRUlDQWdJRE93dHpGMlFFEhNDZ3dJbnBYNGxRWVFxTWJqMHdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lucFg0bFFZUXFNYmowd00&wv=1&d=286732320",
      plusId: "116405384868102367298",
      reviewAuthor: "Randi Paxton",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu079yKnFmeCD6PEFO6O1u6w-3CVJJ0JGK7Wo27e=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda and the brewery tour was amazing. There\u2019s so much history In Cincinnati and we enjoyed learning about it all and enjoying tasty beer after. Definitely recommend the tour and Northern Row!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPd3R6Rm1RRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOwtzFmQE%7CCgwInpX4lQYQqMbj0wM%7C?hl=en-US",
      rawDate: "06-30-2022 8:42 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPd3B6YmhnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPd3B6YmhnRRIsChZDSUhNMG9nS0VJQ0FnSURPd3B6YlJnEhJDZ3NJdHBUNGxRWVFrUHlSRVEaLQoXQ0lITTBvZ0tFSUNBZ0lET3dwemJ4Z0USEkNnc0l0cFQ0bFFZUWtQeVJFUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJdHBUNGxRWVFrUHlSRVE&wv=1&d=286732320",
      plusId: "118109711392884117569",
      reviewAuthor: "cody gardner",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu13LbaSA0gEC-pNU8MpkAoc7uu6gRBQnS41S1xH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We had an amazing brewery/underground tour with Miranda. Very informative with Cincinnati history and was funny as well. Highly recommend.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPd3B6YmhnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOwpzbhgE%7CCgsItpT4lQYQkPyREQ%7C?hl=en-US",
      rawDate: "06-30-2022 8:40 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPZ3JETDdRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPZ3JETDdRRRItChZDSUhNMG9nS0VJQ0FnSURPZ3JETEhREhNDZ3dJM0pIM2xRWVFnSU9abXdJGi4KF0NJSE0wb2dLRUlDQWdJRE9nckRMblFFEhNDZ3dJM0pIM2xRWVFnSU9abXdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzSkgzbFFZUWdJT1ptd0k&wv=1&d=286732320",
      plusId: "114962664320413210479",
      reviewAuthor: "tom hochhausler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3OBa4wMp7KpKRmQr2HDgmINVOAW9sSJLwFZYSd=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Very interesting tour. Laura was very informative and pleasant.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPZ3JETDdRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOgrDL7QE%7CCgwI3JH3lQYQgIOZmwI%7C?hl=en-US",
      rawDate: "06-30-2022 4:01 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPN0wyREZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPN0wyREZREi0KF0NJSE0wb2dLRUlDQWdJRE83TDJEbFFFEhJDZ3NJME5meWxRWVFnTGlySXcaLAoWQ0lITTBvZ0tFSUNBZ0lETzdMMkRWURISQ2dzSTBOZnlsUVlRZ0xpckl3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kwTmZ5bFFZUWdMaXJJdw&wv=1&d=286732320",
      plusId: "109555248423068466966",
      reviewAuthor: "Jack Kelley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3wKmMh8uNYwgduCn__79wciflySv1EeG5BmDg=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Very informative and enjoyable. Miranda was great!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPN0wyREZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO7L2DFQ%7CCgsI0NfylQYQgLirIw%7C?hl=en-US",
      rawDate: "06-29-2022 7:45 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPN0k3RzlBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPN0k3RzlBRRItChZDSUhNMG9nS0VJQ0FnSURPN0k3R0RBEhNDZ3dJOHJMeWxRWVE4SnJFcUFNGi4KF0NJSE0wb2dLRUlDQWdJRE83STdHakFFEhNDZ3dJOHJMeWxRWVE4SnJFcUFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k4ckx5bFFZUThKckVxQU0&wv=1&d=286732320",
      plusId: "104966151057876701828",
      reviewAuthor: "Teresa Jackson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_fYEH5Mx_TrTxH0ct-KRVF8NTjBoDA8EO-DpFZGQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was our guide, and she did a fabulous job! She definitely loves what she does. We enjoyed hearing about the \u201cOld Rhine\u201d area of Cincinnati. The crypt below St. Francis Seraph was interesting. However, even better were the tunnels, if they can called that. The area was HUGE! If you get a chance to go on an \u201cAmerican Legacy\u201d Tour, do it!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPN0k3RzlBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO7I7G9AE%7CCgwI8rLylQYQ8JrEqAM%7C?hl=en-US",
      rawDate: "06-29-2022 6:26 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPN0xiX1J3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPN0xiX1J3Ei4KF0NJSE0wb2dLRUlDQWdJRE83TGJfeHdFEhNDZ3dJdTdIeWxRWVFzTTdfNFFFGi0KFkNJSE0wb2dLRUlDQWdJRE83TGJfSncSE0Nnd0l1N0h5bFFZUXNNN180UUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXU3SHlsUVlRc003XzRRRQ&wv=1&d=286732320",
      plusId: "100491818517493100614",
      reviewAuthor: "Andrew Jackson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2l7rv1k-ll75xl_8JzWDFtaBQ8KCIE3jCcL-yQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Could not have asked for a better tour or tour guide for our brief stop in Cincinnati! Miranda gave our group of 8 a fantastic intro to Cincinnati with an emphasis on the Over-the-Rhine neighborhood. Strongly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPN0xiX1J3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO7Lb_Rw%7CCgwIu7HylQYQsM7_4QE%7C?hl=en-US",
      rawDate: "06-29-2022 6:23 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPdEx2bjZ3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPdEx2bjZ3RRItChZDSUhNMG9nS0VJQ0FnSURPdEx2bkd3EhNDZ3dJdmJ6dGxRWVFzT3Ftd1FJGi4KF0NJSE0wb2dLRUlDQWdJRE90THZubXdFEhNDZ3dJdmJ6dGxRWVFzT3Ftd1FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2Ynp0bFFZUXNPcW13UUk&wv=1&d=286732320",
      plusId: "114089050552634636730",
      reviewAuthor: "Julia Hall",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0TCsQcbqUvLlz4yoz-iAAVQxmFfmLJN6SNrwiI=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Dean was our tour guide. He is a wonderful story teller of the Hidden Caverns history tour. Super knowledgeable. We highly recommend Dean and this tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPdEx2bjZ3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtLvn6wE%7CCgwIvbztlQYQsOqmwQI%7C?hl=en-US",
      rawDate: "06-28-2022 8:02 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdExPVkFREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdExPVkFREi4KF0NJSE0wb2dLRUlDQWdJRE90TE9WZ1FFEhNDZ3dJNTdUdGxRWVF3UEhUcVFNGi0KFkNJSE0wb2dLRUlDQWdJRE90TE9WUVESE0Nnd0k1N1R0bFFZUXdQSFRxUU0iEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STU3VHRsUVlRd1BIVHFRTQ&wv=1&d=286732320",
      plusId: "106049365857057420584",
      reviewAuthor: "Colleen Grimm",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1eyoObcjwkvFIk1_lQRrXOmt1h8zoQY9rHBDd2=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Interesting and informative",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdExPVkFREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtLOVAQ%7CCgwI57TtlQYQwPHTqQM%7C?hl=en-US",
      rawDate: "06-28-2022 7:45 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPdEtub2VREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPdEtub2VREi4KF0NJSE0wb2dLRUlDQWdJRE90S25vLVFFEhNDZ3dJckpydGxRWVE4TExpeUFJGi0KFkNJSE0wb2dLRUlDQWdJRE90S25vQlESE0Nnd0lySnJ0bFFZUThMTGl5QUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXJKcnRsUVlROExMaXlBSQ&wv=1&d=286732320",
      plusId: "111314036003056262675",
      reviewAuthor: "Deidra Williams",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3kXrvO-BZOsRXvg3wRduD5mWxIZ6sm5vZvnhSo=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Our tour guide, Miranda was absolutely spectacular! She was extremely personable and made it fun to learn the knowledgeable facts about the Cincinnati Queen City Underground! She was truly the best tour guide I\u2019ve had during my 4-day stay. Because of her, I\u2019m looking into booking more tours where she\u2019s the tour guide. Thank you for a great time!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPdEtub2VREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOtKnoeQ%7CCgwIrJrtlQYQ8LLiyAI%7C?hl=en-US",
      rawDate: "06-28-2022 6:49 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPLUllYktBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPLUllYktBEi0KF0NJSE0wb2dLRUlDQWdJRE8tSWVicUFFEhJDZ3NJallEbmxRWVEtSVBiVEEaLAoWQ0lITTBvZ0tFSUNBZ0lETy1JZWJhQRISQ2dzSWpZRG5sUVlRLUlQYlRBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lqWURubFFZUS1JUGJUQQ&wv=1&d=286732320",
      plusId: "115853963134854839831",
      reviewAuthor: "gerilyn jobe",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0U_ZBaA3P1_hJ1vf07puzbbggu0fEnBSnk3fRp=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We had the best time on this tour. Request Miranda as your tour guide!! She did a fantastic job of giving history of each building visited as well as being very personable and funny. We will definitely be back!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPLUllYktBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO-IebKA%7CCgsIjYDnlQYQ-IPbTA%7C?hl=en-US",
      rawDate: "06-27-2022 2:34 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPLU55ZVpREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPLU55ZVpRGi4KF0NJSE0wb2dLRUlDQWdJRE8tTnllNVFFEhNDZ3dJLTczbWxRWVFzS0dRaUFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0ktNzNtbFFZUXNLR1FpQU0&wv=1&d=286732320",
      plusId: "101913314437492532432",
      reviewAuthor: "Colleen Budde",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_gdU6r8hGNO8wNhmUJ3YHuZHOSXPrAi9dz97fG=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 4,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPLU55ZVpREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO-NyeZQ%7CCgwI-73mlQYQsKGQiAM%7C?hl=en-US",
      rawDate: "06-27-2022 12:13 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChRDSUhNMG9nS0VJQ0FnSURPMk5wVBAB&entityid=ChRDSUhNMG9nS0VJQ0FnSURPMk5wVBIuChdDSUhNMG9nS0VJQ0FnSURPMk5yVGdBRRITQ2d3SWw3UGtsUVlRdU1iMDJRSRotChZDSUhNMG9nS0VJQ0FnSURPMk5yVFFBEhNDZ3dJbDdQa2xRWVF1TWIwMlFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lsN1BrbFFZUXVNYjAyUUk&wv=1&d=286732320",
      plusId: "110261585071856130163",
      reviewAuthor: "Catherine",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2E5Z46Wy-uu66-6zRVQCYseomcazcCIZOueMaK=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "We went on the Ultimate Queen City is Haunted tour during a bachelorette party this past weekend, with Miranda as our guide. She was great and her storytelling was really captivating. We ended up having a really nice conversation with her back at the storefront as well. Our group said that we wished the tour would have spent less time at the Emory and more time out walking. We really liked hearing the outside stories and it felt like a long time to be in there wandering. But I would definitely take another tour with this company.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChRDSUhNMG9nS0VJQ0FnSURPMk5wVBAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO2NpT%7CCgwIl7PklQYQuMb02QI%7C?hl=en-US",
      rawDate: "06-27-2022 2:44 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPbU5IX0h3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPbU5IX0h3Ei0KF0NJSE0wb2dLRUlDQWdJRE9tTkhfbndFEhJDZ3NJMkozamxRWVEtTHFuY3caLAoWQ0lITTBvZ0tFSUNBZ0lET21OSF9YdxISQ2dzSTJKM2psUVlRLUxxbmN3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0kySjNqbFFZUS1McW5jdw&wv=1&d=286732320",
      plusId: "105242334506091540353",
      reviewAuthor: "David Hurley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-2LwyNWBJLCxSSiAltIxcDWZAmo3_erNgutqdz=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Fantastic 1919 Reds World Series/Baseball History tour! My dad and whole family enjoyed all of the history. Eric was a super knowledgeable and fun tour guide! Highly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPbU5IX0h3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOmNH_Hw%7CCgsI2J3jlQYQ-Lqncw%7C?hl=en-US",
      rawDate: "06-26-2022 9:25 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPNkpEVVhBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPNkpEVVhBEi4KF0NJSE0wb2dLRUlDQWdJRE82SkRVM0FFEhNDZ3dJM09MaGxRWVF5SXFWaXdJGi0KFkNJSE0wb2dLRUlDQWdJRE82SkRVUEESE0Nnd0kzT0xobFFZUXlJcVZpd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STNPTGhsUVlReUlxVml3SQ&wv=1&d=286732320",
      plusId: "114615896610209055782",
      reviewAuthor: "Patrick Steck III",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu89E_n3EydrgGBuw-hQq7yirzwyVa0ubfs4_xU4Gw=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Our tour guide was Peggy and she rocked! Taught us a tremendous chunk of history about Cincinnati\u2019s Over-The-Rhine neighborhood. Well worth the experience, would highly recommend. Loved the walk into Washington Park and learning about its past, other favorite parts were exploring underneath the former John Kauffman vine street brewery.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPNkpEVVhBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO6JDUXA%7CCgwI3OLhlQYQyIqViwI%7C?hl=en-US",
      rawDate: "06-26-2022 2:46 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPeUttUTBRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPeUttUTBRRRItChZDSUhNMG9nS0VJQ0FnSURPeUttUU1REhNDZ3dJaDZyZ2xRWVFvUHVfdVFNGi4KF0NJSE0wb2dLRUlDQWdJRE95S21Rc1FFEhNDZ3dJaDZyZ2xRWVFvUHVfdVFNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0loNnJnbFFZUW9QdV91UU0&wv=1&d=286732320",
      plusId: "112537488814367989561",
      reviewAuthor: "Amy Traxler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9tv7csilLjbtvOTsUAcpXI04a5uBccWGurY4sBKw=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Angela and Michelle were great. The wine and dessert tour was fun. We've done the Under The Market tour and Gangster tour in the past and they were wonderful as well!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPeUttUTBRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOyKmQ0QE%7CCgwIh6rglQYQoPu_uQM%7C?hl=en-US",
      rawDate: "06-26-2022 8:12 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPaUxpN0lBEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPaUxpN0lBEi0KF0NJSE0wb2dLRUlDQWdJRE9pTGk3b0FFEhJDZ3NJemFyZWxRWVEyUHo0YVEaLAoWQ0lITTBvZ0tFSUNBZ0lET2lMaTdZQRISQ2dzSXphcmVsUVlRMlB6NGFRIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l6YXJlbFFZUTJQejRhUQ&wv=1&d=286732320",
      plusId: "102294944774776411613",
      reviewAuthor: "Jessica tych",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1IfQsuzBFbJPT-XhBmbK5XC4aKNrEtDzpvqQub=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Zach was an amazing tour guide!!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPaUxpN0lBEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOiLi7IA%7CCgsIzarelQYQ2Pz4aQ%7C?hl=en-US",
      rawDate: "06-25-2022 11:07 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPNE82dDZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPNE82dDZBRRItChZDSUhNMG9nS0VJQ0FnSURPNE82dEdBEhNDZ3dJMklQWmxRWVF3SjNYc1FNGi4KF0NJSE0wb2dLRUlDQWdJRE80TzZ0bUFFEhNDZ3dJMklQWmxRWVF3SjNYc1FNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kySVBabFFZUXdKM1hzUU0&wv=1&d=286732320",
      plusId: "118106125565026395007",
      reviewAuthor: "David Mullins",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3WcPDBlChIJQWTfFwi_HLnAA4yl8X82HBsHBpx=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Did this tour June 2022 Zach was our guide for the Over the Rhine, Cincinnati underground tour. And he was an awesome guide. The tour was informative and fun, he kept us all interested and involved. Would definitely recommend the tour and Zach as a guide. Was really cool guy and kept us moving.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPNE82dDZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO4O6t6AE%7CCgwI2IPZlQYQwJ3XsQM%7C?hl=en-US",
      rawDate: "06-24-2022 10:59 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURPNExxZFlREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSURPNExxZFlREi0KF0NJSE0wb2dLRUlDQWdJRE80THFkNFFFEhJDZ3NJamZMWWxRWVF3UFdkREEaLAoWQ0lITTBvZ0tFSUNBZ0lETzRMcWRFURISQ2dzSWpmTFlsUVlRd1BXZERBIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0lqZkxZbFFZUXdQV2REQQ&wv=1&d=286732320",
      plusId: "103238483383561240504",
      reviewAuthor: "Shana Verley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_Y4zq5y_8pcb7u542wEeSG4NhyRcBYWdJwBitiSQ=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Our history teacher Zach was amazing!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURPNExxZFlREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDO4LqdYQ%7CCgsIjfLYlQYQwPWdDA%7C?hl=en-US",
      rawDate: "06-24-2022 10:22 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPb0tLS2xnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPb0tLS2xnRRItChZDSUhNMG9nS0VJQ0FnSURPb0tLS1ZnEhNDZ3dJZy1iWGxRWVE4Sm5qZ2dNGi4KF0NJSE0wb2dLRUlDQWdJRE9vS0tLMWdFEhNDZ3dJZy1iWGxRWVE4Sm5qZ2dNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lnLWJYbFFZUThKbmpnZ00&wv=1&d=286732320",
      plusId: "117471487454158523909",
      reviewAuthor: "Michael Andrew Smith",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3vfWETnh-hTrdMrBf6ddaxlAVJ-IfAjHV-ii2E=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was an amazing guide for the ultimate underground tour. The tour hits the crypt at a Franciscan church, underground brewery tunnels and much more. The guide was witty, knowledgeable and kept the pace just right.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPb0tLS2xnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOoKKKlgE%7CCgwIg-bXlQYQ8JnjggM%7C?hl=en-US",
      rawDate: "06-24-2022 5:23 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSURPb0ppYzN3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSURPb0ppYzN3RRItChZDSUhNMG9nS0VJQ0FnSURPb0ppY1B3EhNDZ3dJck5MWGxRWVEtSUR3N0FFGi4KF0NJSE0wb2dLRUlDQWdJRE9vSmljdndFEhNDZ3dJck5MWGxRWVEtSUR3N0FFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lyTkxYbFFZUS1JRHc3QUU&wv=1&d=286732320",
      plusId: "110747146842574383596",
      reviewAuthor: "Emily Hughes",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_Zy62ECIpEPTlsPGfSqij3BhAm_DoT7dGpEyJ4WQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My mother and I loved the Ultimate Queen City Underground Tour :) Our guide Zach (spelling isn\u2019t my strong suit) did a phenomenal job on telling us amazing facts about the roads we walked on (and under, of course), the history of the parks we crossed, and even a beautiful historical church. Both my mom and I HIGHLY recommend this tour and are excited to return for the haunted tour this fall! (Hopefully we\u2019ll catch Zach again!)",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURPb0ppYzN3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIDOoJic3wE%7CCgwIrNLXlQYQ-IDw7AE%7C?hl=en-US",
      rawDate: "06-24-2022 4:41 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPdjZXbF93RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPdjZXbF93RRIrChRDSUhNMG9nS0VJQ0FnSUNPdjZWbBITQ2d3SWlvYlVsUVlRaUthWjFnRRouChdDSUhNMG9nS0VJQ0FnSUNPdjZXZGhnRRITQ2d3SWlvYlVsUVlRaUthWjFnRSISCaFui4b9s0GIEVDJkr76hej6KhNDZ3dJaW9iVWxRWVFpS2FaMWdF&wv=1&d=286732320",
      plusId: "113120509318981650477",
      reviewAuthor: "Matthew Burnell",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2bR2qhCXuej42PLP2ciPMcbDuas4nZfubCffvL=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText: "Maranda",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPdjZXbF93RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOv6Wl_wE%7CCgwIiobUlQYQiKaZ1gE%7C?hl=en-US",
      rawDate: "06-24-2022 12:19 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPbjViNTVnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPbjViNTVnRRItChZDSUhNMG9nS0VJQ0FnSUNPbjViNUZnEhNDZ3dJbHZqUmxRWVFzSTJfd0FNGi4KF0NJSE0wb2dLRUlDQWdJQ09uNWI1bGdFEhNDZ3dJbHZqUmxRWVFzSTJfd0FNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lsdmpSbFFZUXNJMl93QU0&wv=1&d=286732320",
      plusId: "113725000943814282021",
      reviewAuthor: "Ron Callahan",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9DFV7Ic2pg9ISXx47HmDvYh2tJjRpEPlUPoQUKYQ=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Great, informative and interesting tour! We're from Cincinnati and even we learned some things that we did not know. Our tour guide Laura was fun, knowledgable and energetic.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPbjViNTVnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOn5b55gE%7CCgwIlvjRlQYQsI2_wAM%7C?hl=en-US",
      rawDate: "06-23-2022 2:43 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPajdlMTBnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPajdlMTBnRRIsChZDSUhNMG9nS0VJQ0FnSUNPajdlMU1nEhJDZ3NJZ1kzT2xRWVE4TjdsVmcaLQoXQ0lITTBvZ0tFSUNBZ0lDT2o3ZTE4Z0USEkNnc0lnWTNPbFFZUThON2xWZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJZ1kzT2xRWVE4TjdsVmc&wv=1&d=286732320",
      plusId: "107440987536415330561",
      reviewAuthor: "AR LaBarge",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9kjQBVvv3v6B4UXgczhoo6uEQD57w2pYxvpJh3vNw=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "My husband and I visited Cincinnati this past weekend and the Ultimate Queen City Tour was the official kick off. The weather was spectacular and perfect for a walk through OTR.\n\nOur tour guide Zach, a history buff, was terrific. His knowledge and anecdotes added a lot of color on the tour as we went through our stops. His accents during stories were *chef's kiss.* The walk was a good length (not too terribly long) with good pacing. There's seats at some of the stops so you can rest if needed. I would highly recommend the tour for any visitor in town or if you're curious about OTR.\n\nIt was great to start our visit to Cincinnati with this tour and the stop for the underground brewery location is worth the whole tour alone. Definitely the highlight of my husband's trip (not just tour!).",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPajdlMTBnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOj7e10gE%7CCgsIgY3OlQYQ8N7lVg%7C?hl=en-US",
      rawDate: "06-22-2022 9:15 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPNTQyUEVREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPNTQyUEVREi0KF0NJSE0wb2dLRUlDQWdJQ081NDJQa1FFEhJDZ3NJdWQzSWxRWVF3SWU1ZmcaLAoWQ0lITTBvZ0tFSUNBZ0lDTzU0MlBVURISQ2dzSXVkM0lsUVlRd0llNWZnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l1ZDNJbFFZUXdJZTVmZw&wv=1&d=286732320",
      plusId: "101415936621515611532",
      reviewAuthor: "Sean Moulder",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_Hm0lIOO0YQZhx291Qm1Z1hpGPQOFCY1IKXGIDRw=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was an amazing tour guide. Very knowledgeable and caring for the group! Would give her 6 stars if I could!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPNTQyUEVREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO542PEQ%7CCgsIud3IlQYQwIe5fg%7C?hl=en-US",
      rawDate: "06-21-2022 8:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPcDVPeXdRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPcDVPeXdRRRIsChZDSUhNMG9nS0VJQ0FnSUNPcDVPeUlREhJDZ3NJd19ISGxRWVF1TzNzQ2caLQoXQ0lITTBvZ0tFSUNBZ0lDT3A1T3lvUUUSEkNnc0l3X0hIbFFZUXVPM3NDZyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJd19ISGxRWVF1TzNzQ2c&wv=1&d=286732320",
      plusId: "114468410041554897052",
      reviewAuthor: "Arielle Nathanson",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9rh86nT_rBMbHRm2s0EWrFdnyHoHWseuZeTqcXGNI=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda's tour is delightful. She's quirky funny and charming. Had a blast!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPcDVPeXdRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOp5OywQE%7CCgsIw_HHlQYQuO3sCg%7C?hl=en-US",
      rawDate: "06-21-2022 4:58 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPcDY2cGFnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPcDY2cGFnEi0KF0NJSE0wb2dLRUlDQWdJQ09wNjZwNmdFEhJDZ3NJd3NfSGxRWVFnSWJQWWcaLAoWQ0lITTBvZ0tFSUNBZ0lDT3A2NnBHZxISQ2dzSXdzX0hsUVlRZ0liUFlnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l3c19IbFFZUWdJYlBZZw&wv=1&d=286732320",
      plusId: "117807528298014580426",
      reviewAuthor: "Molly Madden",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu22rDKH2RmbJW4xJDfbkjj7Vr17s-UNWzN6Qerd=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "2 months ago",
      reviewRating: 5,
      reviewText:
        "What a special company! We took the Underground Tour as a treat for some of our clients, and all had a wonderful time. Our guide, Dean, was incredibly informative, entertaining, and a perfect gentleman. Our tour began promptly and ended within the allotted time frame. We highly recommend this group, and will not be providing any spoilers in this review--you must come and see for yourself! Thank you American Legacy Tours and Dean for sharing a new and unique perspective on The Queen City!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPcDY2cGFnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOp66pag%7CCgsIws_HlQYQgIbPYg%7C?hl=en-US",
      rawDate: "06-21-2022 3:46 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPdTZPRnZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPdTZPRnZBRRItChZDSUhNMG9nS0VJQ0FnSUNPdTZPRmZBEhNDZ3dJeWZ2RGxRWVFvTnFLZ1FFGi4KF0NJSE0wb2dLRUlDQWdJQ091Nk9GX0FFEhNDZ3dJeWZ2RGxRWVFvTnFLZ1FFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l5ZnZEbFFZUW9OcUtnUUU&wv=1&d=286732320",
      plusId: "105771174210644536800",
      reviewAuthor: "Aimee Packard",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0l1hvd2UfJya4F5I7GB6wF36bJBT_FU-Mk1j8R=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Laura was a greT tour guide! She responded to every question, child or adult. She was conscious of the heat, sought shade and shared so many cool old stories. Very well versed in the history of not only tour locations but whole area.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPdTZPRnZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOu6OFvAE%7CCgwIyfvDlQYQoNqKgQE%7C?hl=en-US",
      rawDate: "06-20-2022 11:07 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPMjhfUExnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPMjhfUExnEi4KF0NJSE0wb2dLRUlDQWdJQ08yOF9QcmdFEhNDZ3dJcG9YRGxRWVFnSnlLdkFFGi0KFkNJSE0wb2dLRUlDQWdJQ08yOF9QYmcSE0Nnd0lwb1hEbFFZUWdKeUt2QUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXBvWERsUVlRZ0p5S3ZBRQ&wv=1&d=286732320",
      plusId: "102545706284294732946",
      reviewAuthor: "S K",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_In4CyNy8ZGLF4vjpgPsSQKBuWIL9aZUiMyyAwyA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Took the Ultimate underground tour with my best friend on a girl's weekend and we loved it! George was great! Can't wait to take my husband on another tour with you guys!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPMjhfUExnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO28_PLg%7CCgwIpoXDlQYQgJyKvAE%7C?hl=en-US",
      rawDate: "06-20-2022 6:55 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPeThHS1ZREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPeThHS1ZRGi4KF0NJSE0wb2dLRUlDQWdJQ095OEdLMVFFEhNDZ3dJcUxtLWxRWVF1UERYNFFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lxTG0tbFFZUXVQRFg0UUU&wv=1&d=286732320",
      plusId: "108880487937993567487",
      reviewAuthor: "Gary Holloway",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu0JhjJlkGNRezo50mUgrLDt_yZmiW5AWpir5-qB=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText: "Only rating provided.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPeThHS1ZREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOy8GKVQ%7CCgwIqLm-lQYQuPDX4QE%7C?hl=en-US",
      rawDate: "06-19-2022 10:01 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPczllWUl3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPczllWUl3Ei0KF0NJSE0wb2dLRUlDQWdJQ09zOWVZb3dFEhJDZ3NJbVlpOGxRWVFzS0tIQmcaLAoWQ0lITTBvZ0tFSUNBZ0lDT3M5ZVlZdxISQ2dzSW1ZaThsUVlRc0tLSEJnIhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0ltWWk4bFFZUXNLS0hCZw&wv=1&d=286732320",
      plusId: "109222929608090797319",
      reviewAuthor: "Molly Rice",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1XridfHBh4to_JA4ZL4ZuW3kIGoLutMFfnTD4m=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Our OTR Underground tour with George was great! He was very knowledgeable about the area and an engaging storyteller. About half the tour was underground, but the parts around the neighborhood were just as interesting!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPczllWUl3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOs9eYIw%7CCgsImYi8lQYQsKKHBg%7C?hl=en-US",
      rawDate: "06-19-2022 11:10 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPazZTSG93RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPazZTSG93RRItChZDSUhNMG9nS0VJQ0FnSUNPazZTSFl3EhNDZ3dJOWIyNGxRWVFpSlcxbEFJGi4KF0NJSE0wb2dLRUlDQWdJQ09rNlNINHdFEhNDZ3dJOWIyNGxRWVFpSlcxbEFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k5YjI0bFFZUWlKVzFsQUk&wv=1&d=286732320",
      plusId: "108542150158680660013",
      reviewAuthor: "Mark D",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1voLkFK-HEbpq87WFx5F5CXbgMUTUScTxAnZWH=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "George was a great tour host, 5 stars. Architecture and Beer history, you can't go wrong.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPazZTSG93RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOk6SHowE%7CCgwI9b24lQYQiJW1lAI%7C?hl=en-US",
      rawDate: "06-18-2022 6:52 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPNC1iRlp3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPNC1iRlp3Ei4KF0NJSE0wb2dLRUlDQWdJQ080LWJGNXdFEhNDZ3dJNWVPM2xRWVFrS21EaXdJGi0KFkNJSE0wb2dLRUlDQWdJQ080LWJGRncSE0Nnd0k1ZU8zbFFZUWtLbURpd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STVlTzNsUVlRa0ttRGl3SQ&wv=1&d=286732320",
      plusId: "113784194501182905944",
      reviewAuthor: "Heather Ross",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1hxjFP2Kl-xa8st_G_xyAyL9PDGHBXdxayKASq=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "AWESOME experience and tour guide. Well worth the money. Very historic and interesting. Miranda, our tour guide, was very knowledgeable and entertaining. Her passion for what she does and commitment to making our experience enjoyable was second to none. THANK YOU! Looking forward to doing other tours in the future.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPNC1iRlp3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO4-bFZw%7CCgwI5eO3lQYQkKmDiwI%7C?hl=en-US",
      rawDate: "06-18-2022 3:40 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPX2QySkR3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPX2QySkR3Ei4KF0NJSE0wb2dLRUlDQWdJQ09fZDJKandFEhNDZ3dJeDdxemxRWVFrTjdFcHdJGi0KFkNJSE0wb2dLRUlDQWdJQ09fZDJKVHcSE0Nnd0l4N3F6bFFZUWtON0Vwd0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SXg3cXpsUVlRa043RXB3SQ&wv=1&d=286732320",
      plusId: "110123095768846174893",
      reviewAuthor: "Chris Mulberry",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3dBHprRyn3SS4yZFWCSAftiCAhtztSA6x6KVGZ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Did the Queen City Underground with Patrick as our guide. He was great. Loved the tour.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPX2QySkR3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO_d2JDw%7CCgwIx7qzlQYQkN7EpwI%7C?hl=en-US",
      rawDate: "06-17-2022 8:00 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPcmRmeDJ3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPcmRmeDJ3RRIsChZDSUhNMG9nS0VJQ0FnSUNPcmRmeE93EhJDZ3NJOWFHdWxRWVEtSzJiTXcaLQoXQ0lITTBvZ0tFSUNBZ0lDT3JkZnh1d0USEkNnc0kwcUd1bFFZUS1LZnJHUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJOWFHdWxRWVEtSzJiTXc&wv=1&d=286732320",
      plusId: "105600541378674431064",
      reviewAuthor: "Samantha Dalton",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-Dp2NugMAB_cermyzCQNfIwmizB8Luk1bdWUwGkA=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "My work team took the Underground tour with Miranda and she ROCKS! She was very knowledgeable, funny and interactive with the group! I have been spreading the word to everyone and plan to enjoy more tours in the near future!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPcmRmeDJ3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOrdfx2wE%7CCgsI9aGulQYQ-K2bMw%7C?hl=en-US",
      rawDate: "06-16-2022 8:22 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPMWFmYTB3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPMWFmYTB3RRItChZDSUhNMG9nS0VJQ0FnSUNPMWFmYU13EhNDZ3dJMnZ5b2xRWVEwT3pKX2dJGi4KF0NJSE0wb2dLRUlDQWdJQ08xYWZhc3dFEhNDZ3dJMnZ5b2xRWVEwT3pKX2dJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kydnlvbFFZUTBPekpfZ0k&wv=1&d=286732320",
      plusId: "101093094849755727373",
      reviewAuthor: "Angela Starkey",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1zMkhR6oh2hRva5V3HcKJnKobkvUouuWML55d_=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Zack was a phenomenal tour guide. He was very knowledgeable and gave us a ton of interesting information about the area and history. He was very funny and entertaining. The Queen City Underground Tour was definitely worth it!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPMWFmYTB3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO1afa0wE%7CCgwI2vyolQYQ0OzJ_gI%7C?hl=en-US",
      rawDate: "06-15-2022 8:17 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPdWE2TndnRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPdWE2TndnRRItChZDSUhNMG9nS0VJQ0FnSUNPdWE2TklnEhNDZ3dJdnNHaGxRWVFzT2VzNlFJGi4KF0NJSE0wb2dLRUlDQWdJQ091YTZOb2dFEhNDZ3dJdnNHaGxRWVFzT2VzNlFJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l2c0dobFFZUXNPZXM2UUk&wv=1&d=286732320",
      plusId: "100275647564574351320",
      reviewAuthor: "Kevin T",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3tgeyUkqfS9gNuIl93akxLGmcZiSdw5mxJJLqj=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We were cheerfully greeted by Zach, who provided an excellent tour focused on the history of Over The Rhine from a cultural and brewing perspective. I had family in town and had been on a similar tour to the same area in the past and didn't have high expectations of learning more. I learned so much and Zach was very knowledgeable and enjoyable to spend a few hours with. Highly recommend.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPdWE2TndnRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOua6NwgE%7CCgwIvsGhlQYQsOes6QI%7C?hl=en-US",
      rawDate: "06-14-2022 10:19 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPNmItbXZBRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPNmItbXZBRRItChZDSUhNMG9nS0VJQ0FnSUNPNmItbWZBEhNDZ3dJMjllZWxRWVFrSzNfLWdJGi4KF0NJSE0wb2dLRUlDQWdJQ082Yi1tX0FFEhNDZ3dJMjllZWxRWVFrSzNfLWdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kyOWVlbFFZUWtLM18tZ0k&wv=1&d=286732320",
      plusId: "105772591405225440880",
      reviewAuthor: "Brandon Parks",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9JZY8k1jaTMkNAo1MSbfuF_TAavoWgVeU-q4gSvw=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Highly recommend. Super professional, entertaining and even educational tour. Our tour guide, George was very informative and had a great energy leading our group. Learned a lot of history local to the area and the buildings that we visited. Never dull and had a good cadence as we went from site to site. A plus all the way!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPNmItbXZBRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO6b-mvAE%7CCgwI29eelQYQkK3_-gI%7C?hl=en-US",
      rawDate: "06-13-2022 9:27 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPOGZxSjN3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPOGZxSjN3RRItChZDSUhNMG9nS0VJQ0FnSUNPOGZxSlB3EhNDZ3dJMzRXYWxRWVFpSk9DeEFFGi4KF0NJSE0wb2dLRUlDQWdJQ084ZnFKdndFEhNDZ3dJMzRXYWxRWVFpSk9DeEFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzNFdhbFFZUWlKT0N4QUU&wv=1&d=286732320",
      plusId: "116513796248615648132",
      reviewAuthor: "Soonlea Springs",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_BWBj59vy3JhnthAopc1AvF9psBpj14MND0o4sRQ=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Absolutely wonderful tour. Barb was amazing. Great information on the history of the city included in the stories. Kept our attention and was fun and informative. Highly recommend, she was amazing.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPOGZxSjN3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO8fqJ3wE%7CCgwI34WalQYQiJOCxAE%7C?hl=en-US",
      rawDate: "06-13-2022 12:20 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPc2N2Nm1nRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPc2N2Nm1nRRItChZDSUhNMG9nS0VJQ0FnSUNPc2N2NldnEhNDZ3dJN3ItWmxRWVEyTTJQcHdNGi4KF0NJSE0wb2dLRUlDQWdJQ09zY3Y2MmdFEhNDZ3dJN3ItWmxRWVEyTTJQcHdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k3ci1abFFZUTJNMlBwd00&wv=1&d=286732320",
      plusId: "103677164922375164984",
      reviewAuthor: "Danielle Reinhart",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu17q6xDhY8upvtIn4JQOItyaIkTGQTZqUCarvLt=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "This was the best experience I\u2019ve had so far! I highly recommend this to everyone! The tour guide is amazing as well.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPc2N2Nm1nRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOscv6mgE%7CCgwI7r-ZlQYQ2M2PpwM%7C?hl=en-US",
      rawDate: "06-12-2022 9:51 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPMGNtU25BRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPMGNtU25BRRItChZDSUhNMG9nS0VJQ0FnSUNPMGNtU1hBEhNDZ3dJcUtPWWxRWVEtSWZHbUFFGi4KF0NJSE0wb2dLRUlDQWdJQ08wY21TM0FFEhNDZ3dJcUtPWWxRWVEtSWZHbUFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0lxS09ZbFFZUS1JZkdtQUU&wv=1&d=286732320",
      plusId: "107512432461941323411",
      reviewAuthor: "Colleen Carr",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu94Iwn-YQS9cHrH-Sa5SvaC7D0Ii7QAcm9nseiz=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We had a great experience! George is a perfect tour guide. He knows the history well, speaks clearly and with good volume. He is friendly and welcomes questions. The historical topics are many and varied. Baseball, prohibition, cemeteries, beer\u2026..\nWe will be returning for more tours!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPMGNtU25BRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO0cmSnAE%7CCgwIqKOYlQYQ-IfGmAE%7C?hl=en-US",
      rawDate: "06-12-2022 4:17 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPd2FxV1pREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPd2FxV1pREi4KF0NJSE0wb2dLRUlDQWdJQ093YXFXNVFFEhNDZ3dJMi15VGxRWVEwT0RQcWdJGi0KFkNJSE0wb2dLRUlDQWdJQ093YXFXRlESE0Nnd0kyLXlUbFFZUTBPRFBxZ0kiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STIteVRsUVlRME9EUHFnSQ&wv=1&d=286732320",
      plusId: "111759028808104672292",
      reviewAuthor: "Kyle Allen",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9kVPcmU4RcsE1BSjmiBt5nS26NlL6of5HNUEp4UAU=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Angela was amazing! We had such a great time with our group and tasting some amazing sweets and wines!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPd2FxV1pREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOwaqWZQ%7CCgwI2-yTlQYQ0ODPqgI%7C?hl=en-US",
      rawDate: "06-11-2022 8:08 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUMyX015NzBRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUMyX015NzBRRRIsChZDSUhNMG9nS0VJQ0FnSUMyX015N01REhJDZ3NJME4zbWtnWVF3UHVYTncaLQoXQ0lITTBvZ0tFSUNBZ0lDMl9NeTdzUUUSEkNnc0kwTjNta2dZUXdQdVhOdyISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJbUpxVGxRWVE2SnpoQnc&wv=1&d=286732320",
      plusId: "103009583413321532433",
      reviewAuthor: "Nigel Armstrong",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-YFDnKT8Iath1mXfJtemECLGFXn_o58Pz9vWi2=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Thoroughly enjoyed the ULTIMATE QUEEN CITY UNDERGROUND TOUR of Cincinnati yesterday. I've lived in the city of 12 years and had no idea about the wealth of history, hidden both above and below ground level in the OTR area. Miranda was our excellent tour guide, providing a constant flow of interesting facts and delivered in such a fun and engaging way. Looking forward to trying out other tours in the area now. Thanks Miranda.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUMyX015NzBRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgIC2_My70QE%7CCgsImJqTlQYQ6JzhBw%7C?hl=en-US",
      rawDate: "06-11-2022 5:12 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPbnF5Znd3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPbnF5Znd3RRItChZDSUhNMG9nS0VJQ0FnSUNPbnF5Zkl3EhNDZ3dJM1plT2xRWVEwT3YyX1FJGi4KF0NJSE0wb2dLRUlDQWdJQ09ucXlmb3dFEhNDZ3dJM1plT2xRWVEwT3YyX1FJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0kzWmVPbFFZUTBPdjJfUUk&wv=1&d=286732320",
      plusId: "116018975981261798169",
      reviewAuthor: "Robin Parker",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu3Ow3fzCf7Z6hMC34ZY3JH9PfGixoIxo8ueas4_=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was our guide, and she was FABULOUS!!! What a wonderful experience!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPbnF5Znd3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOnqyfwwE%7CCgwI3ZeOlQYQ0Ov2_QI%7C?hl=en-US",
      rawDate: "06-10-2022 6:22 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPOXV6cUFREAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPOXV6cUFREi4KF0NJSE0wb2dLRUlDQWdJQ085dXpxZ1FFEhNDZ3dJNS1TSWxRWVE0UHplOHdFGi0KFkNJSE0wb2dLRUlDQWdJQ085dXpxUVESE0Nnd0k1LVNJbFFZUTRQemU4d0UiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STUtU0lsUVlRNFB6ZTh3RQ&wv=1&d=286732320",
      plusId: "116612055366194716745",
      reviewAuthor: "R. Koehler",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-yJNS4sywo0aDS79-Fq2sD2zN8tRf2b7NMe-Q9Hg=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Miranda was the most amazing guide. We had a private tour for the Queens underground experience and it was AMAZING, educational, awe inspiring and just downright a treat. The historical aspect was my all time favorite portion. It is amazing how rich the history of Cincinnati can be discussed in a few hours. \u2764 Now I want to do all the tours. Coming from Wisconsin we've decided we will be back. Miranda has the sweetest, funniest Aussie hospitality, request her if you can!!! Also, have fun seeing BSB Miranda :-)",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPOXV6cUFREAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO9uzqAQ%7CCgwI5-SIlQYQ4Pze8wE%7C?hl=en-US",
      rawDate: "06-09-2022 5:48 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPOXF5RkJnEAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPOXF5RkJnEi4KF0NJSE0wb2dLRUlDQWdJQ085cXlGaGdFEhNDZ3dJaWVTSWxRWVFzTlRsNlFFGi0KFkNJSE0wb2dLRUlDQWdJQ085cXlGUmcSE0Nnd0lpZVNJbFFZUXNOVGw2UUUiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWllU0lsUVlRc05UbDZRRQ&wv=1&d=286732320",
      plusId: "103387640226564496948",
      reviewAuthor: "Toby Farley",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu_d2XXcZjr7NdWHLMYPwnQSx8vRlRansHyivmZG3w=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We did the Queen's Underground Tour, with Miranda... Who brought her Aussie charm to a fantastic walk through some of the history of Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPOXF5RkJnEAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO9qyFBg%7CCgwIieSIlQYQsNTl6QE%7C?hl=en-US",
      rawDate: "06-09-2022 5:46 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPOXF5RzNRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPOXF5RzNRRRItChZDSUhNMG9nS0VJQ0FnSUNPOXF5R1BREhNDZ3dJeC1PSWxRWVFvUGJxeWdJGi4KF0NJSE0wb2dLRUlDQWdJQ085cXlHdlFFEhNDZ3dJeC1PSWxRWVFvUGJxeWdJIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l4LU9JbFFZUW9QYnF5Z0k&wv=1&d=286732320",
      plusId: "107717193685708734851",
      reviewAuthor: "Driftless Axe",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu8TKz1OmsBoepqYZPZXI5qP5ZTvg7UIoOdZF_eM=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We did the Queen's Underground Tour, with Miranda... Who brought her Aussie charm to a fantastic walk through some of the fascinating history of Cincinnati.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPOXF5RzNRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO9qyG3QE%7CCgwIx-OIlQYQoPbqygI%7C?hl=en-US",
      rawDate: "06-09-2022 5:45 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPNW9DTmpRRRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPNW9DTmpRRRItChZDSUhNMG9nS0VJQ0FnSUNPNW9DTlRREhNDZ3dJdElDRWxRWVFzTjJIcmdNGi4KF0NJSE0wb2dLRUlDQWdJQ081b0NOelFFEhNDZ3dJdElDRWxRWVFzTjJIcmdNIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l0SUNFbFFZUXNOMkhyZ00&wv=1&d=286732320",
      plusId: "110914644595970747733",
      reviewAuthor: "Megan T.",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1iHKqyWvVF3zDCAHNKJKn_QzbT7KFl1nYiAX0Y=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 4,
      reviewText:
        "George was a great tour guide. I especially appreciate that George spoke clearly and loud enough for the group to hear as I have hearing difficulties. I hate to go on tours and not be able to hear the guide and miss out. He was knowledgeable and friendly. The walking was alright and a decent length of time though the underground portion was a narrow and steep stairs so persons with accessibility concerns might want to consider this or investigate more before going.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPNW9DTmpRRRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO5oCNjQE%7CCgwItICElQYQsN2HrgM%7C?hl=en-US",
      rawDate: "06-08-2022 8:01 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPcXRLYnF3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPcXRLYnF3RRItChZDSUhNMG9nS0VJQ0FnSUNPcXRLYmF3EhNDZ3dJdFlQN2xBWVEwT2lIMGdFGi4KF0NJSE0wb2dLRUlDQWdJQ09xdEtiNndFEhNDZ3dJdFlQN2xBWVEwT2lIMGdFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0l0WVA3bEFZUTBPaUgwZ0U&wv=1&d=286732320",
      plusId: "100218646358122579188",
      reviewAuthor: "Tyler H",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu-jLy0bGSN9oR5LrmDj2j0ON146XTJKPUBF_eqwMzs=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Great storytelling and experiences. Really appreciated the hosts personal connections and experiences living in the area. Highly recommend!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPcXRLYnF3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOqtKbqwE%7CCgwItYP7lAYQ0OiH0gE%7C?hl=en-US",
      rawDate: "06-07-2022 3:10 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPOHJLTDZ3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPOHJLTDZ3RRIsChZDSUhNMG9nS0VJQ0FnSUNPOHJLTEd3EhJDZ3NJNGYzM2xBWVEtS20wT1EaLQoXQ0lITTBvZ0tFSUNBZ0lDTzhyS0xtd0USEkNnc0k0ZjMzbEFZUS1LbTBPUSISCaFui4b9s0GIEVDJkr76hej6KhJDZ3NJNGYzM2xBWVEtS20wT1E&wv=1&d=286732320",
      plusId: "112553493544544294724",
      reviewAuthor: "Kim Moore",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu2-LrN_xvhtTwKpzqWaJv7JRWsMjO5KpnqR2x8v=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We really enjoyed our tour. We took the Ultimate Underground tour and Matt was a great guide. I highly recommend this company.",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPOHJLTDZ3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO8rKL6wE%7CCgsI4f33lAYQ-Km0OQ%7C?hl=en-US",
      rawDate: "06-06-2022 1:19 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPOG96M1J3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPOG96M1J3Ei4KF0NJSE0wb2dLRUlDQWdJQ084b3ozeHdFEhNDZ3dJNHZIM2xBWVF3S3pLalFJGi0KFkNJSE0wb2dLRUlDQWdJQ084b3ozSncSE0Nnd0k0dkgzbEFZUXdLektqUUkiEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3STR2SDNsQVlRd0t6S2pRSQ&wv=1&d=286732320",
      plusId: "111264841088009696318",
      reviewAuthor: "Laurie Lokash_CNT",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu1CnsSZpXGyonR2CgqlDEsHBEtf8TOpiae2zU9C=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Awesome and informative tour! Miranda, our tour guide, has a great sense of humor. It's nice when history is both fun and informative!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPOG96M1J3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO8oz3Rw%7CCgwI4vH3lAYQwKzKjQI%7C?hl=en-US",
      rawDate: "06-06-2022 12:53 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPa3FySzB3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPa3FySzB3RRIuChdDSUhNMG9nS0VJQ0FnSUNPa3IzeXpnRRITQ2d3SWpvNzFsQVlRd04yVTNBTRosChZDSUhNMG9nS0VJQ0FnSUNPa3FyS013EhJDZ3NJc2I3MGxBWVEyTXpzSHciEgmhbouG_bNBiBFQyZK--oXo-ioTQ2d3SWpvNzFsQVlRd04yVTNBTQ&wv=1&d=286732320",
      plusId: "110623878479477259964",
      reviewAuthor: "Teresa D'Avino",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu36nqwPnhtdXHZjq2e1H6hD7fAcCa5tVkqmklzr=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "The history, architecture, true scary stories and this tour at night makes for one amazing ghost tour but also SO much more. I highly recommend going on one of these tours. Even if ghost tours aren't your thing, it will definitely give you several ideas of what there is to do in Cincinnati, OH as well as Covington, KY!!!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPa3FySzB3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICOkqrK0wE%7CCgwIjo71lAYQwN2U3AM%7C?hl=en-US",
      rawDate: "06-06-2022 12:15 am",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUNPX0x5Q2N3EAE&entityid=ChZDSUhNMG9nS0VJQ0FnSUNPX0x5Q2N3Ei0KF0NJSE0wb2dLRUlDQWdJQ09fTHlDOHdFEhJDZ3NJdGVIdWxBWVEtSUdXZncaLAoWQ0lITTBvZ0tFSUNBZ0lDT19MeUNDdxISQ2dzSXRlSHVsQVlRLUlHV2Z3IhIJoW6Lhv2zQYgRUMmSvvqF6PoqEkNnc0l0ZUh1bEFZUS1JR1dmdw&wv=1&d=286732320",
      plusId: "112954140861781732653",
      reviewAuthor: "H Feng",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a/ALm5wu32Ct51o6CTHMhwea8UrobU3fpbrGU53qa4NQrz=s120-c-c0x00000000-cc-rp-mo-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "We had a great tour today with Mat. He is very knowledgable and provides so much information about Cincinnati!",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNPX0x5Q2N3EAE!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO_LyCcw%7CCgsIteHulAYQ-IGWfw%7C?hl=en-US",
      rawDate: "06-04-2022 7:21 pm",
    },
    {
      flagUrl:
        "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUNPX0lTX2d3RRAB&entityid=ChdDSUhNMG9nS0VJQ0FnSUNPX0lTX2d3RRItChZDSUhNMG9nS0VJQ0FnSUNPX0lTX1F3EhNDZ3dJNjlMdWxBWVFzUGZBckFFGi4KF0NJSE0wb2dLRUlDQWdJQ09fSVNfd3dFEhNDZ3dJNjlMdWxBWVFzUGZBckFFIhIJoW6Lhv2zQYgRUMmSvvqF6PoqE0Nnd0k2OUx1bEFZUXNQZkFyQUU&wv=1&d=286732320",
      plusId: "115334725645703471995",
      reviewAuthor: "Alex Barroso",
      reviewAuthorImage:
        "https://lh3.googleusercontent.com/a-/ACNPEu9lnWpRUd5Zvp2XEZdjbhLGfc2NNTJrKLwZIp_JJJE=s120-c-c0x00000000-cc-rp-mo-ba5-br100",
      reviewDate: "3 months ago",
      reviewRating: 5,
      reviewText:
        "Matt gave us a great tour, he's passionate about it and has been researching and studying the subject to provide the best tour experience",
      reviewUrl:
        "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPX0lTX2d3RRAB!2m1!1s0x0:0xfae885fabe92c950!3m1!1s2@1:CIHM0ogKEICAgICO_IS_gwE%7CCgwI69LulAYQsPfArAE%7C?hl=en-US",
      rawDate: "06-04-2022 6:50 pm",
    },
  ];

  let getRandomReviews = (limit) => {
    let randomReviews = {};
    let count = 0;
    while (count < limit) {
      let review = reviews[Math.floor(Math.random() * reviews.length)];
      if (
        review.reviewRating == 5 &&
        review.reviewText.length > 80 &&
        review.reviewText.length < 300 &&
        !(review.reviewAuthor in randomReviews)
      ) {
        randomReviews[review.reviewAuthor] = review;
        count = count + 1;
      }
    }
    if (randomReviews) {
      return Object.values(randomReviews);
    } else {
      return [];
    }
  };

  const total_google_reviews = 1670;
  const google_rating = 4.9;
  return (
    <>
      {getRandomReviews(props.numToDisplay).map(
        (review) =>
          review.reviewAuthor &&
          review.reviewUrl &&
          review.reviewAuthorImage &&
          review.reviewRating == 5 &&
          review.reviewText && (
            <Col
              xs={6}
              lg={3}
              style={{
                color: "#000000",
                textAlign: "center",
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 5,
              }}
            >
              <Card
                style={{
                  // minHeight: "40rem",
                  marginBottom: "20px",
                }}
              >
                <Card.Header>
                  <Image
                    src={review.reviewAuthorImage}
                    referrerpolicy="no-referrer"
                    style={{ width: "120px", height: "120px" }}
                    loading="lazy"
                  />
                </Card.Header>
                <Card.Body>
                  <Card.Title>
                    <Image
                      src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
                      referrerpolicy="no-referrer"
                      style={{ width: "90px", height: "31px" }}
                    />
                    &nbsp;review:
                    <br />
                    <BsFillStarFill style={{ color: "gold" }} />
                    <BsFillStarFill style={{ color: "gold" }} />
                    <BsFillStarFill style={{ color: "gold" }} />
                    <BsFillStarFill style={{ color: "gold" }} />
                    <BsFillStarFill style={{ color: "gold" }} />
                  </Card.Title>
                  <Card.Text style={{ textAlign: "left" }}>
                    {review.reviewText}
                  </Card.Text>
                  <div style={{ fontStyle: "italic", textAlign: "right" }}>
                    - {review.reviewAuthor}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
      )}
    </>
  );
};
export default Reviews;
