import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { send } from "emailjs-com";

const PrivateTours = (props) => {
  const [sent, setSent] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [toSend, setToSend] = React.useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    setSent(true);
    send("service_thlhokp", "template_ydl9hbe", toSend, "pspiVumi2F-ZjP1oN")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsSent(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setIsSent(false);
        setSent(false);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>Private Tours | American Legacy Tours</title>
      </Helmet>
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 100,
          backgroundColor: "#232629",
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto/img/citysky') no-repeat center center fixed",
        }}
      >
        <Container>
          <h2>Private Tours</h2>

          <Row>
            <Col md={6} xs={12}>
              <div className="contactInfo">
                <p
                  style={{
                    fontSize: "110%",
                    color: "#000000",
                    backgroundColor: "rgba(255,255,255,0.8)",
                    padding: 20,
                    border: "5px solid white",
                  }}
                >
                  Since 2009, American Legacy Tours has been providing amazing
                  tours to both public and private groups in the greater
                  Cincinnati, Ohio area. We would be happy to host your next
                  event, be that a birthday party, bachelor or bachelorette
                  party, corporate team building gathering, event or convention.
                </p>
                <p
                  style={{
                    fontSize: "110%",
                    color: "#000000",
                    backgroundColor: "rgba(255,255,255,0.8)",
                    padding: 20,
                    border: "5px solid white",
                  }}
                >
                  Need food and transportation? No problem, we host hundreds of
                  private events each year and our staff are experts at managing
                  all the details for you. No group is too small or too large
                  for us. We specialize in customizing your experience to fit
                  the needs of your group.
                </p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="contactForm">
                <form onSubmit={onSubmit} class="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="from_name"
                      placeholder="Your Name"
                      value={toSend.from_name}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="phone"
                      className="form-control"
                      name="from_phone"
                      placeholder="Your Phone"
                      value={toSend.from_phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="reply_to"
                      placeholder="Your Email"
                      value={toSend.reply_to}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      name="event_date"
                      placeholder="Date of Private Event"
                      value={toSend.event_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      value={toSend.message}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  {!sent && <button type="submit">Send Message</button>}
                  {isSent && (
                    <div
                      id="thanks"
                      style={{
                        fontSize: "110%",
                        color: "#000000",
                        textAlign: "center",
                        backgroundColor: "rgba(255,255,255,0.8)",
                        padding: 20,
                        border: "5px solid white",
                      }}
                    >
                      Message Sent!
                    </div>
                  )}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default PrivateTours;
